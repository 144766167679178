import React from 'react';
import styled from 'styled-components';

interface PreviewActionsProps {
  imageLink: string;
  ctaText: string;
  onReset: () => void;
  onToggleActive: () => void;
  isActive: boolean;
}

export const PreviewActions: React.FC<PreviewActionsProps> = ({
  imageLink,
  ctaText,
  onReset,
  onToggleActive,
  isActive,
}) => {
  if (imageLink.length > 0 && ctaText.length > 0) {
    return (
      <ButtonActionContainer>
        <ResetButton onClick={onReset}>CLEAR</ResetButton>
        <ValidateButton active={isActive} onClick={onToggleActive}>
          {isActive ? 'DESACTIVER' : 'ACTIVER'}
        </ValidateButton>
      </ButtonActionContainer>
    );
  }

  return null;
};

const ButtonActionContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ResetButton = styled.button`
  background-color: #6b6b6b;
  color: white;
  width: 200px;
  padding: 10px 0px;
  border-radius: 5px;
`;

const ValidateButton = styled.button<{ active: boolean }>`
  background-color: ${(props) => (props.active ? 'red' : 'darkgreen')};
  color: white;
  width: 200px;
  padding: 10px 0px;
  border-radius: 5px;
`;

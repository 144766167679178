import axios from 'axios';
import TokenService from '../services/tokens';

const configAxios = () => {
  if (process.env.REACT_APP_ENV === 'DEV')
    axios.defaults.baseURL = 'http://localhost:3000/api/';
  else if (process.env.REACT_APP_ENV === 'PROD')
    axios.defaults.baseURL = 'https://application-api.thebradery.com/api/';
  else if (process.env.REACT_APP_ENV === 'STAGING')
    axios.defaults.baseURL =
      'https://staging.application-api.thebradery.com/api/';

  const storedJWT = TokenService.getJWT();

  if (storedJWT) {
    axios.defaults.headers.common['authorization'] = storedJWT;
  }
};

export default configAxios;

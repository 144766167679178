import { Button } from '../../../../shadcn/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../../shadcn/components/ui/dialog';
import { Form } from '../../../../shadcn/components/ui/form';
import { z } from 'zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { ScrollArea } from '../../../../shadcn/components/ui/scroll-area';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  deleteQuizQuestion,
  putQuizQuestion,
} from '../../../../services/api/quiz/quiz';
import { useToast } from '../../../../shadcn/components/ui/use-toast';
import { QuizQuestionForm } from './QuizQuestionForm';
import { QuizQuestion } from '../../types';
import { generateQuestionZodSchema } from '../utils/generateQuestionZodSchema';
import { orderQuestionChoices } from '../utils/orderQuestionChoices';

type QuizEditQuestionProps = {
  quizId: string;
  setIsQuestionEditDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isQuestionEditDialogOpen: boolean;
  question?: QuizQuestion;
};

export const QuizEditQuestionDialog = ({
  quizId,
  isQuestionEditDialogOpen,
  setIsQuestionEditDialogOpen,
  question,
}: QuizEditQuestionProps) => {
  const [generalErrorMessage, setGeneralErrorMessage] = useState('');

  const { toast } = useToast();
  const queryClient = useQueryClient();

  const formSchema = generateQuestionZodSchema(setGeneralErrorMessage);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      question: question?.htmlQuestionText,
      imageUrl: question?.imageUrl ?? undefined,
      logoUrl: question?.logoUrl ?? undefined,
      choices: orderQuestionChoices(question?.choices ?? []),
      leftCornerLogoUrl: question?.leftCornerLogoUrl ?? null,
    },
  });

  const choicesFieldArray = useFieldArray({
    control: form.control,
    name: 'choices',
    keyName: 'key',
  });

  useEffect(() => {
    if (!isQuestionEditDialogOpen) {
      form.reset();
      setGeneralErrorMessage('');
    }
  }, [form, isQuestionEditDialogOpen]);

  const putQuizQuestionMutation = useMutation({
    mutationFn: putQuizQuestion,
    onSuccess: async (data) => {
      if (data.success) {
        toast({
          title: 'Question a été modifié !',
          description: 'La question a bien été modifié.',
        });

        queryClient.invalidateQueries({ queryKey: ['quiz', quizId] });

        form.reset();
        setIsQuestionEditDialogOpen(false);
      } else {
        toast({
          variant: 'destructive',
          title: "Oops ! Une erreur s'est produite.",
          description:
            "La modification de la question n'a pas été faite. Veuillez-réessayer.",
        });
      }
    },
  });

  const deleteQuizQuestionMutation = useMutation({
    mutationFn: deleteQuizQuestion,
    onSuccess: async (data) => {
      if (data.success) {
        toast({
          title: 'Question supprimé !',
          description: 'La question a bien été supprimé.',
        });

        queryClient.invalidateQueries({ queryKey: ['quiz', quizId] });

        form.reset();
        setIsQuestionEditDialogOpen(false);
      } else {
        toast({
          variant: 'destructive',
          title: "Oops ! Une erreur s'est produite.",
          description: "La question n'a pas été supprimé. Veuillez-réessayer.",
        });
      }
    },
  });

  const handleSubmit = async (data: z.infer<typeof formSchema>) => {
    if (quizId && question?.id) {
      putQuizQuestionMutation.mutate({
        quizId,
        questionId: question?.id,
        question: data.question,
        choices: data.choices,
        logoUrl: data.logoUrl,
        imageUrl: data.imageUrl,
        leftCornerLogoUrl: data.leftCornerLogoUrl,
      });
    }
  };

  return (
    <Dialog
      onOpenChange={setIsQuestionEditDialogOpen}
      open={isQuestionEditDialogOpen}
    >
      <Form {...form}>
        <form>
          <DialogContent className="max-h-[825px] max-w-[700px] p-0">
            <ScrollArea className="max-h-[800px] p-6">
              <DialogHeader>
                <DialogTitle>Modification de la question</DialogTitle>
                <DialogDescription>
                  Veuillez renseigner les informations relative à la question.
                  {generalErrorMessage && (
                    <p className="text-error">{generalErrorMessage}</p>
                  )}
                </DialogDescription>
              </DialogHeader>

              <QuizQuestionForm
                choicesFieldArray={choicesFieldArray}
                form={form}
              />

              <DialogFooter>
                <div className="flex w-full justify-between">
                  <Button
                    type="button"
                    variant="destructive"
                    isLoading={deleteQuizQuestionMutation.isPending}
                    onClick={() => {
                      deleteQuizQuestionMutation.mutate({
                        quizId: quizId,
                        questionId: question?.id ?? '',
                      });
                    }}
                  >
                    Supprimer
                  </Button>
                  <div className="space-x-3">
                    {!deleteQuizQuestionMutation.isPending &&
                      form.formState.isDirty && (
                        <Button
                          type="button"
                          variant="outline"
                          isLoading={putQuizQuestionMutation.isPending}
                          onClick={() => {
                            form.reset();
                          }}
                        >
                          Réinitialiser
                        </Button>
                      )}

                    {!deleteQuizQuestionMutation.isPending && (
                      <Button
                        type="submit"
                        onClick={form.handleSubmit(handleSubmit)}
                        isLoading={putQuizQuestionMutation.isPending}
                      >
                        Enregistrer
                      </Button>
                    )}
                  </div>
                </div>
              </DialogFooter>
            </ScrollArea>
          </DialogContent>
        </form>
      </Form>
    </Dialog>
  );
};

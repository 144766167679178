import { UseFormReturn } from 'react-hook-form';
import { ColorPicker } from '../../../../components/forms/inputs/ColorPicker';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../shadcn/components/ui/form';
import { Input } from '../../../../shadcn/components/ui/input';
import { QuizProgressStepFormValues } from '../types';

type QuizProgressStepFormProps = {
  form: UseFormReturn<QuizProgressStepFormValues, any, undefined>;
};

export const QuizProgressStepForm = ({ form }: QuizProgressStepFormProps) => {
  return (
    <div className="space-y-4">
      <FormField
        control={form.control}
        name="value"
        render={({ field }) => (
          <FormItem className="grid grid-cols-4 gap-x-4 space-y-0">
            <FormLabel className="col-span-1 flex items-center justify-start">
              Valeur
            </FormLabel>
            <FormControl className="col-span-3">
              <Input {...field} type="number" />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="space-y-2">
        <FormField
          control={form.control}
          name="titleHtml"
          render={({ field }) => (
            <FormItem className="grid grid-cols-4 gap-x-4 space-y-0">
              <FormLabel className="col-span-1 flex items-center justify-start">
                Titre
              </FormLabel>
              <FormControl className="col-span-3">
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="titleColor"
          render={({ field }) => (
            <FormItem className="grid grid-cols-4 gap-x-4 space-y-0">
              <FormLabel className="col-span-1 flex items-center justify-start">
                Couleur
              </FormLabel>
              <FormControl className="col-span-3">
                <ColorPicker {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="space-y-2">
        <FormField
          control={form.control}
          name="subTitleHtml"
          render={({ field }) => (
            <FormItem className="grid grid-cols-4 gap-x-4 space-y-0">
              <FormLabel className="col-span-1 flex items-center justify-start">
                Sous-titre
              </FormLabel>
              <FormControl className="col-span-3">
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="subTitleColor"
          render={({ field }) => (
            <FormItem className="grid grid-cols-4 gap-x-4 space-y-0">
              <FormLabel className="col-span-1 flex items-center justify-start">
                Couleur
              </FormLabel>
              <FormControl className="col-span-3">
                <ColorPicker {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

import React from "react";
import styled from "styled-components";
import colors from "../../../styles/colors.styles";
import fonts from "../../../styles/fonts.styles";

interface ToggleGroupItemProps {
	value: string;
	isSelected: boolean;
	onSelectedValueChange: () => void;
}

const ToggleGroupItem: React.FC<ToggleGroupItemProps> = ({
	value,
	isSelected,
	onSelectedValueChange,
}) => {
	return (
		<Container isActive={isSelected} onClick={() => onSelectedValueChange()}>
			{value}
		</Container>
	);
};

const Container = styled.div<{ isActive: boolean }>`
	display: flex;
	padding: 10px 16px;
	background-color: ${colors.beige.primary};
	margin-right: 13px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	${(props) =>
		props.isActive
			? `border: 2px solid ${colors.blue.secondary};`
			: "border: 2px solid transparent;"}
	border-radius: 4px;

	font-size: 15px;
	font-family: ${fonts.WorkSans.regular};
	color: ${colors.blue.primary};

	&:hover {
		cursor: pointer;
	}
`;

export default ToggleGroupItem;

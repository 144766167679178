import { ChangeEventHandler } from "react";
import styled from "styled-components"

interface IProps {
  label: string;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const Checkbox: React.FC<IProps> = ({
  label,
  checked,
  onChange
}) => {
  const id = `${label}-${Date.now()}`;

  return (
    <InputWrapper>
      <input
        id={id}
        type='checkbox'
        checked={checked === true}
        onChange={onChange}
      />
      <CheckboxLabel htmlFor={id}>{label}</CheckboxLabel>
    </InputWrapper>
  );
}

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxLabel = styled.label`
  margin-left: 6px;
  user-select: none;
`;

export default Checkbox;
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors.styles';
import fonts from '../../styles/fonts.styles';
import QuestionFormDeprecated from '../../components/quiz_deprecated/QuestionFormDeprecated';
import QuestionRowDeprecated from '../../components/quiz_deprecated/QuestionRowDeprecated';
import Input from '../../components/forms/inputs/Input';
import Button from '../../components/forms/buttons/Button';
import { useParams } from 'react-router-dom';
import { getQuizzes, updateQuizzes } from '../../services/quiz.service';
import {
  doQuizzesOverlap,
  getQuizEndDate,
  getQuizStartDate,
} from '../../helpers/quiz';
import { Checkbox } from 'rsuite';
import TextArea from '../../components/forms/inputs/TextArea';
import File from '../../components/forms/inputs/File';
import DownloadBlocIcon from '../../assets/icons/download-icon.svg';
import { uploadFile } from '../../services/api/api';

const QuizDeprecated = () => {
  const params = useParams<{ id: string }>();

  const [quizzes, setQuizzes] = useState<Quiz[]>([]);

  const [status, setStatus] = useState<QuizStatus>('draft');
  const [title, setTitle] = useState<string>('');
  const [popUpLogo, setPopUpLogo] = useState<string | null>(null);
  const [popUpLogoUploading, setPopUpLogoUploading] = useState<boolean>(false);
  const [popUpLogoUploaded, setPopUpLogoUploaded] = useState<boolean>(false);
  const [bannerLogo, setBannerLogo] = useState<string | null>(null);
  const [bannerLogoUploading, setBannerLogoUploading] =
    useState<boolean>(false);
  const [bannerLogoUploaded, setBannerLogoUploaded] = useState<boolean>(false);

  const [questions, setQuestions] = useState<Question[]>([]);
  const [editingQuestionId, setEditingQuestionId] = useState<string | null>(
    null
  );

  const [thanksPopup, setThanksPopup] = useState<PopUp>({
    active: false,
    text: '',
  });
  const [thanksPopupLogoUploading, setThanksPopupLogoUploading] =
    useState<boolean>(false);
  const [thanksPopupLogoUploaded, setThanksPopupLogoUploaded] =
    useState<boolean>(false);
  const [endPopup, setEndPopup] = useState<PopUp>({ active: false, text: '' });
  const [endPopupLogoUploading, setEndPopupLogoUploading] =
    useState<boolean>(false);
  const [endPopupLogoUploaded, setEndPopupLogoUploaded] =
    useState<boolean>(false);

  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const validateForm = () => {
    setError(null);

    if (title === '') {
      setError('Le nom du quiz est obligatoire');
    } else if (popUpLogo === null) {
      setError('Le logo du pop-up est obligatoire');
    } else if (bannerLogo === null) {
      setError('Le logo de la bannière est obligatoire');
    } else {
      for (const quiz of quizzes) {
        if (
          quiz.id !== params.id &&
          doQuizzesOverlap(quiz.questions, questions)
        ) {
          setError(
            "Les dates du quiz se chevauchent avec celles d'un autre quiz."
          );
          return false;
        }
      }

      return true;
    }

    return false;
  };

  const saveQuiz = async () => {
    if (!validateForm()) return;

    const quiz: Quiz = {
      id:
        params.id !== undefined && params.id !== 'create'
          ? params.id
          : Date.now().toString(),
      status,
      title,
      popUpLogo: popUpLogo!,
      bannerLogo: bannerLogo!,
      questions,
      thanksPopup,
      endPopup,
    };

    const previousQuizzes = quizzes.filter((q) => q.id !== params.id);
    const newQuizzes = [...previousQuizzes, quiz];
    newQuizzes.sort(
      (a, b) =>
        getQuizStartDate(a.questions).getTime() -
        getQuizStartDate(b.questions).getTime()
    );
    await updateQuizzes(newQuizzes);
    setSuccess('Quiz sauvegardé');
    setTimeout(() => {
      setSuccess(null);
      if (params.id === 'create') {
        window.location.href = `/quiz/${quiz.id}`;
      }
    }, 2000);
  };

  useEffect(() => {
    setLoading(true);
    getQuizzes().then((quizzes) => {
      setQuizzes(quizzes);
      if (params.id !== 'create') {
        const quiz = quizzes.find((quiz) => quiz.id === params.id) ?? null;
        if (quiz) {
          setStatus(quiz.status);
          setTitle(quiz.title);
          setPopUpLogo(quiz.popUpLogo);
          setBannerLogo(quiz.bannerLogo);
          setQuestions(quiz.questions);
          setThanksPopup(quiz.thanksPopup);
          setEndPopup(quiz.endPopup);
        }
      }
      setLoading(false);
    });
  }, [params]);

  if (loading) return null;

  return (
    <Container>
      <Header>
        <PageTitle>{title || 'Untitled'}</PageTitle>
        {questions.length > 0 && (
          <QuizInfo>
            Du {getQuizStartDate(questions).toLocaleDateString('FR')} au{' '}
            {getQuizEndDate(questions).toLocaleDateString('FR')}
          </QuizInfo>
        )}
        <QuizInfo>{questions.length} questions</QuizInfo>
      </Header>

      <Section>
        <SectionTitle>Général</SectionTitle>
        <SelectWrapper>
          <label htmlFor="quiz-status">Statut</label>
          <select
            id="quiz-status"
            value={status}
            onChange={(evt) => setStatus(evt.currentTarget.value as QuizStatus)}
          >
            <option value="draft">Brouillon</option>
            <option value="preview">Essais</option>
            <option value="active">Live</option>
          </select>
        </SelectWrapper>
        <Input
          type="text"
          label="Nom du quiz"
          value={title}
          onChange={(evt) => setTitle(evt.currentTarget.value)}
        />

        <GeneralImageInputsWrapper>
          <FileInputWrapper>
            <FileInputLabel>Logo pop-up</FileInputLabel>
            <File
              uploaded={popUpLogoUploaded}
              uploading={popUpLogoUploading}
              src={popUpLogo ?? DownloadBlocIcon}
              onChange={async (evt) => {
                setPopUpLogoUploading(true);
                const file = evt.target.files?.length
                  ? evt.target.files[0]
                  : null;
                if (!file) return;

                const path = await uploadFile(file);
                setPopUpLogo(path);

                evt.target.value = '';
                setPopUpLogoUploading(false);
                setPopUpLogoUploaded(true);
              }}
              name="popup-image"
            />
          </FileInputWrapper>
          <FileInputWrapper>
            <FileInputLabel>Logo bannière</FileInputLabel>
            <File
              uploaded={bannerLogoUploaded}
              uploading={bannerLogoUploading}
              src={bannerLogo ?? DownloadBlocIcon}
              onChange={async (evt) => {
                setBannerLogoUploading(true);
                const file = evt.target.files?.length
                  ? evt.target.files[0]
                  : null;
                if (!file) return;

                const path = await uploadFile(file);
                setBannerLogo(path);

                evt.target.value = '';
                setBannerLogoUploading(false);
                setBannerLogoUploaded(true);
              }}
              name="banner-image"
            />
          </FileInputWrapper>
        </GeneralImageInputsWrapper>
      </Section>

      <Section>
        <SectionTitle>Questions</SectionTitle>
        <SectionInfo>
          Ajouter les questions qui seronts présentées aux participants.
        </SectionInfo>

        {questions.map((question, index) => (
          <QuestionRowDeprecated
            key={index}
            question={question}
            onClick={() => setEditingQuestionId(question.id)}
          />
        ))}

        <QuestionFormDeprecated
          question={
            editingQuestionId !== null
              ? questions.find((q) => q.id === editingQuestionId)
              : undefined
          }
          onSave={(question) =>
            setQuestions((state) => {
              const previousQuestions =
                editingQuestionId !== null
                  ? state.filter((q) => q.id !== editingQuestionId)
                  : state;

              const newQuestions = [...previousQuestions, question];
              newQuestions.sort((a, b) => a.date.getTime() - b.date.getTime());
              setEditingQuestionId(null);
              return newQuestions;
            })
          }
          onDelete={(question) =>
            setQuestions((state) => state.filter((q) => q.id !== question.id))
          }
          onCancelEditing={() => setEditingQuestionId(null)}
        />
      </Section>

      <Section>
        <SectionTitle>Pop-up remerciement</SectionTitle>
        <SectionInfo>
          Ce pop-up sera présenté à chaque fois qu'un utilisateur répond à une
          question.
        </SectionInfo>

        <Checkbox
          checked={thanksPopup.active}
          onChange={(_, checked) =>
            setThanksPopup({ ...thanksPopup, active: checked })
          }
        >
          Actif
        </Checkbox>

        <TextArea
          value={thanksPopup.text}
          onChange={(evt) =>
            setThanksPopup({ ...thanksPopup, text: evt.currentTarget.value })
          }
          placeholder="Texte du pop-up..."
          rows={3}
          style={{ margin: 0, height: 'unset' }}
        />

        <FileInputWrapper>
          <FileInputLabel>Image (sous le texte)</FileInputLabel>
          <File
            uploaded={thanksPopupLogoUploaded}
            uploading={thanksPopupLogoUploading}
            src={thanksPopup.image ?? DownloadBlocIcon}
            onChange={async (evt) => {
              setThanksPopupLogoUploading(true);
              const file = evt.target.files?.length
                ? evt.target.files[0]
                : null;
              if (!file) return;

              const path = await uploadFile(file);
              setThanksPopup({ ...thanksPopup, image: path });

              evt.target.value = '';
              setThanksPopupLogoUploading(false);
              setThanksPopupLogoUploaded(true);
            }}
            name="thanks-popup-image"
          />
        </FileInputWrapper>
      </Section>

      <Section>
        <SectionTitle>Pop-up final</SectionTitle>
        <SectionInfo>Ce pop-up sera présenté à la fin du quiz.</SectionInfo>

        <Checkbox
          checked={endPopup.active}
          onChange={(_, checked) =>
            setEndPopup({ ...endPopup, active: checked })
          }
        >
          Actif
        </Checkbox>

        <TextArea
          value={endPopup.text}
          onChange={(evt) =>
            setEndPopup({ ...endPopup, text: evt.currentTarget.value })
          }
          placeholder="Texte du pop-up..."
          rows={3}
          style={{ margin: 0, height: 'unset' }}
        />

        <FileInputWrapper>
          <FileInputLabel>Image (sous le texte)</FileInputLabel>
          <File
            uploaded={endPopupLogoUploaded}
            uploading={endPopupLogoUploading}
            src={endPopup.image ?? DownloadBlocIcon}
            onChange={async (evt) => {
              setEndPopupLogoUploading(true);
              const file = evt.target.files?.length
                ? evt.target.files[0]
                : null;
              if (!file) return;

              const path = await uploadFile(file);
              setEndPopup({ ...endPopup, image: path });

              evt.target.value = '';
              setEndPopupLogoUploading(false);
              setEndPopupLogoUploaded(true);
            }}
            name="end-popup-image"
          />
        </FileInputWrapper>
      </Section>

      <ControlsContainer>
        {success !== null && <Success>{success}</Success>}
        {error !== null && <Error>{error}</Error>}
        <Button buttonType="primary" text="Sauvegarder" onClick={saveQuiz} />
      </ControlsContainer>
    </Container>
  );
};

export default QuizDeprecated;

const Container = styled.div`
  margin: 0 38px 0 38px;
  width: 100%;
`;

const Header = styled.div`
  margin: 2rem;
`;

const PageTitle = styled.h1`
  font-family: ${fonts.WorkSans.regular};
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  color: ${colors.blue.primary};
  margin-bottom: 10px;
`;

const QuizInfo = styled.div`
  font-family: ${fonts.WorkSans.regular};
  font-size: 14px;
  line-height: 18px;
  color: ${colors.blue.secondary};
  text-align: center;
  margin: 0;
`;

const Section = styled.section`
  margin-top: 20px;
  background-color: ${colors.beige.primary};
  border: 2px solid ${colors.beige.tertiary};
  border-radius: 8px;
  padding: 15px;
`;

const SectionTitle = styled.h1`
  font-family: ${fonts.WorkSans.regular};
  color: ${colors.blue.secondary};
  font-size: 15px;
  line-height: 15px;
  margin: 5px 0 15px;
`;

const SectionInfo = styled.p`
  font-family: ${fonts.WorkSans.regular};
  color: ${colors.blue.primary};
  font-size: 13px;
  margin: 5px 0;
`;

const SelectWrapper = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
  label {
    margin-right: 5px;
  }
`;

const GeneralImageInputsWrapper = styled.div`
  display: flex;
  div {
    margin-right: 1em;
  }
`;

const FileInputLabel = styled.div`
  font-family: ${fonts.WorkSans.regular};
  font-size: 12px;
  margin-bottom: 2px;
`;

const FileInputWrapper = styled.div`
  margin-top: 1em;
`;

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 1em 0;
`;

const Success = styled.div`
  font-family: ${fonts.WorkSans.regular};
  font-size: 14px;
  color: ${colors.mono.success};
  margin-right: 1em;
`;

const Error = styled.div`
  font-family: ${fonts.WorkSans.regular};
  font-size: 14px;
  color: ${colors.mono.danger};
  margin-right: 1em;
`;

import { z } from 'zod';
import { useToast } from '../../../../shadcn/components/ui/use-toast';
import { useForm } from 'react-hook-form';
import { useQuizContext } from '../../context/QuizContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../shadcn/components/ui/form';
import { Button } from '../../../../shadcn/components/ui/button';
import { Input } from '../../../../shadcn/components/ui/input';
import { putQuizQuestionRolloverTime } from '../../../../services/api/quiz/quiz';
import { timeStringToMinutes } from '../../utils/timeStringToMinutes';
import { minutesToTimeString } from '../../utils/minutesToTimeString';

const formSchema = z.object({
  questionRolloverTime: z.string().min(1, 'Time is required'),
});

export const QuizQuestionRolloverTime = () => {
  const { quiz } = useQuizContext();

  const { toast } = useToast();
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      questionRolloverTime: quiz?.questionRolloverTime ?? '',
    },
  });

  const updateQuizQuestionRolloverTimeMutation = useMutation({
    mutationFn: putQuizQuestionRolloverTime,
    onSuccess: async (data) => {
      if (data.success) {
        toast({
          title: 'Changements enregistré !',
          description: "L'heure du roulement a bien été modifié.",
        });

        queryClient.invalidateQueries({ queryKey: ['quiz', quiz?.id] });

        form.reset({
          questionRolloverTime: data.quiz.questionRolloverTime
            ? minutesToTimeString(data.quiz.questionRolloverTime)
            : '',
        });
      } else {
        toast({
          variant: 'destructive',
          title: "Oops ! Une erreur s'est produite.",
          description:
            "L'enregistrement de vos changements n'ont pas été fait. Veuillez-réessayer.",
        });
      }
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (quiz?.id) {
      updateQuizQuestionRolloverTimeMutation.mutate({
        id: quiz?.id,
        rolloverTime: timeStringToMinutes(values.questionRolloverTime),
      });
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="space-y-5">
          <h4 className="text-xl font-light leading-none tracking-tight text-bluePrimary">
            Roulement
          </h4>

          <div className="flex space-x-8">
            <FormField
              control={form.control}
              name="questionRolloverTime"
              render={({ field }) => (
                <FormItem className="flex w-full flex-row items-center justify-center space-x-4 space-y-1.5">
                  <FormLabel className="w-1/4">Heure de roulement</FormLabel>
                  <FormControl className="w-3/4">
                    <Input {...field} type="time" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex justify-end space-x-4">
            {form.formState.isDirty && (
              <>
                {!updateQuizQuestionRolloverTimeMutation.isPending && (
                  <Button
                    variant="outline"
                    type="button"
                    onClick={() => form.reset()}
                  >
                    Réinitialiser
                  </Button>
                )}
                <Button
                  type="submit"
                  isLoading={updateQuizQuestionRolloverTimeMutation.isPending}
                >
                  Enregistrer
                </Button>
              </>
            )}
          </div>
        </div>
      </form>
    </Form>
  );
};

import React, { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import AddBlocDroppable from '../components/draggableComponant/AddBlocDroppable';
import PreviewDroppable from '../components/draggableComponant/PreviewDroppable';
import BlocOptionsSection from '../components/forms/BlocOptionsSection';
import Separator from '../components/miscellaneous/Separator';
import usePreview from '../contexts/PreviewContext';
import {
    deleteUpcomingSale,
    updateItem,
    updateOrderingUpcomingSales,
} from '../services/api/api';
import {
    constructorBlocDataUpComingSale,
    grid,
    onDragEnd,
} from '../utils/droppable';
import { isNumber } from '../utils/typguards/isNumber';
import { DroppableItem, DroppablePreviewBlocs } from '../interfaces/droppable';

const HomeUpComing: React.FC = () => {
    const preview = usePreview();

    const [blocsItems, setBlocsItems] = useState<DroppableItem[]>(
        constructorBlocDataUpComingSale
    );
    const [showModalOption, setShowModalOption] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<number>();
    const [isButtonSubmitProcessing, setIsButtonSubmitProcessing] =
        useState<boolean>(false);
    const [isButtonRemoveBlocProcessing, setIsButtonRemoveBlocProcessing] =
        useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        preview.setPreviewType('upcoming');
        preview.loadHomeUpcomingPreview();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnDragEnd = (result: DropResult) => {
        const data = onDragEnd(result, blocsItems, preview.getPreviewItems());
        setBlocsItems(data.dropable1);
        preview.setPreviewItems()(data.dropable2);

        if (
            result.destination?.droppableId === 'droppable2' &&
            result.source?.droppableId === 'droppable2'
        ) {
            updateOrderingUpcomingSales(
                data.dropable2.map((item: any) => {
                    return {
                        id: item.content.id,
                        index: item.content.index,
                    };
                })
            );
        } else if (
            result.destination?.droppableId === 'droppable1' &&
            result.source?.droppableId === 'droppable2'
        ) {
            handleRemoveBloc(
                preview
                    .getPreviewItems()
                    .find((el) => el.id === result.draggableId)
            );
        }
    };

    const handleModalOption = (index: number) => {
        setShowModalOption(true);
        setSelectedItem(index);
    };

    const handleClickConfirmAddBlock = async (item: DroppablePreviewBlocs) => {
        setIsButtonSubmitProcessing(true);
        try {
            const updatedItem = await updateItem('upcoming', item);
            setShowModalOption(false);
            const items = preview.getPreviewItems();
            items.sort((a, b) => {
                const aDateStart = a.content?.items[0]?.dateStart;
                const bDateStart = b.content?.items[0]?.dateStart;

                if (!aDateStart && bDateStart) return -1;
                if (!bDateStart && aDateStart) return 1;
                if (!aDateStart && !bDateStart) return 0;

                const aDate = new Date(aDateStart);
                const bDate = new Date(bDateStart);
                return aDate.getTime() - bDate.getTime();
            });
            items.forEach((item, index) => {
                item.content.index = index;
                if (!isNumber(item.content.id)) {
                    item.content.id = updatedItem.data.data.upcomingSaleId;
                }
            });
            preview.setPreviewItems()(items);

            await updateOrderingUpcomingSales(
                items.map((item) => {
                    // Convert id to number if it is a string, or use it as is if it's already a number
                    let id: number | undefined;
                    if (typeof item.content.id === 'number') {
                        id = item.content.id;
                    } else if (typeof item.content.id === 'string') {
                        id = parseInt(item.content.id, 10);
                        if (isNaN(id)) {
                            id = undefined; // Set to undefined if the conversion fails
                        }
                    } else {
                        id = undefined; // Maintain undefined if it was originally undefined
                    }

                    // Take the index directly, no need for conversion but ensure it's a number or undefined
                    const index =
                        typeof item.content.index === 'number'
                            ? item.content.index
                            : undefined;

                    return { id, index };
                })
            );
        } catch (error) {
            console.error(error);
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 5000);
        } finally {
            setIsButtonSubmitProcessing(false);
        }
    };

    const handleRemoveBloc = async (item: any) => {
        setIsButtonRemoveBlocProcessing(true);
        const newPreviewItems = preview
            .getPreviewItems()
            .filter((previewItem) => item.id !== previewItem.id);
        preview.setPreviewItems()(newPreviewItems);
        try {
            deleteUpcomingSale(item.content.id);
            setShowModalOption(false);
        } catch (error) {
            console.error(error);
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 5000);
        } finally {
            setIsButtonRemoveBlocProcessing(false);
        }
    };

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <AddBlocDroppable blocsItems={blocsItems} />
            <Separator />
            <PreviewDroppable
                screenName="upComingSales"
                grid={grid}
                handleModalOption={handleModalOption}
            />
            {selectedItem !== undefined &&
                preview.getPreviewItems()[selectedItem] && (
                    <BlocOptionsSection
                        onClickConfirm={handleClickConfirmAddBlock}
                        selectedItem={selectedItem}
                        visible={showModalOption}
                        onRemoveBloc={handleRemoveBloc}
                        isButtonSubmitProcessing={isButtonSubmitProcessing}
                        isButtonRemoveBlocProcessing={
                            isButtonRemoveBlocProcessing
                        }
                        error={error}
                    />
                )}
        </DragDropContext>
    );
};

export default HomeUpComing;

import axios from "axios";

const ROUTE_ROOT = "quizzes";

export async function getQuizzes(): Promise<Quiz[]> {
  const response = await axios.get(`${ROUTE_ROOT}`);
  const quizzes = response.data.data;
  for (const quiz of quizzes) {
    for (const question of quiz.questions) {
      question.date = new Date(question.date);
    }
  }
  
  return quizzes as Quiz[];
}

export async function updateQuizzes(quizzes: Quiz[]): Promise<void> {
  await axios.put(`${ROUTE_ROOT}`, quizzes);
}
import { CollectionImage } from '../../interfaces/shop';

export function isCollectionImage(obj: any): obj is CollectionImage {
    return (
        obj !== null &&
        typeof obj === 'object' &&
        ('id' in obj ? typeof obj.id === 'number' : true) &&
        ('url' in obj ? typeof obj.url === 'string' : true)
    );
}

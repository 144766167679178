import { Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import colors from '../../styles/colors.styles';
import fonts from '../../styles/fonts.styles';
import { DroppableItem } from '../../interfaces/droppable';

interface AddBlocDroppableProps {
  blocsItems: DroppableItem[];
}

const AddBlocDroppable = ({ blocsItems }: AddBlocDroppableProps) => {
  const grid = 8;
  return (
    <Container>
      <Title>BLOCS À AJOUTER</Title>
      <Droppable droppableId="droppable1">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver, grid)}
          >
            {blocsItems.map((item: DroppableItem, index: number) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided: any, snapshot: any) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                      grid
                    )}
                  >
                    {item.id}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Container>
  );
};

export default AddBlocDroppable;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 30px 0px 30px;
  position: sticky;
  top: 0;
`;

const Title = styled.div`
  font-size: 15px;
  font-family: ${fonts.WorkSans.semiBold};
  color: ${colors.blue.secondary};
  margin-bottom: 20px;
`;

const getListStyle = (isDraggingOver: boolean, grid: number) => ({
  background: isDraggingOver ? 'lightblue' : '',
  padding: grid,
  width: 250,
  flexGrow: 1,
});

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: CSSStyleRule,
  grid: number
) => ({
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${22}px 0`,
  background: isDragging ? 'lightgreen' : colors.blue.primary,
  color: colors.mono.white,
  fontFamily: fonts.WorkSans.regular,
  fontSize: '13px',
  borderRadius: '8px',
  height: '100px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ...draggableStyle,
});

import styled from 'styled-components';
import { PreviewActions } from './PreviewActions';

type OrderConfirmationPreviewProps = {
  image: {
    link: string;
    width: number;
    height: number;
  };
  ctaBackgroundColor: string;
  ctaTextColor: string;
  ctaText: string;
  isActive: boolean;
  onReset: () => void;
  onToggleActive: () => void;
  title: React.ReactNode;
};

export const OrderConfirmationPreview = ({
  image,
  ctaBackgroundColor,
  ctaTextColor,
  ctaText,
  isActive,
  onReset,
  onToggleActive,
  title,
}: OrderConfirmationPreviewProps) => {
  return (
    <Container>
      <ContentContainer>
        {title}
        {image.link.length > 0 ? (
          <PreviewImage
            src={image.link}
            alt="preview"
            width={image.width}
            height={image.height}
          />
        ) : undefined}
        {ctaText.length > 0 ? (
          <CtaButton backgroundColor={ctaBackgroundColor} color={ctaTextColor}>
            {ctaText}
          </CtaButton>
        ) : undefined}
      </ContentContainer>
      <PreviewActions
        imageLink={image.link}
        ctaText={ctaText}
        onReset={onReset}
        onToggleActive={onToggleActive}
        isActive={isActive}
      />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PreviewImage = styled.img<{
  src: string;
  width: number;
  height: number;
}>`
  src: ${(props) => props.src};
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CtaButton = styled.button<{
  backgroundColor: string;
  color: string;
}>`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  padding: 10px 143px;
  border-radius: 5px;
  margin-top: 12px;
  font-weight: bold;
`;

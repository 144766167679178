import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getQuiz } from '../../../services/api/quiz/quiz';
import { Quiz } from '../types';
import { parseQuizDTO } from '../utils/parseQuizDTO';
import { useQuery } from '@tanstack/react-query';

type QuizContextType = {
  loading: boolean;
  quiz: Quiz | null;
};

const QuizContext = createContext<QuizContextType | undefined>(undefined);

type QuizProviderProps = {
  quizId?: string;
  children: React.ReactNode;
};

export const QuizProvider = ({ quizId, children }: QuizProviderProps) => {
  const [quiz, setQuiz] = useState<Quiz | null>(null);

  const quizQuery = useQuery({
    queryKey: ['quiz', quizId],
    enabled: !!quizId,
    queryFn: ({ queryKey }) => getQuiz(queryKey[1] ?? ''),
  });

  useEffect(() => {
    if (quizQuery.data) {
      const data = quizQuery.data;
      const parsedQuiz = parseQuizDTO(data);

      setQuiz(parsedQuiz);
    }
  }, [quizQuery.data]);

  const value = useMemo(
    () => ({
      loading: quizQuery.isLoading,
      quiz,
    }),
    [quizQuery.isLoading, quiz]
  );

  return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>;
};

export const useQuizContext = () => {
  const context = useContext(QuizContext);
  if (!context) {
    throw new Error('useQuiz must be used within a QuizProvider');
  }
  return context;
};

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { QuizInList } from '../types';
import { parseQuizInListDTO } from '../utils/parseQuizInListDTO';
import { getQuizList } from '../../../services/api/quiz/quiz';
import { useQuery } from '@tanstack/react-query';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../shadcn/components/ui/table';
import { Badge } from '../../../shadcn/components/ui/badge';
import { formatQuizDate } from '../utils/formatQuizDate';
import { getQuizStatus } from '../utils/getQuizStatus';

export const QuizList = () => {
  const navigate = useNavigate();

  const [quizList, setQuizList] = useState<QuizInList[]>([]);

  const quizListQuery = useQuery({
    queryKey: ['quiz-list'],
    queryFn: getQuizList,
  });

  useEffect(() => {
    if (quizListQuery.data) {
      setQuizList(quizListQuery?.data?.map((quiz) => parseQuizInListDTO(quiz)));
    }
  }, [quizListQuery.data]);

  const onQuizLintClick = (id: number) => {
    navigate(`/quiz/${id}`);
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Titre</TableHead>
          <TableHead className="w-[100px]">Questions</TableHead>
          <TableHead>Début</TableHead>
          <TableHead>Fin</TableHead>
          <TableHead>Status</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {quizList.map((quiz) => {
          const quizStatus = getQuizStatus(
            quiz.isActive,
            quiz.startDate,
            quiz.endDate
          );

          return (
            <TableRow
              key={quiz.id}
              className="cursor-pointer"
              onClick={() => onQuizLintClick(quiz.id)}
            >
              <TableCell className="font-medium">{quiz.title}</TableCell>
              <TableCell>{quiz.questionsCount}</TableCell>
              <TableCell>{formatQuizDate(quiz.startDate)}</TableCell>
              <TableCell>{formatQuizDate(quiz.endDate)}</TableCell>
              <TableCell>
                <Badge variant={quizStatus?.badgeVariant}>
                  {quizStatus?.statusText}
                </Badge>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

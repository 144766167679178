import { z } from 'zod';
import { useQuizContext } from '../../context/QuizContext';
import { useToast } from '../../../../shadcn/components/ui/use-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../shadcn/components/ui/form';
import { ColorPicker } from '../../../../components/forms/inputs/ColorPicker';
import { Button } from '../../../../shadcn/components/ui/button';
import { Input } from '../../../../shadcn/components/ui/input';
import { putAnsweredPageCTA } from '../../../../services/api/quiz/quiz';
import { Switch } from '../../../../shadcn/components/ui/switch';
import { CTAAction } from '../types';
import {
  ToggleGroup,
  ToggleGroupItem,
} from '../../../../shadcn/components/ui/toggle-group';

const formSchema = z
  .object({
    CTALabel: z.string(),
    CTALabelColor: z.string(),
    CTABackgroundColor: z.string(),
    CTASubTitleText: z.string(),
    CTASubTitleTextColor: z.string(),
    displayCTA: z.boolean(),
    CTAAction: z.nativeEnum(CTAAction),
    CTAActionUrl: z.string().optional(),
  })
  .refine(
    (data) => {
      if (
        data.CTAAction === CTAAction.CUSTOM_URL ||
        data.CTAAction === CTAAction.DEEP_LINK
      ) {
        if (!data.CTAActionUrl) {
          return false;
        }

        if (data.CTAAction === CTAAction.CUSTOM_URL) {
          const urlRegex = /^(http|https):\/\/[^ "]+$/;
          return urlRegex.test(data.CTAActionUrl);
        }

        if (data.CTAAction === CTAAction.DEEP_LINK) {
          const deepLinkRegex = /^thebradery:\/\/[^ "]+$/;
          return deepLinkRegex.test(data.CTAActionUrl);
        }
      }

      return true;
    },
    {
      message:
        'CTAActionUrl must be a valid URL when CTAAction is CUSTOM_URL or a valid deep link starting with thebradery:// when CTAAction is DEEP_LINK',
      path: ['CTAActionUrl'],
    }
  );

export const QuizResultAnswerCTA = () => {
  const { quiz } = useQuizContext();

  const { toast } = useToast();
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      CTALabel: quiz?.answeredPage?.CTALabel ?? '',
      CTALabelColor: quiz?.answeredPage?.CTALabelColor ?? '',
      CTABackgroundColor: quiz?.answeredPage?.CTABackgroundColor ?? '',
      CTASubTitleText: quiz?.answeredPage?.CTASubTitleText ?? '',
      CTASubTitleTextColor: quiz?.answeredPage?.CTASubTitleTextColor ?? '',
      displayCTA: quiz?.answeredPage?.displayCTA ?? false,
      CTAAction: quiz?.answeredPage?.CTAAction ?? CTAAction.NOTIFICATIONS,
      CTAActionUrl: quiz?.answeredPage?.CTAActionUrl ?? '',
    },
  });

  const putQuizAnsweredPageCTAMutation = useMutation({
    mutationFn: putAnsweredPageCTA,
    onSuccess: async (data) => {
      if (data.success) {
        toast({
          title: 'CTA enregistré !',
          description: 'Les changements sur le CTA ont bien été enregistré',
        });

        queryClient.invalidateQueries({ queryKey: ['quiz', quiz?.id] });

        const formValues = form.getValues();

        form.reset(formValues);
      } else {
        toast({
          variant: 'destructive',
          title: "Oops ! Une erreur s'est produite.",
          description:
            "Les changements sur le CTA n'ont pas été enregistré. Veuillez-réessayer.",
        });
      }
    },
    onError(error, variables, context) {
      console.log({ error, variables, context });
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (quiz && quiz.answeredPage) {
      putQuizAnsweredPageCTAMutation.mutate({
        quizId: quiz.id,
        quizAnsweredPageId: quiz.answeredPage.id,
        ...values,
      });
    }
  };

  const watchCTAActionValue = form.watch('CTAAction');

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="space-y-5">
          <div className="row flex space-x-5">
            <h4 className="text-xl font-light leading-none tracking-tight text-bluePrimary">
              CTA
            </h4>
            <FormField
              control={form.control}
              name="displayCTA"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-2">
                  <FormControl>
                    <Switch
                      id="displayCTA"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>
                    {quiz?.answeredPage?.displayCTA ? 'Affiché' : 'Caché'}
                  </FormLabel>
                  <FormMessage className="col-span-6 col-start-3" />
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-2 gap-x-10 gap-y-5">
            <div className="space-y-4">
              <div className="space-y-3">
                <FormField
                  control={form.control}
                  name="CTALabel"
                  render={({ field }) => (
                    <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                      <FormLabel className="col-span-2 flex items-center justify-start">
                        Texte CTA
                      </FormLabel>
                      <FormControl className="col-span-6 col-start-3">
                        <Input {...field} />
                      </FormControl>
                      <FormMessage className="col-span-6 col-start-3" />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="CTALabelColor"
                  render={({ field }) => (
                    <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                      <FormLabel className="col-span-2 flex items-center justify-start">
                        Couleur de texte
                      </FormLabel>
                      <FormControl className="col-span-6 text-right">
                        <ColorPicker {...field} />
                      </FormControl>
                      <FormMessage className="col-span-6 col-start-3" />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="space-y-4">
              <div className="space-y-3">
                <FormField
                  control={form.control}
                  name="CTASubTitleText"
                  render={({ field }) => (
                    <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                      <FormLabel className="col-span-2 flex items-center justify-start">
                        Sous-titre CTA
                      </FormLabel>
                      <FormControl className="col-span-6 col-start-3">
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="CTASubTitleTextColor"
                  render={({ field }) => (
                    <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                      <FormLabel className="col-span-2 flex items-center justify-start">
                        Couleur de texte
                      </FormLabel>
                      <FormControl className="col-span-6 text-right">
                        <ColorPicker {...field} />
                      </FormControl>
                      <FormMessage className="col-span-6 col-start-3" />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div>
              <FormField
                control={form.control}
                name="CTABackgroundColor"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                    <FormLabel className="col-span-2 flex items-center justify-start">
                      Couleur bouton CTA
                    </FormLabel>
                    <FormControl className="col-span-6 text-right">
                      <ColorPicker {...field} />
                    </FormControl>
                    <FormMessage className="col-span-6 col-start-3" />
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-3">
              <FormField
                control={form.control}
                name="CTAAction"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                    <FormControl className="col-span-6 col-start-3">
                      <ToggleGroup
                        type="single"
                        variant="outline"
                        className="justify-start"
                        {...field}
                        onValueChange={(newValue: CTAAction) => {
                          if (newValue) {
                            field.onChange(newValue);
                          }
                        }}
                      >
                        <ToggleGroupItem value={CTAAction.NOTIFICATIONS}>
                          Notifications
                        </ToggleGroupItem>
                        <ToggleGroupItem value={CTAAction.CUSTOM_URL}>
                          URL
                        </ToggleGroupItem>
                        <ToggleGroupItem value={CTAAction.DEEP_LINK}>
                          Deep link
                        </ToggleGroupItem>
                      </ToggleGroup>
                    </FormControl>
                    <FormMessage className="col-span-6 col-start-3" />
                  </FormItem>
                )}
              />

              {watchCTAActionValue !== CTAAction.NOTIFICATIONS && (
                <div>
                  <FormField
                    control={form.control}
                    name="CTAActionUrl"
                    render={({ field }) => (
                      <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                        <FormLabel className="col-span-2 flex items-center justify-start">
                          URL CTA
                        </FormLabel>
                        <FormControl className="col-span-6 col-start-3">
                          <Input {...field} />
                        </FormControl>
                        <FormMessage className="col-span-6 col-start-3" />
                      </FormItem>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end space-x-4">
            {form.formState.isDirty && (
              <>
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => form.reset()}
                >
                  Réinitialiser
                </Button>

                <Button
                  type="submit"
                  isLoading={putQuizAnsweredPageCTAMutation.isPending}
                >
                  Enregistrer
                </Button>
              </>
            )}
          </div>
        </div>
      </form>
    </Form>
  );
};

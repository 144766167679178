import {
  Control,
  FieldArrayWithId,
  UseFieldArrayReturn,
} from 'react-hook-form';
import { TableCell, TableRow } from '../../../../shadcn/components/ui/table';
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../../../shadcn/components/ui/form';
import { Input } from '../../../../shadcn/components/ui/input';
import { Button } from '../../../../shadcn/components/ui/button';
import { GripVertical, X } from 'lucide-react';
import { Checkbox } from '../../../../shadcn/components/ui/checkbox';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { QuizQuestionFormField } from '../../types';

type QuizQuestionChoiceListRowProps = {
  formControl: Control<QuizQuestionFormField, any>;
  index: number;
  choicesFieldArray: UseFieldArrayReturn<
    QuizQuestionFormField,
    'choices',
    'key'
  >;
  choice: FieldArrayWithId<QuizQuestionFormField, 'choices', 'key'>;
};

export const QuizQuestionChoiceListRow = ({
  formControl,
  index,
  choice,
  choicesFieldArray,
}: QuizQuestionChoiceListRowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: choice.key });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <TableRow ref={setNodeRef} style={style}>
      <TableCell
        className="text-left"
        ref={setActivatorNodeRef}
        {...listeners}
        {...attributes}
      >
        <div>
          <GripVertical size={18} className="text-greySecondary" />
        </div>
      </TableCell>
      <TableCell className="font-medium">
        <FormField
          control={formControl}
          name={`choices.${index}.text`}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </TableCell>
      <TableCell className="text-center">
        <FormField
          control={formControl}
          name={`choices.${index}.isCorrectAnswer`}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  onBlur={field.onBlur}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </TableCell>

      <TableCell className="text-right">
        {choicesFieldArray.fields.length > 1 && (
          <Button
            size="icon-sm"
            variant="destructive"
            type="button"
            onClick={() => choicesFieldArray.remove(index)}
          >
            <X className="h-5 w-5" />
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

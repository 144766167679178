import { UpComingSale } from '../../interfaces/shop';

export const isUpComingSaleWithIdAndIndex = (
    content: any
): content is UpComingSale & { id: number; index: number } => {
    return (
        content &&
        typeof content === 'object' &&
        typeof content.id === 'number' &&
        typeof content.index === 'number'
    );
};

import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { HomeItem } from "../../interfaces/shop"
import colors from "../../styles/colors.styles"
import fonts from "../../styles/fonts.styles"

interface CarouselProps {
  data: HomeItem
  isEditing: boolean
}

const Carousel: React.FC<CarouselProps> = ({ data, isEditing }) => {
  const [index, setIndex] = useState<number>(0)

  const handleIncrementIndex = () => {
    if (data.items.length - 1 === index) {
      setIndex(0)
    } else {
      setIndex(index + 1)
    }
  }

  useEffect(() => {
    const timer = setTimeout(handleIncrementIndex, 4000)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index])

  return (
    <Container>
      <Image
        src={data.items[index]?.image?.defaultUrl}
        alt=""
        setOpacity={isEditing && !!data.items[index]?.image?.defaultUrl}
      />
    </Container>
  )
}

export default Carousel

const Container = styled.div`
  display: flex;
  font-family: ${fonts.WorkSans.regular};
  font-size: 13px;
  color: ${colors.mono.white};
  height: 100%;
  width: 100%;
`
const Image = styled.img<{ setOpacity: boolean }>`
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: ${(p: any) => (p.setOpacity ? `0.5` : `1`)};
`

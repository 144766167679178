import React from 'react';
import styled from 'styled-components';
import Title from '../miscellaneous/Title';
import { Checkbox } from '../../../../shadcn/components/ui/checkbox';
import usePreview from '../../../../contexts/PreviewContext';

interface DeveloperSectionProps {
    selectedItem: number;
    selectedTab: number;
}

export const DeveloperSection: React.FC<DeveloperSectionProps> = ({
    selectedItem,
    selectedTab,
}) => {
    const preview = usePreview();
    const isOnlyDisplayedToDevs =
        preview.getIsOnlyDisplayedToDevs(selectedItem);

    return (
        <Container>
            <Title>Développeurs</Title>
            <CheckboxContainer>
                <Checkbox
                    checked={isOnlyDisplayedToDevs}
                    onClick={() => {
                        preview.setIsOnlyDisplayedToDevs(
                            selectedItem,
                            selectedTab,
                            !isOnlyDisplayedToDevs
                        );
                    }}
                />
                <label className="ml-2 text-xs text-primary-blue">
                    Afficher uniquement aux comptes développeurs
                </label>
            </CheckboxContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
`;

import { Plus } from 'lucide-react';
import { Button } from '../../../../shadcn/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../../shadcn/components/ui/dialog';
import { Form } from '../../../../shadcn/components/ui/form';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '../../../../shadcn/components/ui/use-toast';
import { Quiz } from '../../types';
import { generateProgressStepZodSchema } from '../utils/generateProgressStepZodSchema';
import { postCreateQuizProgressStep } from '../../../../services/api/quiz/quiz';
import { QuizProgressStepForm } from './QuizProgressStepForm';

type QuizCreateProgressStepDialogProps = {
  quiz: Quiz;
};

export const QuizCreateProgressStepDialog = ({
  quiz,
}: QuizCreateProgressStepDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { toast } = useToast();
  const queryClient = useQueryClient();

  const formSchema = generateProgressStepZodSchema();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      value: '',
      titleHtml: '',
      titleColor: '',
      subTitleColor: '',
      subTitleHtml: '',
    },
  });

  useEffect(() => {
    if (!isOpen) {
      form.reset();
    }
  }, [form, isOpen]);

  const postQuizProgressStepCreationMutation = useMutation({
    mutationFn: postCreateQuizProgressStep,
    onSuccess: async (data) => {
      if (data.success) {
        toast({
          title: 'Étape Créé !',
          description: "L'étape à bien été créé",
        });

        queryClient.invalidateQueries({ queryKey: ['quiz', quiz.id] });

        form.reset();
        setIsOpen(false);
      } else {
        toast({
          variant: 'destructive',
          title: "Oops ! Une erreur s'est produite.",
          description:
            "La création de l'étape n'a pas été faite. Veuillez-réessayer.",
        });
      }
    },
  });

  const handleSubmit = async (data: z.infer<typeof formSchema>) => {
    if (quiz.id) {
      postQuizProgressStepCreationMutation.mutate({
        quizRoadmapId: quiz.roadmap.id,
        value: +data.value,
        titleHtml: data.titleHtml,
        titleColor: data.titleColor,
        subTitleHtml: data.subTitleHtml,
        subTitleColor: data.subTitleColor,
      });
    }
  };

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <DialogTrigger asChild>
            <Button size="icon-sm" type="button">
              <Plus className="h-4 w-4" />
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Créer une nouvelle étape</DialogTitle>
              <DialogDescription>
                Veuillez renseigner les informations relative à l'étape.
              </DialogDescription>
            </DialogHeader>
            <QuizProgressStepForm form={form} />
            <DialogFooter>
              <Button
                type="submit"
                onClick={form.handleSubmit(handleSubmit)}
                isLoading={postQuizProgressStepCreationMutation.isPending}
              >
                Créer
              </Button>
            </DialogFooter>
          </DialogContent>
        </form>
      </Form>
    </Dialog>
  );
};

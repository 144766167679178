import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../../../shadcn/components/ui/card';
import { QuizQuestionColors } from './QuizQuestionColors';
import { QuizQuestionList } from './QuizQuestionList';
import { QuizQuestionRolloverTime } from './QuizQuestionRolloverTime';

export const QuizQuestions = () => {
  return (
    <Card className="bg-beigePrimary">
      <CardHeader className="align-center flex-row justify-between">
        <CardTitle className="text-bluePrimary">Questions</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="mb-8 grid auto-rows-auto grid-cols-2">
          <QuizQuestionColors />
          <QuizQuestionRolloverTime />
        </div>

        <QuizQuestionList />
      </CardContent>
    </Card>
  );
};

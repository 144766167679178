import React from 'react';
import styled from 'styled-components';
import usePreview from '../../../../contexts/PreviewContext';
import colors from '../../../../styles/colors.styles';
import fonts from '../../../../styles/fonts.styles';
import { utcToLocalDate } from '../../../../utils/date';
import Title from '../miscellaneous/Title';
import { parseISO } from 'date-fns';

interface SellTimeProps {
  selectedItem: number;
  selectedTab: number;
  title: string;
}

const DisplayDate: React.FC<SellTimeProps> = ({
  selectedItem,
  selectedTab,
  title,
}) => {
  const preview = usePreview();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    preview.setPreviewItemTabField(
      selectedItem,
      selectedTab,
      event.target.name,
      parseISO(event.target.value)
    );
  };

  const dateStartValue = () => {
    const utcDate = preview.getSelectedItemTab(
      selectedItem,
      selectedTab
    )?.dateStart;

    const localDate = utcToLocalDate(utcDate);

    return localDate.substring(0, 16) || '';
  };

  const dateStopValue = () => {
    const utcDate = preview.getSelectedItemTab(
      selectedItem,
      selectedTab
    )?.dateStop;

    const localDate = utcToLocalDate(utcDate);

    return localDate.substring(0, 16) || '';
  };

  return (
    <Container>
      <Title>{title}</Title>
      <DateContainer>
        <Label>Début</Label>
        <Input
          name="dateStart"
          onChange={handleChange}
          value={dateStartValue()}
        />
      </DateContainer>
      <DateContainer>
        <Label>Fin</Label>
        <Input
          name="dateStop"
          onChange={(event) => handleChange(event)}
          value={dateStopValue()}
        />
      </DateContainer>
    </Container>
  );
};

export default DisplayDate;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 15px;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  font-family: ${fonts.WorkSans.regular};
  font-size: 12px;
  color: ${colors.blue.primary};
  width: 60px;
  text-align: right;
  margin: 0 8px 0 20px;
`;

const Input = styled.input.attrs({ type: 'datetime-local' })`
  box-sizing: border-box;
  width: calc(100%);
  background-color: ${colors.beige.tertiary};
  margin: 5px 22px 5px 0;
  border: none;
  border-radius: 4px;
  padding: 11px 11px 11px 11px;

  &:focus {
    outline: none;
  }
`;

import React, { TextareaHTMLAttributes } from "react"
import styled from "styled-components"
import colors from "../../../styles/colors.styles"
import fonts from "../../../styles/fonts.styles"

const TextArea: React.FC<TextareaHTMLAttributes<HTMLTextAreaElement>> = (
  props
) => {
  return <Container {...props} name="descriptionText" />
}

export default TextArea

const Container = styled.textarea`
  box-sizing: border-box;
  width: calc(100% - 44px);
  background-color: ${colors.beige.tertiary};
  margin: 11px 22px 22px 22px;
  border: none;
  border-radius: 4px;
  padding: 11px 11px 11px 11px;
  font-family: ${fonts.WorkSans.regular};
  font-size: 12px;
  color: ${colors.blue.primary};
  height: 200px;
  resize: vertical;

  &:focus {
    outline: none;
  }
`

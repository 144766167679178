import { HomeItem } from '../../interfaces/shop';
import { isHomeItem } from './isHomeItem';

export const isHomeItemWithIdAndIndex = (
    content: any
): content is HomeItem & { id: string; index: number } => {
    return (
        content &&
        typeof content === 'object' &&
        typeof content.id === 'string' &&
        typeof content.index === 'number' &&
        isHomeItem(content)
    );
};

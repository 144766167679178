import { z } from 'zod';
import { useQuizContext } from '../../context/QuizContext';
import { useToast } from '../../../../shadcn/components/ui/use-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import File from '../../../../components/forms/inputs/File';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../shadcn/components/ui/form';
import { ColorPicker } from '../../../../components/forms/inputs/ColorPicker';
import { Button } from '../../../../shadcn/components/ui/button';
import { useState } from 'react';
import { uploadFile } from '../../../../services/api/api';
import { putAnsweredPageGeneralStyle } from '../../../../services/api/quiz/quiz';

const formSchema = z.object({
  minimizeQuizButtonColor: z.string(),
  maximizeQuizButtonColor: z.string(),
  maximizeQuizButtonLogoUrl: z.string(),
  logoUrl: z.string(),
});

export const QuizResultGeneralStyle = () => {
  const { quiz } = useQuizContext();

  const [maximizeQuizButtonLogoUploaded, setMaximizeQuizButtonLogoUploaded] =
    useState(false);
  const [maximizeQuizButtonLogoUploading, setMaximizeQuizButtonLogoUploading] =
    useState(false);

  const [logoUploaded, setLogoUploaded] = useState(false);
  const [logoUploading, setLogoUploading] = useState(false);

  const { toast } = useToast();
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      logoUrl: quiz?.answeredPage?.logoUrl,
      minimizeQuizButtonColor: quiz?.minimizeQuizButtonColor ?? '',
      maximizeQuizButtonColor: quiz?.maximizeQuizButtonColor ?? '',
      maximizeQuizButtonLogoUrl: quiz?.maximizeQuizButtonLogoUrl ?? '',
    },
  });

  const putQuizAnsweredPageGeneralStyleMutation = useMutation({
    mutationFn: putAnsweredPageGeneralStyle,
    onSuccess: async (data) => {
      if (data.success) {
        toast({
          title: 'Style générale enregistré !',
          description:
            'Les changements du style générale ont bien été enregistré',
        });

        queryClient.invalidateQueries({ queryKey: ['quiz', quiz?.id] });

        const formValues = form.getValues();

        form.reset(formValues);
      } else {
        toast({
          variant: 'destructive',
          title: "Oops ! Une erreur s'est produite.",
          description:
            "Les changements du style générale n'ont pas été enregistré. Veuillez-réessayer.",
        });
      }
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (quiz && quiz.answeredPage) {
      putQuizAnsweredPageGeneralStyleMutation.mutate({
        quizId: quiz.id,
        quizAnsweredPageId: quiz.answeredPage.id,
        ...values,
      });
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="space-y-5">
          <h4 className="text-xl font-light leading-none tracking-tight text-bluePrimary">
            Style générale
          </h4>
          <div className="grid grid-cols-2">
            <div className="flex space-x-8">
              <FormField
                control={form.control}
                name="logoUrl"
                render={({ field }) => (
                  <FormItem className="flex flex-col items-center space-y-1.5">
                    <FormLabel>Logo du quiz</FormLabel>
                    <FormControl>
                      <File
                        uploaded={logoUploaded}
                        uploading={logoUploading}
                        src={field.value}
                        onChange={async (evt) => {
                          setLogoUploading(true);
                          const file = evt.target.files?.length
                            ? evt.target.files[0]
                            : null;
                          if (!file) return;

                          const path = await uploadFile(file);

                          field.onChange(path);

                          evt.target.value = '';
                          setLogoUploading(false);
                          setLogoUploaded(true);
                        }}
                        name={field.name}
                        onBlur={field.onBlur}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="maximizeQuizButtonLogoUrl"
                render={({ field }) => (
                  <FormItem className="flex flex-col items-center space-y-1.5">
                    <FormLabel>Logo bouton ouverture de quiz</FormLabel>
                    <FormControl>
                      <File
                        uploaded={maximizeQuizButtonLogoUploaded}
                        uploading={maximizeQuizButtonLogoUploading}
                        src={field.value}
                        onChange={async (evt) => {
                          setMaximizeQuizButtonLogoUploading(true);
                          const file = evt.target.files?.length
                            ? evt.target.files[0]
                            : null;
                          if (!file) return;

                          const path = await uploadFile(file);

                          field.onChange(path);

                          evt.target.value = '';
                          setMaximizeQuizButtonLogoUploading(false);
                          setMaximizeQuizButtonLogoUploaded(true);
                        }}
                        name={field.name}
                        onBlur={field.onBlur}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex flex-col items-start space-y-4">
              <FormField
                control={form.control}
                name="minimizeQuizButtonColor"
                render={({ field }) => (
                  <FormItem className="grid w-full grid-cols-4 gap-x-4 space-y-0">
                    <FormLabel className="col-span-2 col-start-1 grid place-items-center">
                      Couleur du bouton de fermeture du quiz
                    </FormLabel>
                    <FormControl className="col-span-2 col-start-3 place-items-center bg-bluePrimary">
                      <ColorPicker {...field} />
                    </FormControl>
                    <FormMessage className="col-span-2 col-start-1 grid place-items-center" />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="maximizeQuizButtonColor"
                render={({ field }) => (
                  <FormItem className="grid w-full grid-cols-4 gap-x-4 space-y-0">
                    <FormLabel className="col-span-2 col-start-1 grid place-items-center">
                      Couleur du bouton d'ouverture du quiz
                    </FormLabel>
                    <FormControl className="col-span-2 col-start-3 place-items-center bg-bluePrimary">
                      <ColorPicker {...field} />
                    </FormControl>
                    <FormMessage className="col-span-2 col-start-1 grid place-items-center" />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="flex justify-end space-x-4">
            {form.formState.isDirty && (
              <>
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => form.reset()}
                >
                  Réinitialiser
                </Button>

                <Button
                  type="submit"
                  isLoading={putQuizAnsweredPageGeneralStyleMutation.isPending}
                >
                  Enregistrer
                </Button>
              </>
            )}
          </div>
        </div>
      </form>
    </Form>
  );
};

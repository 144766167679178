import { Plus } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../../shadcn/components/ui/dialog';
import { Button } from '../../../../shadcn/components/ui/button';
import { Input } from '../../../../shadcn/components/ui/input';
import { DatePicker } from '../../../../components/forms/inputs/DatePicker';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../shadcn/components/ui/form';
import { useEffect, useState } from 'react';
import { postNewQuiz } from '../../../../services/api/quiz/quiz';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

const formSchema = z.object({
  title: z.string().min(2).max(50),
  startDate: z.date(),
  endDate: z.date(),
});

export const QuizCreationDialog = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const createQuizMutation = useMutation({
    mutationFn: postNewQuiz,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['quiz-list'] });
      setOpen(false);
      navigate(`/quiz/${data}`);
    },
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      title: '',
    },
  });

  useEffect(() => {
    if (!open) {
      form.reset();
    }
  }, [open, form]);

  const handleConfirmNewQuiz = async (data: z.infer<typeof formSchema>) => {
    await createQuizMutation.mutateAsync({ ...data });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Form {...form}>
        <form>
          <DialogTrigger>
            <Button type="button">
              <Plus />
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Créer un nouveau quiz</DialogTitle>
            </DialogHeader>

            <div className="grid gap-4 py-4">
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Nom du quiz</FormLabel>
                    <FormControl className="col-span-3">
                      <Input {...field} />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2" />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="startDate"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Début</FormLabel>
                    <FormControl buttonClassName="col-span-3">
                      <DatePicker
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        disabled={field.disabled}
                        name={field.name}
                        ref={field.ref}
                      />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2" />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="endDate"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Fin</FormLabel>
                    <FormControl buttonClassName="col-span-3">
                      <DatePicker
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        disabled={field.disabled}
                        name={field.name}
                        ref={field.ref}
                      />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2" />
                  </FormItem>
                )}
              />
            </div>

            <DialogFooter>
              {!createQuizMutation.isPending && (
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Annuler
                </Button>
              )}
              <Button
                type="button"
                onClick={() => {
                  form.handleSubmit(handleConfirmNewQuiz)();
                }}
                isLoading={createQuizMutation.isPending}
              >
                Créer
              </Button>
            </DialogFooter>
          </DialogContent>
        </form>
      </Form>
    </Dialog>
  );
};

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../../../shadcn/components/ui/card';
import { QuizResultProgressStyle } from './QuizProgressStyle';
import { QuizResultAnswerCTA } from './QuizResultAnswerCTA';
import { QuizResultAnswerMessages } from './QuizResultAnswerMessages';
import { QuizResultGeneralStyle } from './QuizResultGeneralStyle';
import { QuizResultProgress } from './QuizResultProgress';

export const QuizResult = () => {
  return (
    <Card className="bg-beigePrimary">
      <CardHeader className="align-center flex-row justify-between">
        <CardTitle className="text-bluePrimary">Page résultat</CardTitle>
      </CardHeader>
      <CardContent className="space-y-10">
        <QuizResultGeneralStyle />
        <QuizResultAnswerMessages />
        <QuizResultAnswerCTA />
        <QuizResultProgress />
        <QuizResultProgressStyle />
      </CardContent>
    </Card>
  );
};

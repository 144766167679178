import { z } from 'zod';

export const generateProgressStepZodSchema = () => {
  return z.object({
    value: z.string(),
    titleHtml: z.string().optional(),
    titleColor: z.string().optional(),
    subTitleHtml: z.string().optional(),
    subTitleColor: z.string().optional(),
  });
};

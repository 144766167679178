import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import styled from 'styled-components';
import Header from './components/Header';
import Navbar from './components/Navbar';
import ProtectedRoute from './components/navigation/ProtectedRoute.component';
import useAuth from './contexts/AuthContext';
import { PreviewProvider } from './contexts/PreviewContext';
import Categories from './pages/Categories';
import Christmas from './pages/Christmas';
import HomeInProgress from './pages/HomeInProgress';
import HomeUpComing from './pages/HomeUpComing';
import Login from './pages/Login';
import Marketplace from './pages/Marketplace';
import SecondHand from './pages/SecondHand';
import Settings from './pages/Settings';
import Travels from './pages/Travels';
import Users from './pages/Users/Users';
import QuizzesDeprecated from './pages/quiz_deprecated/QuizzesDeprecated';
import colors from './styles/colors.styles';
import configAxios from './utils/axios';
import QuizDeprecated from './pages/quiz_deprecated/QuizDeprecated';
import { QuizListPage } from './domains/quiz/QuizList/QuizListPage';
import { QuizPageWrapper } from './domains/quiz/Quiz/QuizPageWrapper';

configAxios();

export const DEFAULT_HOME_LOCATION = '/home-in-progress';

function App() {
  const { user } = useAuth();

  if (!process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN) {
    return null;
  }

  const devMode =
    process.env.REACT_APP_ENV === 'STAGING' ||
    process.env.REACT_APP_ENV === 'DEV';
  const client = new ApolloClient({
    uri: devMode
      ? process.env.REACT_APP_SHOPIFY_API_URL_STAGING
      : process.env.REACT_APP_SHOPIFY_API_URL,
    cache: new InMemoryCache(),
    headers: {
      'X-Shopify-Storefront-Access-Token':
        (devMode
          ? process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN_STAGING
          : process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN) || '',
    },
  });

  return (
    <MainContainer>
      <ApolloProvider client={client}>
        <PreviewProvider>
          <Router>
            {user ? (
              <>
                <GridLayoutContainer>
                  <HeaderContainer>
                    <Header />
                  </HeaderContainer>
                  <SidebarContainer>
                    <Navbar />
                  </SidebarContainer>
                  <MainContentContainer>
                    <Routes>
                      {/* Protected routes for admin and editor */}
                      <Route
                        path="/login"
                        element={<Navigate to={DEFAULT_HOME_LOCATION} />}
                      />
                      <Route
                        element={
                          <ProtectedRoute
                            roles={['admin', 'editor']}
                            fallback="/login"
                          />
                        }
                      >
                        <Route
                          path="/home-in-progress"
                          element={<HomeInProgress />}
                        />
                        <Route
                          path="/home-up-coming"
                          element={<HomeUpComing />}
                        />
                        <Route path="/second-hand" element={<SecondHand />} />
                        <Route path="/marketplace" element={<Marketplace />} />
                        <Route path="/christmas" element={<Christmas />} />
                        <Route path="/travels" element={<Travels />} />
                        <Route
                          path="/quiz_deprecated"
                          element={<QuizzesDeprecated />}
                        />
                        <Route
                          path="/quiz_deprecated/:id"
                          element={<QuizDeprecated />}
                        />
                        <Route path="/quiz" element={<QuizListPage />} />
                        <Route path="/quiz/:id" element={<QuizPageWrapper />} />
                      </Route>

                      {/* Protected routes for admin only */}
                      <Route
                        element={
                          <ProtectedRoute
                            roles={['admin']}
                            fallback={DEFAULT_HOME_LOCATION}
                          />
                        }
                      >
                        <Route path="/categories" element={<Categories />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/settings" element={<Settings />} />
                      </Route>
                    </Routes>
                  </MainContentContainer>
                </GridLayoutContainer>
              </>
            ) : (
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/*" element={<Navigate to={'/login'} />} />
              </Routes>
            )}
          </Router>
        </PreviewProvider>
      </ApolloProvider>
    </MainContainer>
  );
}

export default App;

const MainContainer = styled.div`
  max-height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
`;

const GridLayoutContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-areas:
    'header header header'
    'sidebar main main';
  grid-template-rows: 40px 1fr;
  grid-template-columns: 1fr 5fr;
`;

const HeaderContainer = styled.div`
  grid-area: header;
`;

const SidebarContainer = styled.div`
  background: ${colors.beige.primary};
  grid-area: sidebar;
`;

const MainContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;

  overflow-y: auto;
  grid-area: main;
`;

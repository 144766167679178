import { useParams } from 'react-router-dom';
import { QuizProvider } from '../context/QuizContext';
import { QuizPage } from './QuizPage';

type QuizParams = {
  id: string;
};

export const QuizPageWrapper = () => {
  const { id: quizId } = useParams<QuizParams>();

  return (
    <QuizProvider quizId={quizId}>
      <QuizPage />
    </QuizProvider>
  );
};

import { useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import styled from 'styled-components';
import colors from '../../../styles/colors.styles';
import fonts from '../../../styles/fonts.styles';

type ColorObject = {
  color: string;
  setColor: (color: string) => void;
  label: string;
};

type EditableCTAProps = {
  colors: ColorObject[];
  title: string;
};

export const EditableCTA = ({ colors, title }: EditableCTAProps) => {
  const [colorPickerFocus, setColorPickerFocused] = useState(0);

  return (
    <Container>
      <SectionSubtitle>{title}</SectionSubtitle>
      <Content>
        <HexColorPicker
          color={colors[colorPickerFocus].color}
          onChange={(newColor) => colors[colorPickerFocus].setColor(newColor)}
          style={{
            flex: 1,
          }}
        />
        <ButtonsContainer>
          {colors.map((colorObject, index) => (
            <Button
              isFocused={colorPickerFocus === index}
              onClick={() => setColorPickerFocused(index)}
              key={colorObject.label}
            >
              <ButtonLabel>{colorObject.label}</ButtonLabel>
              <Input
                color={colorObject.color}
                onChange={colorObject.setColor}
                placeholder="Type a color"
                prefixed
                onFocus={() => setColorPickerFocused(index)}
              />
            </Button>
          ))}
        </ButtonsContainer>
      </Content>
    </Container>
  );
};

const SectionSubtitle = styled.p`
  font-family: ${fonts.WorkSans.semiBold};
  font-size: 12px;
  color: ${colors.blue.primary};
  padding: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
`;

const Button = styled.button<{
  isFocused?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-width: 1px;
  border-radius: 7px;
  opacity: ${(props) => (props.isFocused ? 0.6 : 1)};
  background-color: ${colors.blue.quaternary};
`;

const ButtonLabel = styled.p`
  color: ${colors.white.primary};
  font-family: ${fonts.WorkSans.regular};
  font-size: 13px;
  font-weight: bold;
  word-wrap: break-word;
  margin: 5px 0;
`;

const Input = styled(HexColorInput)`
  border-width: 1px;
  border-radius: 7px;
  width: 40%;
  background-color: ${colors.beige.primary};
  margin-left: 5px;
  font-family: ${fonts.WorkSans.regular};
  color: ${colors.blue.primary};
  font-size: 13px;
  outline: none;
`;

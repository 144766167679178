import { format } from 'date-fns';

export const utcToLocalDate = (
    date: string | Date | undefined,
    hideTime: boolean = false
) => {
    if (!date) return '';

    const dateObject = new Date(date);

    // Format the date based on whether time should be hidden or not
    return format(dateObject, hideTime ? 'yyyy-MM-dd' : "yyyy-MM-dd'T'HH:mm");
};

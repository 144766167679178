import axios from "axios";

const ROUTE_ROOT = "marketplace";

export async function getMarketplaceConfig(): Promise<any> {
	const response = await axios.get(`${ROUTE_ROOT}`);
	return response.data.data;
}

export async function updateMarketplaceConfig(
	jsonData: any
): Promise<any> {
	const response = await axios.post(`${ROUTE_ROOT}`, {
		jsonData
	});
	return response.data.data;
}

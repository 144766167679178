import React from 'react';
import styled from 'styled-components';

import CrossSVG from '../../../assets/icons/cross.svg';
import colors from '../../../styles/colors.styles';
import fonts from '../../../styles/fonts.styles';
import SVGButton from '../../forms/buttons/SVGButton';
import { PreviewActions } from './PreviewActions';

interface PopUpAppPreviewProps {
  previewTitle: React.ReactNode;
  imageLink: string;
  title: string;
  body: string;
  ctaText: string;
  ctaBackgroundColor: string;
  ctaTextColor: string;
  titleColor: string;
  bodyColor: string;
  isActive: boolean;
  onReset: () => void;
  onToggleActive: () => void;
}

export const PopUpAppPreview: React.FC<PopUpAppPreviewProps> = ({
  previewTitle,
  imageLink,
  title,
  body,
  ctaText,
  ctaBackgroundColor,
  ctaTextColor,
  titleColor,
  bodyColor,
  isActive,
  onReset,
  onToggleActive,
}) => {
  return (
    <BackgroundContainer>
      {previewTitle}
      {imageLink.length > 0 ||
      title.length > 0 ||
      body.length > 0 ||
      ctaText.length > 0 ? (
        <PopUpContainer>
          {imageLink.length > 0 ? (
            <ImageContainer>
              <StyledImage src={imageLink} alt="pop up" />
              <SVGButton
                icon={CrossSVG}
                width={11}
                height={11}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  marginTop: '10px',
                  marginRight: '10px',
                }}
              />
            </ImageContainer>
          ) : undefined}
          {title.length > 0 && body.length > 0 ? (
            <TextContainer>
              <Title color={titleColor}>{title}</Title>
              <Body color={bodyColor}>{body}</Body>
            </TextContainer>
          ) : undefined}
          {ctaText.length > 0 ? (
            <StyledButton
              backgroundColor={ctaBackgroundColor}
              color={ctaTextColor}
            >
              {ctaText}
            </StyledButton>
          ) : undefined}
        </PopUpContainer>
      ) : undefined}
      <PreviewActions
        imageLink={imageLink}
        ctaText={ctaText}
        onReset={onReset}
        onToggleActive={onToggleActive}
        isActive={isActive}
      />
    </BackgroundContainer>
  );
};

const BackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex: 1;
  align-self: stretch;
  background-color: white;
`;

const PopUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  background-color: ${colors.beige.primary};
  border-radius: 23px;
  width: 368px;
  padding: 7px;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const StyledImage = styled.img`
  border-radius: 16px 16px 0 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  margin: 0 25px;
`;

const Title = styled.p<{
  color: string;
}>`
  font-family: ${fonts.WorkSans.semiBold};
  font-size: 15px;
  line-height: 18px;
  color: ${(props) => props.color};
`;

const Body = styled.p<{
  color: string;
}>`
  font-family: ${fonts.WorkSans.regular};
  color: ${colors.blue.primary};
  font-size: 12px;
  line-height: 14px;
  color: ${(props) => props.color};
`;

const StyledButton = styled.button<{
  backgroundColor: string;
  color: string;
}>`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  font-weight: bold;
  border-radius: 10px;
  margin: 0 21px 25px;
  padding: 10px 0;
`;

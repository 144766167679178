import React from "react";
import styled from "styled-components";
import Button from "../components/forms/buttons/Button";
import Input from "../components/forms/inputs/Input";
import colors from "../styles/colors.styles";
import { Formik, FormikHelpers } from "formik";
import useAuth from "../contexts/AuthContext";

export interface LoginFormValues {
	email: string;
	password: string;
}

interface LoginFormErrors {
	email?: string;
	password?: string;
}

const Login: React.FC = () => {
	const { login, error } = useAuth();
	return (
		<Container>
			<TheBraderyLogo
				src="/assets/images/theBraderyLogo.svg"
				alt="The Bradery's logo"
				style={{ marginBottom: 50 }}
			/>

			<Formik
				initialValues={{ email: "", password: "" }}
				validate={(values: LoginFormValues) => {
					const errors: LoginFormErrors = {};

					if (!values.email) {
						errors.email = "Requis";
					} else if (
						!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
					) {
						errors.email = "Email adresse non-valide";
					}

					if (!values.password) {
						errors.password = "Requis";
					}

					return errors;
				}}
				onSubmit={(
					values: LoginFormValues,
					{ setSubmitting, setErrors }: FormikHelpers<LoginFormValues>
				) => {
					setErrors({});
					login(values, setSubmitting);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					/* and other goodies */
				}) => (
					<form onSubmit={handleSubmit}>
						<FormContainer>
							<Input
								label={"Email"}
								type="email"
								name="email"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.email}
							/>

							{errors.email && touched.email && (
								<ErrorContainer>{errors.email}</ErrorContainer>
							)}

							<Input
								label={"Mot de passe"}
								type="password"
								name="password"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.password}
							/>

							{errors.password && touched.password && (
								<ErrorContainer>{errors.password}</ErrorContainer>
							)}

							{error && error.response && error.response.status === 401 && (
								<ErrorContainer>
									Les identifiants entrés ne sont pas correctes
								</ErrorContainer>
							)}

							<Button
								text="Connexion"
								buttonType="primary"
								type="submit"
								disabled={isSubmitting}
							/>
						</FormContainer>
					</form>
				)}
			</Formik>
		</Container>
	);
};

export default Login;

const FormContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 11px;
	width: 400px;
`;

const Container = styled.div`
	max-height: 100vh;
	max-height: -webkit-fill-available;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;

	justify-content: center;
	align-items: center;

	background-color: ${colors.beige.primary};
`;

const ErrorContainer = styled.div`
	color: ${colors.orange.primary};
`;

const TheBraderyLogo = styled.img`
	height: 100px;
	width: auto;
`;

import React from 'react';
import styled from 'styled-components';
import usePreview from '../../../../contexts/PreviewContext';
import colors from '../../../../styles/colors.styles';
import fonts from '../../../../styles/fonts.styles';
import ButtonDataType from '../../buttons/ButtonDataType';
import { ItemType } from '../../../../interfaces/items';

interface DataTypeProps {
    categories: ItemType[] | null;
    selectedItem: number;
    selectedTab: number;
}

const DataType: React.FC<DataTypeProps> = ({
    categories,
    selectedItem,
    selectedTab,
}) => {
    const preview = usePreview();

    if (categories === null) {
        return null;
    }

    const handleChange = (event: any) => {
        preview.resetCurrentFormButKeepImages(selectedItem, selectedTab);
        preview.setPreviewItemTabField(
            selectedItem,
            selectedTab,
            'datatype',
            event.target.value
        );
    };

    return (
        <Container>
            <Title>URL ?</Title>
            <ButtonsContainer>
                {categories.map((category, index) => (
                    <ButtonDataType
                        text={category}
                        name="datatype"
                        isSelected={
                            /*previewItems[selectedItem].content.items &&*/
                            preview.getDataType(selectedItem, selectedTab) ===
                            category
                        }
                        onClick={handleChange}
                        key={index}
                    />
                ))}
            </ButtonsContainer>
        </Container>
    );
};

export default DataType;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;

const Title = styled.div`
    margin: 15px 0 15px 0;
    font-family: ${fonts.WorkSans.semiBold};
    font-size: 12px;
    color: ${colors.blue.primary};
`;

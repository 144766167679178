import React, { PropsWithChildren } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { HomeItem } from '../../interfaces/shop';
import colors from '../../styles/colors.styles';
import { grid } from '../../utils/droppable';
import Carousel from '../preview/Carousel';
import FullWidth from '../preview/FullWidth';
import TextBanner from '../preview/TextBanner';
import { DroppablePreviewBlocs, ScreenName } from '../../interfaces/droppable';

interface DragableElementProps {
  screenName: ScreenName;
  data: DroppablePreviewBlocs;
  index: number;
  onClick: (item: DroppablePreviewBlocs, number: number) => void;
  editing: boolean;
  isDragDisabled?: boolean;
}

const DragableElement = ({
  screenName,
  data,
  index,
  onClick,
  editing,
  isDragDisabled = false,
}: PropsWithChildren<DragableElementProps>) => {
  const renderBloc = (item: HomeItem | any) => {
    if (screenName === 'onGoingSales') {
      return onGoingSalesBloc(item);
    } else if (screenName === 'upComingSales') {
      return upComingSales(item);
    }
  };

  const onGoingSalesBloc = (item: HomeItem) => {
    const isCarousel = item?.blockType === 'carousel';
    const isTextBanner = item?.blockType === 'textBanner';
    const isFullWidth = item?.blockType === 'normal';

    if (isCarousel) {
      return <Carousel data={item} isEditing={editing} />;
    }
    if (isTextBanner) {
      return <TextBanner data={item} isEditing={editing} />;
    }
    if (isFullWidth) {
      return <FullWidth data={item} isEditing={editing} />;
    }
    return null;
  };

  const upComingSales = (item: any) => {
    return <FullWidth data={item} isEditing={editing} />;
  };

  return (
    <Draggable
      key={data.id}
      draggableId={data.id.toString()}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided: any, snapshot: any) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
            grid,
            editing
          )}
          onClick={() => onClick(data, index)}
        >
          {renderBloc(data.content)}
        </div>
      )}
    </Draggable>
  );
};

export default DragableElement;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: CSSStyleRule,
  grid: number,
  editing: boolean
) => ({
  userSelect: 'none',
  padding: 0,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? colors.blue.primary : null,
  height: '260px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: editing ? `0.5` : `1`,
  ...draggableStyle,
});

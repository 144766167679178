import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    calculateDaysRemaining,
    calculateHoursRemaining,
    calculateMinutesRemaining,
} from '../../helpers/time';
import { HomeItem } from '../../interfaces/shop';
import colors from '../../styles/colors.styles';
import fonts from '../../styles/fonts.styles';
import BellOutlinedIcon from '../../assets/icons/bellOutlined.svg';
import ImageOrVideo from '../miscellaneous/ImageOrVideo';

interface CarouselProps {
    data: HomeItem;
    isEditing: boolean;
}

const FullWidth: React.FC<CarouselProps> = ({ data, isEditing }) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dateStop = data?.items[0]?.dateStop
        ? new Date(data.items[0].dateStop)
        : new Date();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dateStart = data?.items[0]?.dateStart
        ? new Date(data.items[0].dateStart)
        : new Date();
    const [daysRemaining, setDaysRemaining] = useState(
        calculateDaysRemaining(dateStop)
    );
    const [hoursRemaining, setHoursRemaining] = useState(
        calculateHoursRemaining(dateStop)
    );
    const [minutesRemaining, setMinutesRemaining] = useState(
        calculateMinutesRemaining(dateStop)
    );

    const [_, setDaysRemainingBeforeTheSaleBegins] = useState(
        calculateDaysRemaining(dateStop)
    );

    useEffect(() => {
        setDaysRemaining(calculateDaysRemaining(dateStop));
        setHoursRemaining(calculateHoursRemaining(dateStop));
        setMinutesRemaining(calculateMinutesRemaining(dateStop));
        setDaysRemainingBeforeTheSaleBegins(calculateDaysRemaining(dateStart));
    }, [data, dateStop, dateStart]);

    const renderTimeRemaining = () => {
        if (minutesRemaining < 0) {
            return 'La vente est terminée';
        }
        if (daysRemaining > 1) {
            return `Se termine dans ${daysRemaining} jours`;
        }
        if (hoursRemaining > 1) {
            return `Se termine dans ${hoursRemaining} heures`;
        }
        return `Se termine dans ${minutesRemaining} minutes`;
    };

    const renderTimeRemainingBeforeTheSaleBegins = () => {
        const daysRemaining = calculateDaysRemaining(dateStart);
        if (Object.is(daysRemaining, +0)) {
            return 'La vente débute bientôt';
        }
        if (daysRemaining === 1) {
            return 'La vente débute demain';
        }
        if (daysRemaining > 1) {
            return `La vente débute dans ${calculateDaysRemaining(dateStart)} jours`;
        }
        return `La vente a déjà commencé`;
    };

    const isItemExist = data?.items && data.items.length > 0;

    const imageIsSet =
        isItemExist &&
        data.items[0].image &&
        !!data.items[0].image &&
        !!data.items[0].image?.defaultUrl;

    const imageDefaultUrl = imageIsSet ? data.items[0].image?.defaultUrl : '';

    return (
        <Container>
            <ImageOrVideo
                url={imageDefaultUrl || ''}
                style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                }}
            />
            {isItemExist && (
                <TopContainer>
                    <Text>
                        {data.blockType === 'upComingSale'
                            ? renderTimeRemainingBeforeTheSaleBegins()
                            : renderTimeRemaining()}
                    </Text>
                    {data.blockType === 'normal' &&
                        data.items[0].discountAllAt && (
                            <TextBold>{`Tout à -${data.items[0].discountAllAt} %`}</TextBold>
                        )}
                    {data.blockType === 'normal' &&
                        data.items[0].discountUpTo && (
                            <TextBold>{`Jusqu'à -${data.items[0].discountUpTo} %`}</TextBold>
                        )}
                </TopContainer>
            )}
            {data.blockType === 'upComingSale' && (
                <IconBellWrapper>
                    <IconBell src={BellOutlinedIcon} alt="" />
                </IconBellWrapper>
            )}
        </Container>
    );
};

export default FullWidth;

const Container = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
`;

const TopContainer = styled.div`
    position: absolute;
    box-sizing: border-box;
    padding: 10px;
    top: 0;
    width: 100%;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.46262254901960786) 57%,
        rgba(255, 255, 255, 0) 100%
    );
`;

const Text = styled.div`
    font-family: ${fonts.WorkSans.regular};
    font-size: 11px;
    color: ${colors.mono.white};
`;

const TextBold = styled.div`
    font-family: ${fonts.WorkSans.semiBold};
    font-size: 11px;
    color: ${colors.mono.white};
`;

const IconBellWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.beige.primary};
    bottom: 15px;
    right: 15px;
    height: 38px;
    width: 38px;
    border-radius: 8px;
`;

const IconBell = styled.img`
    object-fit: contain;
    height: 22px;
    width: 22px;
`;

import { useQuizContext } from '../../context/QuizContext';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../../../../shadcn/components/ui/card';
import { Button } from '../../../../shadcn/components/ui/button';
import File from '../../../../components/forms/inputs/File';
import { useState } from 'react';
import { uploadFile } from '../../../../services/api/api';
import { Textarea } from '../../../../shadcn/components/ui/textarea';
import { Switch } from '../../../../shadcn/components/ui/switch';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../shadcn/components/ui/form';
import { Input } from '../../../../shadcn/components/ui/input';
import { DatePicker } from '../../../../components/forms/inputs/DatePicker';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putQuizGeneralInformation } from '../../../../services/api/quiz/quiz';
import { useToast } from '../../../../shadcn/components/ui/use-toast';
import { isISODateString } from '../../../../utils/typguards/isISODateString';
import { ColorPicker } from '../../../../components/forms/inputs/ColorPicker';
import { Trash2 } from 'lucide-react';

const formSchema = z.object({
  isActive: z.boolean(),
  title: z.string().min(2).max(50),
  startDate: z.date(),
  endDate: z.date(),
  imageUrl: z.string().url(),
  logoUrl: z.string().url(),
  imageTitle: z.string(),
  imageTitleColor: z.string(),
  imageSubTitle: z.string(),
  imageSubTitleColor: z.string(),
  leftCornerLogoUrl: z.string().nullable(),
});

export const QuizGeneralInformation = () => {
  const { quiz } = useQuizContext();

  const { toast } = useToast();
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      title: quiz?.title,
      endDate: quiz?.endDate,
      isActive: quiz?.isActive,
      startDate: quiz?.startDate,
      imageSubTitle: quiz?.image.htmlSubTitle,
      imageTitle: quiz?.image.htmlTitle,
      imageSubTitleColor: quiz?.image.htmlSubTitleColor,
      imageTitleColor: quiz?.image.htmlTitleColor,
      imageUrl: quiz?.image.imageUrl,
      logoUrl: quiz?.image.logoUrl,
      leftCornerLogoUrl: quiz?.image.leftCornerLogoUrl,
    },
  });

  const [imageUploaded, setImageUploaded] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);

  const [logoUploaded, setLogoUploaded] = useState(false);
  const [logoUploading, setLogoUploading] = useState(false);

  const [cornerLogoUploaded, setCornerLogoUploaded] = useState(false);
  const [cornerLogoUploading, setCornerLogoUploading] = useState(false);

  const updateQuizGeneralInformationMutation = useMutation({
    mutationFn: putQuizGeneralInformation,
    onSuccess: async (data) => {
      if (data.success) {
        toast({
          title: 'Changements enregistré !',
          description: 'Les changements ont bien été enregistré.',
        });

        queryClient.invalidateQueries({ queryKey: ['quiz', quiz?.id] });

        form.reset({
          isActive: data.quiz.isActive,
          imageSubTitle: data.quiz.quizImage?.htmlSubTitle,
          imageTitle: data.quiz.quizImage?.htmlTitle,
          imageUrl: data.quiz.quizImage?.imageUrl,
          logoUrl: data.quiz.quizImage?.logoUrl,
          leftCornerLogoUrl: data.quiz.quizImage?.leftCornerLogoUrl,
          title: data.quiz.title,
          startDate: isISODateString(data.quiz.startDate)
            ? new Date(data.quiz.startDate)
            : new Date(),
          endDate: isISODateString(data.quiz.endDate)
            ? new Date(data.quiz.endDate)
            : new Date(),
          imageSubTitleColor: data.quiz.quizImage?.htmlSubTitleColor,
          imageTitleColor: data.quiz.quizImage?.htmlTitleColor,
        });
      } else {
        toast({
          variant: 'destructive',
          title: "Oops ! Une erreur s'est produite.",
          description:
            "L'enregistrement de vos changements n'ont pas été fait. Veuillez-réessayer.",
        });
      }
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (quiz?.id) {
      updateQuizGeneralInformationMutation.mutate({
        id: quiz?.id,
        ...values,
      });
    }
  };

  return (
    <Card className="bg-beigePrimary">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CardHeader className="align-center flex-row justify-between">
            <CardTitle className="text-bluePrimary">
              Informations générales
            </CardTitle>
            <FormField
              control={form.control}
              name="isActive"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-2">
                  <FormControl>
                    <Switch
                      id="isActivated"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>
                    {quiz?.isActive ? 'Activé' : 'Désactivé'}
                  </FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardHeader>
          <CardContent>
            <div className="mb-8 grid auto-rows-auto grid-cols-8 gap-x-4">
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem className="col-span-4 flex flex-col space-y-1.5">
                    <FormLabel>Nom du quiz</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2" />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="startDate"
                render={({ field }) => (
                  <FormItem className="col-span-2 flex flex-col space-y-1.5">
                    <FormLabel>Début</FormLabel>
                    <FormControl>
                      <DatePicker
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        disabled={field.disabled}
                        name={field.name}
                        ref={field.ref}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="endDate"
                render={({ field }) => (
                  <FormItem className="col-span-2 flex flex-col space-y-1.5">
                    <FormLabel>Fin</FormLabel>
                    <FormControl>
                      <DatePicker
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        disabled={field.disabled}
                        name={field.name}
                        ref={field.ref}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="grid auto-rows-auto grid-cols-3 gap-x-4 gap-y-6">
              <h4 className="col-span-full text-xl font-light leading-none tracking-tight text-bluePrimary">
                Header
              </h4>

              <div className="flex justify-center">
                <FormField
                  control={form.control}
                  name="imageUrl"
                  render={({ field }) => (
                    <FormItem className="flex flex-col items-center space-y-1.5">
                      <FormLabel>Image du quiz</FormLabel>
                      <FormControl>
                        <File
                          uploaded={imageUploaded}
                          uploading={imageUploading}
                          src={field.value}
                          onChange={async (evt) => {
                            setImageUploading(true);
                            const file = evt.target.files?.length
                              ? evt.target.files[0]
                              : null;
                            if (!file) return;

                            const path = await uploadFile(file);

                            field.onChange(path);

                            evt.target.value = '';
                            setImageUploading(false);
                            setImageUploaded(true);
                          }}
                          name={field.name}
                          onBlur={field.onBlur}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex justify-center">
                <FormField
                  control={form.control}
                  name="logoUrl"
                  render={({ field }) => (
                    <FormItem className="flex flex-col items-center space-y-1.5">
                      <FormLabel>Logo sur l'image</FormLabel>
                      <FormControl>
                        <File
                          uploaded={logoUploaded}
                          uploading={logoUploading}
                          src={field.value}
                          onChange={async (evt) => {
                            setLogoUploading(true);
                            const file = evt.target.files?.length
                              ? evt.target.files[0]
                              : null;
                            if (!file) return;

                            const path = await uploadFile(file);

                            field.onChange(path);

                            evt.target.value = '';
                            setLogoUploading(false);
                            setLogoUploaded(true);
                          }}
                          name={field.name}
                          onBlur={field.onBlur}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex justify-center">
                <FormField
                  control={form.control}
                  name="leftCornerLogoUrl"
                  render={({ field }) => (
                    <FormItem className="flex flex-col items-center space-y-1.5">
                      <FormLabel>Logo coin gauche</FormLabel>
                      <FormControl>
                        <File
                          uploaded={cornerLogoUploaded}
                          uploading={cornerLogoUploading}
                          src={field.value ?? undefined}
                          onChange={async (evt) => {
                            setCornerLogoUploading(true);
                            const file = evt.target.files?.length
                              ? evt.target.files[0]
                              : null;
                            if (!file) return;

                            const path = await uploadFile(file);

                            field.onChange(path);

                            evt.target.value = '';
                            setCornerLogoUploading(false);
                            setCornerLogoUploaded(true);
                          }}
                          name={field.name}
                          onBlur={field.onBlur}
                        />
                      </FormControl>
                      <FormMessage />
                      {field.value && (
                        <Button
                          variant="outline"
                          type="button"
                          size="sm"
                          onClick={() => field.onChange(null)}
                        >
                          <Trash2 />
                        </Button>
                      )}
                    </FormItem>
                  )}
                />
              </div>

              <div className="col-span-full space-y-4">
                <FormField
                  control={form.control}
                  name="imageTitle"
                  render={({ field }) => (
                    <FormItem className="space-y-1.5">
                      <FormLabel>Titre sur l'image</FormLabel>
                      <FormControl>
                        <Textarea {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="imageTitleColor"
                  render={({ field }) => (
                    <FormItem className="flex space-x-6 space-y-0">
                      <FormLabel className="flex items-center justify-start">
                        Couleur
                      </FormLabel>
                      <FormControl className="flex items-center justify-start">
                        <ColorPicker {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="col-span-full space-y-4">
                <FormField
                  control={form.control}
                  name="imageSubTitle"
                  render={({ field }) => (
                    <FormItem className="col-span-full space-y-1.5">
                      <FormLabel>Sous-titre sur l'image</FormLabel>
                      <FormControl>
                        <Textarea {...field} />
                      </FormControl>
                      <FormMessage className="col-span-full" />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="imageSubTitleColor"
                  render={({ field }) => (
                    <FormItem className="flex space-x-6 space-y-0">
                      <FormLabel className="flex items-center justify-start">
                        Couleur
                      </FormLabel>
                      <FormControl className="flex items-center justify-start">
                        <ColorPicker {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </CardContent>
          <CardFooter className={`flex justify-end space-x-4`}>
            {form.formState.isDirty && (
              <>
                {!updateQuizGeneralInformationMutation.isPending && (
                  <Button
                    variant="outline"
                    type="button"
                    onClick={() => form.reset()}
                  >
                    Réinitialiser
                  </Button>
                )}
                <Button
                  type="submit"
                  isLoading={updateQuizGeneralInformationMutation.isPending}
                >
                  Enregistrer
                </Button>
              </>
            )}
          </CardFooter>
        </form>
      </Form>
    </Card>
  );
};

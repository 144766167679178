import React, { InputHTMLAttributes } from "react"
import styled from "styled-components"
import colors from "../../../styles/colors.styles"
import fonts from "../../../styles/fonts.styles"

interface ButtonDataTypeProps extends InputHTMLAttributes<HTMLInputElement> {
  text: string
  isSelected: boolean
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined
}

const ButtonDataType: React.FC<ButtonDataTypeProps> = ({
  isSelected,
  text,
  onClick,
  ...props
}) => {
  return (
    <>
      <Input
        type="button"
        value={text}
        onClick={onClick}
        isSelected={isSelected}
        {...props}
      />
    </>
  )
}

export default ButtonDataType

const Input = styled.input<{ isSelected: boolean }>`
  width: 70px;
  height: 59px;
  flex-basis: 25%;
  border-radius: 4px;
  margin: 14px;
  background-color: ${colors.beige.tertiary};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  font-family: ${(p: any) =>
    p.isSelected ? fonts.WorkSans.medium : fonts.WorkSans.regular};
  color: ${(p: any) =>
    p.isSelected ? colors.blue.secondary : colors.blue.primary};
  border: 2px solid
    ${(p: any) =>
      p.isSelected ? colors.blue.secondary : colors.beige.tertiary} !important;
`

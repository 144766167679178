import React from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors.styles';
import { getQuizEndDate, getQuizStartDate } from '../../helpers/quiz';

interface QuizItemProps {
  quiz: Quiz;
}

const QuizItemDeprecated = ({ quiz }: QuizItemProps) => {
  return (
    <QuizCard href={`/quiz/${quiz.id}`}>
      <Column>{quiz.title}</Column>
      <Column>
        Du {getQuizStartDate(quiz.questions).toLocaleDateString('FR')} au{' '}
        {getQuizEndDate(quiz.questions).toLocaleDateString('FR')}
      </Column>
      <Column style={{ borderRight: 'none' }}>
        {quiz.questions.length} questions
      </Column>
    </QuizCard>
  );
};

const QuizCard = styled.a`
  display: flex;
  flex-direction: row;
  border: 1px solid ${colors.blue.primary};
  border-radius: 4px;
  background-color: ${colors.beige.primary};
  margin-bottom: 5px;
  width: 100%;
  padding: 0;
  cursor: pointer;
  :hover {
    background-color: ${colors.beige.tertiary};
    text-decoration: none;
  }
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-right: 1px solid ${colors.blue.primary};
  padding: 1em 1.5em;
  color: ${colors.blue.primary};
  font-family: ${colors.blue.primary};
  font-size: 16px;
`;

export default QuizItemDeprecated;

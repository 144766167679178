import { z } from 'zod';

export const generateQuestionZodSchema = (
  setGeneralErrorMessage: React.Dispatch<React.SetStateAction<string>>
) => {
  return z
    .object({
      question: z.string().min(1),
      imageUrl: z.string().url().optional().nullable(),
      logoUrl: z.string().url().optional().nullable(),
      leftCornerLogoUrl: z.string().optional().nullable(),
      choices: z.array(
        z.object({
          text: z.string().min(1),
          isCorrectAnswer: z.boolean(),
          index: z.number(),
          id: z.string().uuid().optional(),
        })
      ),
    })
    .refine(
      (data) => {
        const answers = data.choices.filter((choice) => choice.isCorrectAnswer);
        const hasExactlyOneAnswer = answers.length === 1;

        if (!hasExactlyOneAnswer) {
          setGeneralErrorMessage("Il ne peut y avoir qu'une seule réponse");
        } else {
          setGeneralErrorMessage('');
        }

        return hasExactlyOneAnswer;
      },
      {
        message: 'There must be exactly one correct answer',
        path: ['choices'],
      }
    );
};

import {
  differenceInCalendarDays,
  isAfter,
  isBefore,
  isWithinInterval,
} from 'date-fns';

const now = new Date();

type GetQuizStatusReturn = {
  badgeVariant: 'destructive' | 'secondary' | 'outline' | 'success' | 'default';
  statusText: string;
};

export const getQuizStatus = (
  isActive: boolean,
  startDate: Date,
  endDate: Date
): GetQuizStatusReturn => {
  if (!isActive) {
    return {
      statusText: `désactivé`,
      badgeVariant: 'destructive',
    };
  }

  if (isBefore(now, startDate)) {
    const days = differenceInCalendarDays(startDate, now);
    return {
      statusText: `dans ${days} jour${days > 1 ? 's' : ''}`,
      badgeVariant: 'default',
    };
  } else if (isAfter(now, endDate)) {
    return {
      statusText: `fini`,
      badgeVariant: 'outline',
    };
  } else if (isWithinInterval(now, { start: startDate, end: endDate })) {
    return {
      statusText: `en cours`,
      badgeVariant: 'success',
    };
  }

  return {
    statusText: `erreur`,
    badgeVariant: 'destructive',
  };
};

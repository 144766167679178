import { Plus, Trash2 } from 'lucide-react';
import { Button } from '../../../../shadcn/components/ui/button';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../shadcn/components/ui/form';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../../shadcn/components/ui/table';
import { Textarea } from '../../../../shadcn/components/ui/textarea';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { QuizQuestionChoiceListRow } from './QuizQuestionChoiceListRow';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { QuizQuestionFormField } from '../../types';
import { useState } from 'react';
import { uploadFile } from '../../../../services/api/api';
import File from '../../../../components/forms/inputs/File';

type QuizQuestionFormProps = {
  form: UseFormReturn<QuizQuestionFormField, any, undefined>;
  choicesFieldArray: UseFieldArrayReturn<
    QuizQuestionFormField,
    'choices',
    'key'
  >;
};

export const QuizQuestionForm = ({
  choicesFieldArray,
  form,
}: QuizQuestionFormProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [logoUploaded, setLogoUploaded] = useState(false);
  const [logoUploading, setLogoUploading] = useState(false);

  const [imageUploaded, setImageUploaded] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);

  const [cornerLogoUploaded, setCornerLogoUploaded] = useState(false);
  const [cornerLogoUploading, setCornerLogoUploading] = useState(false);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = choicesFieldArray.fields.findIndex(
        (choice) => choice.key === active.id
      );
      const newIndex = choicesFieldArray.fields.findIndex(
        (choice) => choice.key === over.id
      );

      choicesFieldArray.move(oldIndex, newIndex);

      const newChoicesArray = arrayMove(
        choicesFieldArray.fields,
        oldIndex,
        newIndex
      ).map((item, index) => ({
        ...item,
        index: index,
      }));

      form.setValue('choices', newChoicesArray);
    }
  };

  return (
    <div className="mt-2 space-y-7">
      <FormField
        control={form.control}
        name="question"
        render={({ field }) => (
          <FormItem className="px-1">
            <FormLabel>Question</FormLabel>
            <FormControl>
              <Textarea {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <div className="flex flex-row justify-around">
        <FormField
          control={form.control}
          name="logoUrl"
          render={({ field }) => (
            <FormItem className="flex flex-col items-center space-y-1.5">
              <FormLabel>Logo de la question</FormLabel>
              <FormControl>
                <File
                  uploaded={logoUploaded}
                  uploading={logoUploading}
                  src={field.value ?? undefined}
                  onChange={async (evt) => {
                    setLogoUploading(true);
                    const file = evt.target.files?.length
                      ? evt.target.files[0]
                      : null;
                    if (!file) return;

                    const path = await uploadFile(file);

                    field.onChange(path);

                    evt.target.value = '';
                    setLogoUploading(false);
                    setLogoUploaded(true);
                  }}
                  name={field.name}
                  onBlur={field.onBlur}
                />
              </FormControl>
              <FormMessage />
              {field.value && (
                <Button
                  variant="outline"
                  type="button"
                  size="sm"
                  onClick={() => field.onChange(null)}
                >
                  <Trash2 />
                </Button>
              )}
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="imageUrl"
          render={({ field }) => (
            <FormItem className="flex flex-col items-center space-y-1.5">
              <FormLabel>Image de la question</FormLabel>
              <FormControl>
                <File
                  uploaded={imageUploaded}
                  uploading={imageUploading}
                  src={field.value ?? undefined}
                  onChange={async (evt) => {
                    setImageUploading(true);
                    const file = evt.target.files?.length
                      ? evt.target.files[0]
                      : null;
                    if (!file) return;

                    const path = await uploadFile(file);

                    field.onChange(path);

                    evt.target.value = '';
                    setImageUploading(false);
                    setImageUploaded(true);
                  }}
                  name={field.name}
                  onBlur={field.onBlur}
                />
              </FormControl>
              <FormMessage />
              {field.value && (
                <Button
                  variant="outline"
                  type="button"
                  size="sm"
                  onClick={() => field.onChange(null)}
                >
                  <Trash2 />
                </Button>
              )}
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="leftCornerLogoUrl"
          render={({ field }) => (
            <FormItem className="flex flex-col items-center space-y-1.5">
              <FormLabel>Logo coin gauche</FormLabel>
              <FormControl>
                <File
                  uploaded={cornerLogoUploaded}
                  uploading={cornerLogoUploading}
                  src={field.value ?? undefined}
                  onChange={async (evt) => {
                    setCornerLogoUploading(true);
                    const file = evt.target.files?.length
                      ? evt.target.files[0]
                      : null;
                    if (!file) return;

                    const path = await uploadFile(file);

                    field.onChange(path);

                    evt.target.value = '';
                    setCornerLogoUploading(false);
                    setCornerLogoUploaded(true);
                  }}
                  name={field.name}
                  onBlur={field.onBlur}
                />
              </FormControl>
              <FormMessage />
              {field.value && (
                <Button
                  variant="outline"
                  type="button"
                  size="sm"
                  onClick={() => field.onChange(null)}
                >
                  <Trash2 />
                </Button>
              )}
            </FormItem>
          )}
        />
      </div>

      <div>
        <div>Choix</div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[25px]"></TableHead>
              <TableHead>Text</TableHead>
              <TableHead className="w-[75px] text-center">Réponse</TableHead>
              <TableHead className="w-[25px] text-right" />
            </TableRow>
          </TableHeader>
          <TableBody>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={choicesFieldArray.fields.map(
                  (choiceField) => choiceField.key
                )}
                strategy={verticalListSortingStrategy}
              >
                {choicesFieldArray.fields.map((item, index) => (
                  <QuizQuestionChoiceListRow
                    key={item.id}
                    formControl={form.control}
                    index={index}
                    choice={item}
                    choicesFieldArray={choicesFieldArray}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={2}>
                <Button
                  className="w-full border border-input bg-bluePrimary/50 text-white/75 hover:bg-bluePrimary"
                  type="button"
                  onClick={() =>
                    choicesFieldArray.append({
                      text: '',
                      isCorrectAnswer: false,
                      index: choicesFieldArray.fields.length,
                    })
                  }
                >
                  <Plus />
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  );
};

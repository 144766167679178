import { QuizInListDTO } from '../../../services/api/quiz/types';
import { isISODateString } from '../../../utils/typguards/isISODateString';
import { QuizInList } from '../types';

export function parseQuizInListDTO(quizInListDTO: QuizInListDTO): QuizInList {
  const quizInList: QuizInList = {
    ...quizInListDTO,
    startDate: isISODateString(quizInListDTO.startDate)
      ? new Date(quizInListDTO.startDate)
      : new Date(),
    endDate: isISODateString(quizInListDTO.endDate)
      ? new Date(quizInListDTO.endDate)
      : new Date(),
  };
  return quizInList;
}

import axios, { AxiosResponse } from 'axios';
import {
  SETTINGS_ROUTE_APP_COLOR_SWATCH_COLORS,
  SETTINGS_ROUTE_APP_VERSION,
  SETTINGS_ROUTE_BASE,
  SETTINGS_ROUTE_ORDER_CONFIRMATION_AD,
  SETTINGS_ROUTE_POP_UP_APP,
} from './constants';
import { AppSettingsDto } from './dto/app-settings-dto';
import { AppSettingsOrderConfirmationMediaAdDto } from './dto/order-confirmation-media-dto';
import { AppSettings, AppSettingsMinimalVersion } from './types';
import { AppSettingsPopUpAppDTO } from './dto/pop-up-app-settings.dto';

// TODO once all users have the new app version, remove the 'v1/' prefix

export async function getAllSettings() {
  const response = await axios.get<AxiosResponse<AppSettings>>(
    'v1/' + SETTINGS_ROUTE_BASE
  );

  return response.data.data;
}

export async function getMinimalAppVersions() {
  const response = await axios.get<AxiosResponse<AppSettingsMinimalVersion>>(
    'v1/' + SETTINGS_ROUTE_APP_VERSION
  );

  return response.data.data;
}

export async function getColorSwatchColors() {
  const { data }: any = await axios.get<AxiosResponse<string>>(
    SETTINGS_ROUTE_APP_COLOR_SWATCH_COLORS
  );
  return JSON.stringify(data.data);
}

export async function updateAllSettings(data: AppSettingsDto) {
  return await axios.put(SETTINGS_ROUTE_BASE, data);
}

export async function updateOrderConfirmationAdSettings(
  data: AppSettingsOrderConfirmationMediaAdDto
) {
  return await axios.put(SETTINGS_ROUTE_ORDER_CONFIRMATION_AD, data);
}

export async function getOrderConfirmationMediaAdSettings() {
  const response = await axios.get<
    AxiosResponse<AppSettingsOrderConfirmationMediaAdDto>
  >(SETTINGS_ROUTE_ORDER_CONFIRMATION_AD);

  return response.data.data;
}

export async function updatePopUpAppSettings(data: AppSettingsPopUpAppDTO) {
  return await axios.put(SETTINGS_ROUTE_POP_UP_APP, data);
}

export async function getPopUpAppSettings() {
  const response = await axios.get<AxiosResponse<AppSettingsPopUpAppDTO>>(
    SETTINGS_ROUTE_POP_UP_APP
  );

  return response.data.data;
}

import { useQuizContext } from '../../context/QuizContext';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../../shadcn/components/ui/table';
import { QuizCreateQuestionDialog } from './QuizCreateQuestionDialog';
import { useEffect, useState } from 'react';
import { QuizQuestion } from '../../types';
import { QuizEditQuestionDialog } from './QuizEditQuestionDialog';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { QuizQuestionListRow } from './QuizQuestionListRow';
import { isQuestionListOrderDifferent } from '../utils/isQuestionListOrderDifferent';
import { Button } from '../../../../shadcn/components/ui/button';
import { orderQuestions } from '../utils/orderQuestions';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putQuizQuestionOrder } from '../../../../services/api/quiz/quiz';
import { useToast } from '../../../../shadcn/components/ui/use-toast';
import { QuizQuestionWinnerDialog } from './QuizQuestionWinnerDialog';

export const QuizQuestionList = () => {
  const { quiz } = useQuizContext();

  const { toast } = useToast();
  const queryClient = useQueryClient();

  const [isQuestionEditDialogOpen, setIsQuestionEditDialogOpen] =
    useState(false);
  const [isQuestionWinnerDialogOpen, setIsQuestionWinnerDialogOpen] =
    useState(false);
  const [localQuizQuestions, setLocalQuizQuestions] = useState<QuizQuestion[]>(
    []
  );

  const [selectedQuestion, setSelectedQuestion] = useState<QuizQuestion>();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (quiz?.questions) {
      setLocalQuizQuestions(orderQuestions(quiz.questions));
    }
  }, [quiz?.questions]);

  const handleDragEnd = (event: DragEndEvent) => {
    if (localQuizQuestions) {
      const { active, over } = event;

      let localQuestions = [...localQuizQuestions];

      if (active.id !== over?.id && over) {
        const oldIndex = localQuestions.findIndex(
          (question) => question.id === active.id
        );
        const newIndex = localQuestions.findIndex(
          (question) => question.id === over.id
        );

        localQuestions = arrayMove(localQuestions, oldIndex, newIndex);

        setLocalQuizQuestions(localQuestions);
      }
    }
  };

  const resetQuestionOrder = () => {
    if (quiz?.questions) {
      setLocalQuizQuestions(orderQuestions(quiz.questions));
    }
  };

  const quizQuestionOrderUpdateMutation = useMutation({
    mutationFn: putQuizQuestionOrder,
    onSuccess: async (data) => {
      if (data.success) {
        toast({
          title: "L'ordre des questions mis à jour !",
          description: "L'ordre des questions a bien été enregistré.",
        });

        queryClient.invalidateQueries({ queryKey: ['quiz', quiz?.id] });
      } else {
        toast({
          variant: 'destructive',
          title: "Oops ! Une erreur s'est produite.",
          description:
            "Le changement d'ordre de questions n'a pas été enregistrer. Veuillez-réessayer.",
        });
      }
    },
  });

  const isOrderDifferent = isQuestionListOrderDifferent(
    quiz?.questions ?? [],
    localQuizQuestions
  );

  const onSaveQuestionOrder = () => {
    if (quiz) {
      quizQuestionOrderUpdateMutation.mutate({
        quizId: quiz.id,
        order: localQuizQuestions.map((item, index) => ({
          index,
          id: item.id,
        })),
      });
    }
  };

  return (
    <div className="space-y-5">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <h4 className="text-xl font-light leading-none tracking-tight text-bluePrimary">
            Liste ({localQuizQuestions.length})
          </h4>

          {quiz && <QuizCreateQuestionDialog quiz={quiz} />}

          {quiz?.id && selectedQuestion && isQuestionEditDialogOpen && (
            <QuizEditQuestionDialog
              quizId={quiz.id}
              setIsQuestionEditDialogOpen={setIsQuestionEditDialogOpen}
              isQuestionEditDialogOpen={isQuestionEditDialogOpen}
              question={selectedQuestion}
            />
          )}

          {quiz?.id && selectedQuestion && isQuestionWinnerDialogOpen && (
            <QuizQuestionWinnerDialog
              quizId={quiz.id}
              setIsQuestionWinnerDialogOpen={setIsQuestionWinnerDialogOpen}
              isQuestionWinnerDialogOpen={isQuestionWinnerDialogOpen}
              question={selectedQuestion}
              updateSelectedQuestion={setSelectedQuestion}
            />
          )}
        </div>

        <div
          className={`space-x-2 ${isOrderDifferent ? 'visible' : 'invisible'}`}
        >
          {!quizQuestionOrderUpdateMutation.isPending && (
            <Button
              variant="outline"
              type="button"
              size="sm"
              onClick={resetQuestionOrder}
            >
              Réinitialiser
            </Button>
          )}

          <Button
            type="button"
            size="sm"
            onClick={onSaveQuestionOrder}
            isLoading={quizQuestionOrderUpdateMutation.isPending}
          >
            Enregistrer
          </Button>
        </div>
      </div>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[25px]"></TableHead>
            <TableHead className="w-[100px]">Numéro</TableHead>
            <TableHead>Question</TableHead>
            <TableHead className="w-[100px] text-right">Choix</TableHead>
            <TableHead className="w-[125px]"></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {localQuizQuestions && (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={localQuizQuestions}
                strategy={verticalListSortingStrategy}
              >
                {localQuizQuestions.map((question, index) => (
                  <QuizQuestionListRow
                    key={question.id}
                    question={question}
                    index={index}
                    setIsQuestionEditDialogOpen={setIsQuestionEditDialogOpen}
                    setIsQuestionWinnerDialogOpen={
                      setIsQuestionWinnerDialogOpen
                    }
                    setSelectedQuestion={setSelectedQuestion}
                  />
                ))}
              </SortableContext>
            </DndContext>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

import { Story } from '../../interfaces/shop';

export function isStory(story: any): story is Story {
    return (
        story &&
        story !== null &&
        typeof story === 'object' &&
        typeof story.id === 'number' &&
        typeof story.url === 'string'
    );
}

import styled from 'styled-components';
import colors from '../../../styles/colors.styles';
import fonts from '../../../styles/fonts.styles';
import { useQuizContext } from '../context/QuizContext';
import { QuizGeneralInformation } from './components/QuizGeneralInformation';
import { QuizQuestions } from '../QuizQuestion/components/QuizQuestions';
import { formatQuizDate } from '../utils/formatQuizDate';
import { Badge } from '../../../shadcn/components/ui/badge';
import { getQuizStatus } from '../utils/getQuizStatus';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '../../../shadcn/components/ui/tabs';
import { QuizResult } from '../QuizResultPage/components/QuizResult';

export const QuizPage = () => {
  const { loading, quiz } = useQuizContext();

  if (loading || !quiz) {
    return <div>Loading...</div>;
  }

  const quizStatus = getQuizStatus(quiz.isActive, quiz.startDate, quiz.endDate);

  return (
    <Container className="space-y-6">
      <Header>
        <PageTitle>{quiz?.title}</PageTitle>
        <QuizInfo>
          {`Du ${formatQuizDate(quiz?.startDate)} au ${formatQuizDate(quiz?.endDate)}`}
        </QuizInfo>
        <QuizInfo>
          <Badge variant={quizStatus?.badgeVariant}>
            {quizStatus?.statusText}
          </Badge>
        </QuizInfo>
      </Header>

      <Tabs defaultValue="general-information" className="w-full">
        <TabsList>
          <TabsTrigger value="general-information">
            Informations générales
          </TabsTrigger>
          <TabsTrigger value="questions">Questions</TabsTrigger>
          <TabsTrigger value="results-page">Page résultat</TabsTrigger>
        </TabsList>
        <TabsContent value="general-information">
          <QuizGeneralInformation />
        </TabsContent>
        <TabsContent value="questions">
          <QuizQuestions />
        </TabsContent>
        <TabsContent value="results-page">
          <QuizResult />
        </TabsContent>
      </Tabs>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 38px 0 38px;
  width: 100%;
`;

const Header = styled.div`
  margin: 2rem;
`;

const PageTitle = styled.h1`
  font-family: ${fonts.WorkSans.regular};
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  color: ${colors.blue.primary};
  margin-bottom: 10px;
`;

const QuizInfo = styled.div`
  font-family: ${fonts.WorkSans.regular};
  font-size: 14px;
  line-height: 18px;
  color: ${colors.blue.secondary};
  text-align: center;
  margin: 10px;
`;

import styled from "styled-components"
import TextArea from "../components/forms/inputs/TextArea"
import Button from '../components/forms/buttons/Button'
import { useState } from 'react';
import colors from "../styles/colors.styles";
import { useEffect } from 'react';
import { getMarketplaceConfig, updateMarketplaceConfig } from "../services/marketplace.service";

const Marketplace = () => {
  const [settingsData, setSettingsData] = useState<string>('')
  const [error, setError] = useState<string|null>(null)
  const [success, setSuccess] = useState<string|null>(null)

  const saveSettings = async () => {
    try {
      const parsedInput = JSON.parse(settingsData)
      if (parsedInput.type === "marketplace-template") {
        const response = await updateMarketplaceConfig(parsedInput)
        setSettingsData(response.jsonData)
        setError(null)
        setSuccess('Sauvegardé')
      }
      else {
        setSuccess(null)
        setError('Le fichier ne contient pas la configuration pour Marketplace')
      }
    } catch (e) {
      setSuccess(null)
      setError('Le fichier copié est invalide')
    }
  }

  const fetchMarketplaceConfig = async () => {
    const jsonData = await getMarketplaceConfig()
    setSettingsData(jsonData)
  }

  useEffect(() => {
    fetchMarketplaceConfig()
  }, [])

  return (
    <Container>
      <HelperText>
        Copiez-collez le fichier de configuration "settings_data.json" depuis le back-office de Shopify.<br></br>
        <i>Shopify {`>`} Themes {`>`} Edit code {`>`} Config {`>`} settings_data.json</i>
      </HelperText>
      <TextArea
        style={{ resize: 'none', flex: 1 }}
        name="shopify-settings-data"
        placeholder='Copiez-collez "settings_data.json"'
        value={settingsData}
        spellCheck={false}
        onKeyDown={event => {
          if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            saveSettings();
          }
        }}
        onChange={event => {
          setSettingsData(event.target.value)
          setError(null)
          setSuccess(null)

          try {
            const parsedInput = JSON.parse(event.target.value)
            if (parsedInput.current?.sections && parsedInput.current.sections['marketplace-template']) {
              setSettingsData(JSON.stringify(parsedInput.current.sections['marketplace-template']))
            }
            else if (parsedInput.type === "marketplace-template") {
              setSettingsData(JSON.stringify(parsedInput))
            }
            else {
              setError('Le fichier ne contient pas la configuration pour Marketplace')
            }
          } catch (e) {
            setError('Le fichier copié est invalide')
          }
        }}
      ></TextArea>

      <ButtonContainer>
        {error && <FormError>{error}</FormError>}
        {success && <FormSuccess>{success}</FormSuccess>}
        <Button type="button" buttonType="primary" text="Sauvegarder" onClick={saveSettings}></Button>
      </ButtonContainer>
    </Container>
  )
}

export default Marketplace

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: "0 38px 0 38px";
  width: 100%;
  height: 100%;
`

const HelperText = styled.div`
  margin: 11px 22px 0 22px;
  padding: 10px;
  background-color: ${colors.beige.primary};
  border: 2px solid ${colors.beige.tertiary};
  border-radius: 4px;
  color: ${colors.blue.primary};
`

const ButtonContainer = styled.div`
  padding: 0 22px 22px 22px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const FormError = styled.div`
  color: ${colors.mono.danger};
  margin-right: 20px;
`

const FormSuccess = styled.div`
  color: ${colors.mono.success};
  margin-right: 20px;
`

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  getPopUpAppSettings,
  updatePopUpAppSettings,
} from '../../services/settings/settings.service';
import colors from '../../styles/colors.styles';
import fonts from '../../styles/fonts.styles';
import MediaUploader from '../forms/options/sections/MediaUploader';
import { SettingsSection } from './SettingsSection';
import { EditableCTA } from './components/EditableCTA';
import { PopUpAppPreview } from './components/PopUpAppPreview';
import AddButton from '../forms/buttons/AddButton';
import { NewPopupConfirmationModal } from './components/NewPopupConfirmationModal';
import { v4 as uuidv4 } from 'uuid';

type PopUpAppSectionProps = {
  triggerSubmit: boolean;
};

export const PopUpAppSection = ({ triggerSubmit }: PopUpAppSectionProps) => {
  const [isRequestingNewPopup, setIsRequestingNewPopup] = useState(false);
  const [popupUUID, setPopupUUID] = useState<string | undefined>(undefined);

  const [imageLink, setImageLink] = useState('');

  const [ctaBackgroundColor, setCtaBackgroundColor] = useState(
    colors.blue.primary
  );
  const [ctaTextColor, setCtaTextColor] = useState(colors.white.primary);
  const [ctaText, setCtaText] = useState('');

  const [title, setTitle] = useState('');
  const [titleColor, setTitleColor] = useState(colors.blue.primary);
  const [body, setBody] = useState('');
  const [bodyColor, setBodyColor] = useState(colors.blue.primary);

  const [active, setActive] = useState(false);
  const [redirectLink, setRedirectLink] = useState('');

  // Fetches the settings when the component mounts
  useEffect(() => {
    getPopUpAppSettings().then((data) => {
      setPopupUUID(data.uuid.length === 0 ? uuidv4() : data.uuid);
      setImageLink(data.imageLink);
      setCtaBackgroundColor(
        data.ctaBackgroundColor.length === 0
          ? colors.blue.primary
          : data.ctaBackgroundColor
      );
      setCtaTextColor(
        data.ctaTextColor.length === 0
          ? colors.white.primary
          : data.ctaTextColor
      );
      setCtaText(data.ctaText);
      setTitle(data.title);
      setTitleColor(
        data.titleColor.length === 0 ? colors.blue.primary : data.titleColor
      );
      setBody(data.body);
      setBodyColor(
        data.bodyColor.length === 0 ? colors.blue.primary : data.bodyColor
      );
      setActive(data.active);
      setRedirectLink(data.redirectLink);
    });
  }, []);

  // Updates the settings when the triggerSubmit prop is true
  useEffect(() => {
    if (triggerSubmit) {
      const submitSettings = async () => {
        await updatePopUpAppSettings({
          uuid: popupUUID ?? uuidv4(),
          imageLink,
          ctaBackgroundColor,
          ctaTextColor,
          ctaText,
          title,
          titleColor,
          body,
          bodyColor,
          active,
          redirectLink,
        });
      };
      submitSettings();
    }
  }, [
    popupUUID,
    active,
    body,
    bodyColor,
    ctaBackgroundColor,
    ctaText,
    ctaTextColor,
    imageLink,
    redirectLink,
    title,
    titleColor,
    triggerSubmit,
  ]);

  const resetValues = () => {
    setPopupUUID(undefined);
    setImageLink('');
    setCtaBackgroundColor('#ffffff');
    setCtaTextColor('#00000');
    setCtaText('');
    setTitle('');
    setBody('');
    setActive(false);
    setRedirectLink('');
  };

  const colorsPicker = [
    {
      color: ctaBackgroundColor,
      setColor: setCtaBackgroundColor,
      label: 'Couleur de fond du CTA',
    },
    {
      color: ctaTextColor,
      setColor: setCtaTextColor,
      label: 'Couleur du texte du CTA',
    },
    {
      color: titleColor,
      setColor: setTitleColor,
      label: 'Couleur du titre de la pop up',
    },
    {
      color: bodyColor,
      setColor: setBodyColor,
      label: 'Couleur du texte de la pop up',
    },
  ];

  return (
    <SettingsSection
      title="Pop Up de l'app"
      description="Paramètres de la pop up affichée sur l'app"
      headerExtra={
        popupUUID ? (
          <NewPopupButon onClick={() => setIsRequestingNewPopup(true)}>
            <AddButton />
            <p>Nouvelle popup</p>
          </NewPopupButon>
        ) : undefined
      }
    >
      {isRequestingNewPopup && (
        <NewPopupConfirmationModal
          setIsOpen={setIsRequestingNewPopup}
          isOpen={isRequestingNewPopup}
          onConfirm={() => {
            resetValues();
            setPopupUUID(uuidv4());
          }}
        />
      )}
      <ContentContainer>
        <EditableContainer>
          <TopRowContainer>
            <MediaUploader
              title="Image de la pop up"
              imageFieldName="popupUrl"
              imageUrl={''}
              onSetImage={setImageLink}
              onDeleteImage={() => setImageLink('')}
            />
            <VerticalSeparator />
            <CtaTextContainer>
              <Subtitle>Texte du CTA</Subtitle>
              <CtaTextInput
                value={ctaText}
                onChange={(e) => setCtaText(e.target.value)}
              />
              <Subtitle>Lien de redirection</Subtitle>
              <CtaTextInput
                value={redirectLink}
                onChange={(e) => setRedirectLink(e.target.value)}
              />
            </CtaTextContainer>
          </TopRowContainer>

          <PopupTextContainer>
            <Subtitle>Titre de la pop up</Subtitle>
            <PopupTitleInput
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Subtitle>Texte la pop up</Subtitle>
            <PopupBodyTextArea
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />
          </PopupTextContainer>

          <EditableCTA
            colors={colorsPicker}
            title="Selectionnez les couleurs"
          />
        </EditableContainer>

        <VerticalSeparator />

        <PopUpAppPreview
          imageLink={imageLink}
          title={title}
          body={body}
          ctaText={ctaText}
          ctaBackgroundColor={ctaBackgroundColor}
          ctaTextColor={ctaTextColor}
          titleColor={titleColor}
          bodyColor={bodyColor}
          previewTitle={<SectionSubtitle>Aperçu du rendu.</SectionSubtitle>}
          isActive={active}
          onReset={resetValues}
          onToggleActive={() => setActive(!active)}
        />
      </ContentContainer>
    </SettingsSection>
  );
};

const NewPopupButon = styled.button`
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: ${colors.blue.primary};
`;

const SectionSubtitle = styled.p`
  font-family: ${fonts.WorkSans.semiBold};
  font-size: 12px;
  color: ${colors.blue.primary};
  padding: 10px;
`;

const TopRowContainer = styled.div`
  display: flex;
`;

const EditableContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: space-around;
`;

const PopupTextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const PopupTitleInput = styled.input`
  border-width: 1px;
  border-radius: 7px;
  width: 80%;
  background-color: ${colors.beige.primary};
  color: ${colors.blue.primary};
  font-family: ${fonts.WorkSans.regular};
  font-size: 13px;
  font-weight: bold;
  padding: 5px;
  margin-bottom: 10px;
`;

const PopupBodyTextArea = styled.textarea`
  border-width: 1px;
  border-radius: 7px;
  width: 80%;
  background-color: ${colors.beige.primary};
  color: ${colors.blue.primary};
  font-family: ${fonts.WorkSans.regular};
  font-size: 13px;
  font-weight: bold;
  padding: 5px;
`;

const Subtitle = styled.p`
  font-family: ${fonts.WorkSans.semiBold};
  font-size: 12px;
  color: ${colors.blue.primary};
  padding: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  column-gap: 20px;
`;

const VerticalSeparator = styled.div`
  width: 1px;
  align-self: stretch;
  background-color: #000000;
`;

const CtaTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CtaTextInput = styled.input`
  border-width: 1px;
  border-radius: 7px;
  width: 80%;
  background-color: ${colors.beige.primary};
  font-family: ${fonts.WorkSans.regular};
  color: ${colors.blue.primary};
  font-size: 13px;
  padding: 5px;
  margin-bottom: 10px;
`;

import axios from 'axios';
import { DroppableItem } from '../../interfaces/droppable';
import { CategoryWithIndexDTO } from '../../interfaces/shop';

export const getCategories = async (): Promise<CategoryWithIndexDTO[]> => {
    const { data } = await axios.get('/category');

    return data.data;
};

export const updateCategories = async (categories: DroppableItem[]) => {
    const result = await axios.post('/category', {
        categories,
    });
    return result;
};

export const deleteCategory = async (id: string) => {
    const result = await axios.delete('/category', {
        params: {
            id,
        },
    });
    return result;
};

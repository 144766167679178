export const QUIZ_ROUTE = '/quiz';
export const QUIZ_GENERAL_INFORMATIONS_ROUTE = `${QUIZ_ROUTE}/general-informations`;
export const QUIZ_LIST_ROUTE = `${QUIZ_ROUTE}/list`;
export const QUIZ_QUESTION_ROUTE = `${QUIZ_ROUTE}/question`;
export const QUIZ_QUESTION_ORDER_ROUTE = `${QUIZ_ROUTE}/question/order`;
export const QUIZ_QUESTION_COLORS_ROUTE = `${QUIZ_QUESTION_ROUTE}/colors`;
export const QUIZ_QUESTION_ROLLOVER_TIME_ROUTE = `${QUIZ_QUESTION_ROUTE}/rollover`;
export const QUIZ_PROGRESS_ROUTE = `${QUIZ_ROUTE}/progress`;
export const QUIZ_PROGRESS_STYLES_ROUTES = `${QUIZ_PROGRESS_ROUTE}/styles`;
export const QUIZ_PROGRESS_STEP_ROUTE = `${QUIZ_PROGRESS_ROUTE}/step`;
export const QUIZ_ANSWERED_PAGE_ROUTE = `${QUIZ_ROUTE}/answered`;

import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { CollectionCategorySelect, Item, Story } from '../interfaces/shop';
import {
    getInProgressPreviewData,
    getUpcomingPreviewData,
} from '../services/api/api';
import { DroppablePreviewBlocs } from '../interfaces/droppable';
import { isHomeItem } from '../utils/typguards/isHomeItem';

interface PreviewContextType {
    setPreviewType: React.Dispatch<
        React.SetStateAction<'inProgress' | 'upcoming'>
    >;
    setPreviewItems: () => React.Dispatch<
        React.SetStateAction<DroppablePreviewBlocs[]>
    >;
    getPreviewItems: () => DroppablePreviewBlocs[];
    updatePreviewItemById: (
        id: string,
        data: DroppablePreviewBlocs
    ) => DroppablePreviewBlocs[];
    homeInProgressPreview: DroppablePreviewBlocs[];
    resetCurrentFormButKeepImages: (
        selectedItem: number,
        selectedTab: number
    ) => void;
    resetCurrentForm: (selectedItem: number, selectedTab: number) => void;
    uploadImage: (
        selectedItem: number,
        selectedTab: number,
        path: string
    ) => void;
    deleteImage: (
        selectedItem: number,
        selectedTab: number,
        databaseObjectName: string,
        urlFieldName: string
    ) => void;
    deletePreviewStory: (
        electedItem: number,
        selectedTab: number,
        selectedStory: number
    ) => void;
    loadHomeInProgressPreview: (customDate?: Date) => void;
    loadHomeUpcomingPreview: (customDate?: Date) => void;
    addCarouselItem: (selectedItem: number) => void;
    addMediaToStory: (selectedItem: number, selectedTab: number) => void;
    removeCarouselItem: (selectedItem: number, selectedTab: number) => void;
    setCategories: (
        selectedItem: number,
        selectedTab: number,
        event: CollectionCategorySelect[]
    ) => void;
    setAllSales: (
        selectedItem: number,
        selectedTab: number,
        allSalesChecked: boolean
    ) => void;
    getAllSales: (selectedItem: number) => boolean;
    setIsOnlyDisplayedToDevs: (
        selectedItem: number,
        selectedTab: number,
        onlyDisplayedToDevs: boolean
    ) => void;
    getIsOnlyDisplayedToDevs: (selectedItem: number) => boolean;
    setPreviewItemTabField: (
        selectedItem: number,
        selectedTab: number,
        fieldName: string,
        fieldValue: any
    ) => void;
    setPreviewItemTabImageField: (
        selectedItem: number,
        selectedTab: number,
        databaseObjectName: string,
        fieldName: string,
        fieldValue: any
    ) => void;
    setPreviewItemTabStory: (
        selectedItem: number,
        selectedTab: number,
        selectedStory: number,
        fieldValue: any
    ) => void;
    setPreviewItemTabAdditionalImageField: (
        selectedItem: number,
        selectedTab: number,
        fieldName: string,
        fieldValue: any
    ) => void;
    getBlockType: (selectedItem: number) => string | undefined;
    getSelectedItem: (selectedItem: number) => DroppablePreviewBlocs;
    getDataType: (
        selectedItem: number,
        selectedTab: number
    ) => string | undefined;
    getSelectedItemTabs: (selectedItem: number) => Item[];
    getSelectedItemTabStories: (
        selectedItem: number,
        selectedTab: number
    ) => Story[];
    getSelectedItemTab: (selectedItem: number, selectedTab: number) => Item;
}

const PreviewContext = createContext<PreviewContextType>(
    {} as PreviewContextType
);

export function PreviewProvider({
    children,
}: {
    children: ReactNode;
}): JSX.Element {
    const [homeInProgressPreview, setHomeInProgressPreview] = useState<
        DroppablePreviewBlocs[]
    >([]);
    const [homeUpcomingPreview, setHomeUpcomingPreview] = useState<
        DroppablePreviewBlocs[]
    >([]);
    const [previewType, setPreviewType] = useState<'inProgress' | 'upcoming'>(
        'inProgress'
    );

    const setPreviewItems = () => {
        if (previewType === 'inProgress') {
            return setHomeInProgressPreview;
        } else if (previewType === 'upcoming') {
            return setHomeUpcomingPreview;
        }
        return setHomeInProgressPreview;
    };

    const getPreviewItems = () => {
        if (previewType === 'inProgress') {
            return homeInProgressPreview;
        } else if (previewType === 'upcoming') {
            return homeUpcomingPreview;
        }
        return homeInProgressPreview;
    };

    const resetCurrentFormButKeepImages = (
        selectedItem: number,
        selectedTab: number
    ) => {
        setHomeInProgressPreview(
            homeInProgressPreview.map((previewItem, i) => {
                const images =
                    homeInProgressPreview[selectedItem].content.items[
                        selectedTab
                    ]?.image;

                if (i === selectedItem) {
                    return {
                        ...previewItem,
                        content: {
                            ...previewItem.content,
                            items: previewItem.content.items.map(
                                (item: Item, index: number) => {
                                    if (index === selectedTab) {
                                        return {
                                            ...item,
                                            additionalImages: {},
                                            image: images,
                                        };
                                    }
                                    return item;
                                }
                            ),
                        },
                    };
                }
                return previewItem;
            })
        );
    };

    const resetCurrentForm = (selectedItem: number, selectedTab: number) => {
        setHomeInProgressPreview(
            homeInProgressPreview.map((previewItem, i) => {
                if (i === selectedItem) {
                    return {
                        ...previewItem,
                        content: {
                            ...previewItem.content,
                            items: previewItem.content.items.map(
                                (item, index) => {
                                    if (index === selectedTab) {
                                        // Preserving or properly initializing all required properties
                                        return {
                                            ...item, // This ensures all properties of item are carried over
                                            additionalImages: {}, // assuming this needs to be reset
                                            image: {}, // assuming this needs to be reset
                                            // Initialize other required properties if not intended to carry over
                                        };
                                    }
                                    return item;
                                }
                            ),
                        },
                    };
                }
                return previewItem;
            })
        );
    };

    const uploadImage = (
        selectedItem: number,
        selectedTab: number,
        path: string
    ) => {
        setPreviewItems()((previewItems) =>
            previewItems.map((previewItem, i) => {
                if (i === selectedItem) {
                    return {
                        ...previewItem,
                        content: {
                            ...previewItem.content,
                            items: previewItem.content.items.map(
                                (item: Item, index: number) => {
                                    if (index === selectedTab) {
                                        return {
                                            ...item,
                                            image: {
                                                ...item.image,
                                                defaultUrl: path,
                                            },
                                        };
                                    }
                                    return item;
                                }
                            ),
                        },
                    };
                }
                return previewItem;
            })
        );
    };

    const deleteImage = (
        selectedItem: number,
        selectedTab: number,
        databaseObjectName: string,
        urlFieldName: string
    ) => {
        setPreviewItems()((previewItems) =>
            previewItems.map((previewItem, i) => {
                if (i === selectedItem) {
                    return {
                        ...previewItem,
                        content: {
                            ...previewItem.content,
                            items: previewItem.content.items.map(
                                (item: Item, index: number) => {
                                    if (index === selectedTab) {
                                        return {
                                            ...item,
                                            [databaseObjectName]: {
                                                ...item.image,
                                                [urlFieldName]: undefined,
                                            },
                                        };
                                    }
                                    return item;
                                }
                            ),
                        },
                    };
                }
                return previewItem;
            })
        );
    };

    const deletePreviewStory = (
        selectedItem: number,
        selectedTab: number,
        selectedStory: number
    ) => {
        setPreviewItems()((previewItems) =>
            previewItems.map((previewItem, i) => {
                if (i === selectedItem) {
                    return {
                        ...previewItem,
                        content: {
                            ...previewItem.content,
                            items: previewItem.content.items.map(
                                (item, index) => {
                                    if (index === selectedTab) {
                                        return {
                                            ...item,
                                            story: item.story.filter(
                                                (_, j) => j !== selectedStory
                                            ),
                                        };
                                    }
                                    return item;
                                }
                            ),
                        },
                    };
                }
                return previewItem;
            })
        );
    };

    const loadHomeInProgressPreview = async (customDate?: Date) => {
        const data = await getInProgressPreviewData(customDate);
        setHomeInProgressPreview(data);
    };

    const loadHomeUpcomingPreview = async (customDate?: Date) => {
        const data = await getUpcomingPreviewData();
        setHomeUpcomingPreview(data);
    };

    const removeCarouselItem = (selectedItem: number, selectedTab: number) => {
        setPreviewItems()((previewItems) =>
            previewItems.map((previewItem, index) => {
                if (index === selectedItem) {
                    return {
                        ...previewItem,
                        content: {
                            ...previewItem.content,
                            items: previewItem.content.items.filter(
                                (el: any, index: number) =>
                                    index !== selectedTab
                            ),
                        },
                    };
                }
                return previewItem;
            })
        );
    };

    const updatePreviewItemById = (
        id: string,
        data: DroppablePreviewBlocs
    ): DroppablePreviewBlocs[] => {
        let tmpArray =
            previewType === 'inProgress'
                ? [...homeInProgressPreview]
                : [...homeUpcomingPreview];
        const tmpPreview = tmpArray.map((x) => {
            if (x.id !== id) {
                return x;
            } else {
                return {
                    ...data,
                };
            }
        });

        if (previewType === 'inProgress') {
            setHomeInProgressPreview(tmpPreview);
        } else if (previewType === 'upcoming') {
            setHomeUpcomingPreview(tmpPreview);
        }

        return tmpPreview;
    };

    const addCarouselItem = (selectedItem: number) => {
        setPreviewItems()((previewItems) =>
            previewItems.map((previewItem, index) => {
                if (index === selectedItem) {
                    const newItem: Item = {
                        webpageUrl: '',
                        dateStart: new Date().toISOString(),
                        dateStop: new Date().toISOString(),
                        story: [],
                        image: {},
                        additionalImages: {},
                        collectionImage: {},
                    };
                    return {
                        ...previewItem,
                        content: {
                            ...previewItem.content,
                            items: [
                                ...previewItem.content.items,
                                newItem, // Adding the new properly formatted item
                            ],
                        },
                    };
                }
                return previewItem;
            })
        );
    };

    const addMediaToStory = (selectedItem: number, selectedTab: number) => {
        setPreviewItems()((previewItems) =>
            previewItems.map((previewItem, index) => {
                if (index === selectedItem) {
                    return {
                        ...previewItem,
                        content: {
                            ...previewItem.content,
                            items: previewItem.content.items.map(
                                (item, tabIndex) => {
                                    if (tabIndex === selectedTab) {
                                        // Assuming defaults or generating values for a new story
                                        const newStory: Story = {
                                            id: 0,
                                            url: '',
                                        };
                                        return {
                                            ...item,
                                            story: [...item.story, newStory],
                                        };
                                    }
                                    return item;
                                }
                            ),
                        },
                    };
                }
                return previewItem;
            })
        );
    };

    const setCategories = (
        selectedItem: number,
        selectedTab: number,
        options: CollectionCategorySelect[]
    ) => {
        setPreviewItems()((previewItems) =>
            previewItems.map((previewItem, i) => {
                if (i === selectedItem) {
                    return {
                        ...previewItem,
                        content: {
                            ...previewItem.content,
                            items: previewItem.content.items.map(
                                (item, index) => {
                                    if (index === selectedTab) {
                                        const currentTab = getSelectedItemTab(
                                            selectedItem,
                                            selectedTab
                                        );

                                        // Ensure that collectionId is defined or default to 0 (or another appropriate default)
                                        const collectionId =
                                            currentTab &&
                                            currentTab.id !== undefined
                                                ? currentTab.id
                                                : 0;

                                        return {
                                            ...item,
                                            Category: options.map((e) => {
                                                return {
                                                    category: {
                                                        id: `${e.value}`, // Assuming 'value' is a number.
                                                        index: 0, // Default or determine appropriate index.
                                                        title: e.label, // Assuming 'label' is a string.
                                                    },
                                                    categoryId: `${e.value}`,
                                                    collectionId: collectionId, // Ensured to be a number, not undefined.
                                                    id: `${e.value}`, // Assuming 'value' is a number.
                                                    index: 0, // Default or determine appropriate index.
                                                    title: e.label, // Assuming 'label' is a string.
                                                };
                                            }),
                                        };
                                    }

                                    return item;
                                }
                            ),
                        },
                    };
                }
                return previewItem;
            })
        );
    };

    const setAllSales = (
        selectedItem: number,
        selectedTab: number,
        allSalesChecked: boolean
    ) => {
        setPreviewItems()((previewItems) =>
            previewItems.map((previewItem, i) => {
                if (i !== selectedItem) return previewItem;
                return {
                    ...previewItem,
                    content: {
                        ...previewItem.content,
                        isDisplayedInallSales: allSalesChecked,
                    },
                };
            })
        );
    };

    const getAllSales = (selectedItem: number) => {
        const previewSelectedItem = getPreviewItems()[selectedItem];

        if (isHomeItem(previewSelectedItem.content)) {
            return previewSelectedItem.content.isDisplayedInallSales ?? true;
        }

        return true;
    };

    const setIsOnlyDisplayedToDevs = (
        selectedItem: number,
        selectedTab: number,
        onlyDisplayedToDevs: boolean
    ) => {
        setPreviewItems()((previewItems) =>
            previewItems.map((previewItem, i) => {
                if (i !== selectedItem) return previewItem;
                return {
                    ...previewItem,
                    content: {
                        ...previewItem.content,
                        isOnlyDisplayedToDevs: onlyDisplayedToDevs,
                    },
                };
            })
        );
    };

    const getIsOnlyDisplayedToDevs = (selectedItem: number) => {
        const previewSelectedItem = getPreviewItems()[selectedItem];

        if (isHomeItem(previewSelectedItem.content)) {
            return previewSelectedItem.content.isOnlyDisplayedToDevs ?? false;
        }

        return false;
    };

    const setPreviewItemTabField = (
        selectedItem: number,
        selectedTab: number,
        fieldName: any,
        fieldValue: any
    ) => {
        setPreviewItems()((previewItems) =>
            previewItems.map((previewItem, i) => {
                if (i === selectedItem) {
                    return {
                        ...previewItem,
                        content: {
                            ...previewItem.content,
                            items: previewItem.content.items.map(
                                (item: Item, index: number) => {
                                    if (index === selectedTab) {
                                        return {
                                            ...item,
                                            [fieldName]: fieldValue,
                                        };
                                    }
                                    return item;
                                }
                            ),
                        },
                    };
                }
                return previewItem;
            })
        );
    };

    const setPreviewItemTabImageField = (
        selectedItem: number,
        selectedTab: number,
        databaseObjectName: string,
        fieldName: string,
        fieldValue: any
    ) => {
        setPreviewItems()((previewItems) =>
            previewItems.map((previewItem, i) => {
                if (i === selectedItem) {
                    return {
                        ...previewItem,
                        content: {
                            ...previewItem.content,
                            items: previewItem.content.items.map(
                                (item: Item, index: number) => {
                                    if (index === selectedTab) {
                                        return {
                                            ...item,
                                            [databaseObjectName]: {
                                                ...item.image,
                                                [fieldName]: fieldValue,
                                            },
                                        };
                                    }
                                    return item;
                                }
                            ),
                        },
                    };
                }
                return previewItem;
            })
        );
    };

    const setPreviewItemTabStory = (
        selectedItem: number,
        selectedTab: number,
        selectedStory: number,
        fieldValue: any
    ) => {
        setPreviewItems()((previewItems) =>
            previewItems.map((previewItem, i) => {
                if (i === selectedItem) {
                    return {
                        ...previewItem,
                        content: {
                            ...previewItem.content,
                            items: previewItem.content.items.map(
                                (item, index) => {
                                    if (index === selectedTab) {
                                        return {
                                            ...item,
                                            story: item.story.map((el, j) => {
                                                if (selectedStory === j) {
                                                    return {
                                                        ...el, // Preserve all existing properties
                                                        url: fieldValue, // Update only the url
                                                    };
                                                }
                                                return el;
                                            }),
                                        };
                                    }
                                    return item;
                                }
                            ),
                        },
                    };
                }
                return previewItem;
            })
        );
    };

    const setPreviewItemTabAdditionalImageField = (
        selectedItem: number,
        selectedTab: number,
        fieldName: string,
        fieldValue: any
    ) => {
        setPreviewItems()((previewItems) =>
            previewItems.map((previewItem, i) => {
                if (i === selectedItem) {
                    return {
                        ...previewItem,
                        content: {
                            ...previewItem.content,
                            items: previewItem.content.items.map(
                                (item: Item, index: number) => {
                                    if (index === selectedTab) {
                                        return {
                                            ...item,
                                            additionalImages: {
                                                ...item.additionalImages,
                                                [fieldName]: fieldValue,
                                            },
                                        };
                                    }
                                    return item;
                                }
                            ),
                        },
                    };
                }
                return previewItem;
            })
        );
    };

    const getBlockType = (selectedItem: number): string | undefined => {
        const item = getPreviewItems()[selectedItem].content;

        return item.blockType;
    };

    const getDataType = (selectedItem: number, selectedTab: number) => {
        return getPreviewItems()[selectedItem].content?.items[selectedTab]
            ?.datatype;
    };

    const getSelectedItem = (selectedItem: number) => {
        return getPreviewItems()[selectedItem];
    };

    const getSelectedItemTabs = (selectedItem: number) => {
        return getPreviewItems()[selectedItem].content.items;
    };

    const getSelectedItemTabStories = (
        selectedItem: number,
        selectedTab: number
    ) => {
        return getPreviewItems()[selectedItem].content.items[selectedTab].story;
    };

    const getSelectedItemTab = (selectedItem: number, selectedTab: number) => {
        return getPreviewItems()[selectedItem].content.items[selectedTab];
    };

    const memoedValue = useMemo(
        () => ({
            previewType,
            homeInProgressPreview,
            homeUpcomingPreview,
            setPreviewType,
            setPreviewItems,
            getPreviewItems,
            resetCurrentForm,
            resetCurrentFormButKeepImages,
            uploadImage,
            deleteImage,
            deletePreviewStory,
            loadHomeInProgressPreview,
            loadHomeUpcomingPreview,
            addCarouselItem,
            addMediaToStory,
            removeCarouselItem,
            setCategories,
            setAllSales,
            getAllSales,
            setIsOnlyDisplayedToDevs,
            getIsOnlyDisplayedToDevs,
            setPreviewItemTabField,
            setPreviewItemTabImageField,
            setPreviewItemTabAdditionalImageField,
            setPreviewItemTabStory,
            getBlockType,
            getSelectedItem,
            getDataType,
            getSelectedItemTabs,
            getSelectedItemTabStories,
            getSelectedItemTab,
            updatePreviewItemById,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [homeInProgressPreview, previewType, homeUpcomingPreview]
    );

    return (
        <PreviewContext.Provider value={memoedValue}>
            {children}
        </PreviewContext.Provider>
    );
}

export default function usePreview() {
    return useContext(PreviewContext);
}

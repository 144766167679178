import { HomeItem } from '../../interfaces/shop';
import { isItem } from './isItem';

export function isHomeItem(item: any): item is HomeItem {
    const isDateString = (date: any): boolean => {
        if (typeof date !== 'string') return false;
        const parsedDate = Date.parse(date);
        return !isNaN(parsedDate);
    };

    return (
        item &&
        (typeof item.id === 'number' ||
            typeof item.id === 'string' ||
            item.id === undefined) &&
        (typeof item.index === 'number' || item.index === undefined) &&
        (typeof item.blockType === 'string' || item.blockType === undefined) &&
        (item.date === undefined ||
            item.date instanceof Date ||
            isDateString(item.date)) &&
        Array.isArray(item.items) &&
        item.items.every((i: any) => isItem(i)) &&
        (typeof item.isDisplayedInallSales === 'boolean' ||
            item.isDisplayedInallSales === undefined) &&
        (typeof item.isOnlyDisplayedToDevs === 'boolean' ||
            item.isOnlyDisplayedToDevs === undefined)
    );
}

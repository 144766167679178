import { ReactNode, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../../styles/colors.styles';
import fonts from '../../../styles/fonts.styles';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

interface ButtonNavbarProps {
  type: 'link' | 'menu' | 'action';
  text: string;
  to?: any;
  subNavigationLocations?: string[];
  onClick?: () => void;
  children?: ReactNode;
}

const ButtonNavbar = ({ type, text, to, ...props }: ButtonNavbarProps) => {
  const [menuState, setMenuState] = useState<boolean>(false);
  const location = useLocation();
  const isSelected =
    (type === 'link' && to === location.pathname) ||
    (type !== 'link' &&
      !!props.subNavigationLocations &&
      props.subNavigationLocations.includes(location.pathname));

  const handleMenuClick = () => {
    setMenuState(!menuState);
  };

  if (type === 'link') {
    return (
      <LinkContainer to={to} isSelected={isSelected}>
        <SelectionIndicator isSelected={isSelected} />
        <Span isSelected={isSelected}>{text}</Span>
      </LinkContainer>
    );
  } else if (type === 'action') {
    return (
      <Button onClick={props.onClick} isSelected={false}>
        <LabelContainer>
          <Span isSelected={false}>{text}</Span>
        </LabelContainer>
      </Button>
    );
  }

  return (
    <>
      <Button onClick={handleMenuClick} isSelected={isSelected}>
        <SelectionIndicator isSelected={isSelected} />
        <LabelContainer>
          <Span isSelected={isSelected}>{text}</Span>
          {menuState ? (
            <AiOutlineMinus
              style={{ height: 18, width: 18, color: colors.orange.primary }}
            />
          ) : (
            <AiOutlinePlus
              style={{ height: 18, width: 18, color: colors.blue.primary }}
            />
          )}
        </LabelContainer>
      </Button>
      {menuState ? (
        <ChildrenContainer>{props.children}</ChildrenContainer>
      ) : null}
    </>
  );
};

export default ButtonNavbar;

const Button = styled.span<{ isSelected: boolean }>`
  margin-right: -16px;
  width: calc(100% + 16px);
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 10px 0;
  background: ${(props) =>
    props.isSelected ? colors.orange.overlay : 'transparent'};

  &:hover {
    background: ${colors.orange.overlay};
  }
`;

const Span = styled.span<{ isSelected: boolean }>`
  font-size: 15px;
  font-family: ${(props) =>
    props.isSelected ? fonts.WorkSans.semiBold : fonts.WorkSans.regular};
  color: ${(props) =>
    props.isSelected ? colors.orange.primary : colors.blue.primary};
  margin-left: 16px;
`;

const LinkContainer = styled(Link)<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  margin-right: -16px;
  width: calc(100% + 16px);
  padding: 10px 0;
  background: ${(props) =>
    props.isSelected ? colors.orange.overlay : 'transparent'};

  &:hover {
    background: ${colors.orange.overlay};
  }
`;

const ChildrenContainer = styled.div`
  display: grid;
  margin-left: 32px;
  grid-template-columns: 1fr;
  grid-row-gap: 14px;
  margin-bottom: 15px;
`;

const SelectionIndicator = styled.div<{ isSelected: boolean }>`
  margin-top: -10px;
  height: calc(100% + 20px);
  width: 2px;
  background: ${(props) =>
    props.isSelected ? colors.orange.primary : 'transparent'};
`;

const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
`;

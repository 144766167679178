import React from "react";
import styled from "styled-components";
import usePreview from "../../../../contexts/PreviewContext";
import Input from "../../inputs/Input";
import Title from "../miscellaneous/Title";

interface ProductVendorProps {
    selectedItem: number;
    selectedTab: number;
}

const ProductVendor: React.FC<ProductVendorProps> = ({
    selectedItem,
    selectedTab,
}) => {
    const preview = usePreview();

    const handleChange = (event: any) => {
        preview.setPreviewItemTabField(
            selectedItem,
            selectedTab,
            "productVendor",
            event.target.value
        );
    };

    return (
        <Container>
            <Title>Product Vendor</Title>
            <Input
                type="text"
                name="productVendor"
                value={
                    preview.getSelectedItemTab(selectedItem, selectedTab)
                        .productVendor || ""
                }
                onChange={handleChange}
            />
        </Container>
    );
};

export default ProductVendor;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
`;

import React, { InputHTMLAttributes } from "react"
import styled from "styled-components"
import usePreview from "../../../../contexts/PreviewContext"
import Input from "../../inputs/Input"
import Title from "../miscellaneous/Title"

interface DiscountProps extends InputHTMLAttributes<HTMLInputElement> {
  selectedItem: number
  selectedTab: number
}

const Discount: React.FC<DiscountProps> = ({ selectedItem, selectedTab }) => {
  const preview = usePreview()

  const handleChange = (event: any, type: string) => {
    if (type === "AllAt") {
      preview.setPreviewItemTabField(
        selectedItem,
        selectedTab,
        "discountAllAt",
        event.target.value
      )
      preview.setPreviewItemTabField(
        selectedItem,
        selectedTab,
        "discountUpTo",
        undefined
      )
    } else if (type === "UpTo") {
      preview.setPreviewItemTabField(
        selectedItem,
        selectedTab,
        "discountAllAt",
        undefined
      )
      preview.setPreviewItemTabField(
        selectedItem,
        selectedTab,
        "discountUpTo",
        event.target.value
      )
    }
  }

  return (
    <Container>
      <Title>Réduction de la vente %</Title>
      <InputsContainer>
        <InputContainer style={{ marginRight: 11.5 }}>
          <label>jusqu’à</label>
          <Input
            type="number"
            name="discountUpTo"
            value={
              preview.getSelectedItemTab(selectedItem, selectedTab)
                .discountUpTo || ""
            }
            onChange={(event) => handleChange(event, "UpTo")}
          />
        </InputContainer>
        <InputContainer style={{ marginLeft: 11.5 }}>
          <label>tout à</label>
          <Input
            type="number"
            name="discountAllAt"
            value={
              preview.getSelectedItemTab(selectedItem, selectedTab)
                .discountAllAt || ""
            }
            onChange={(event) => handleChange(event, "AllAt")}
          />
        </InputContainer>
      </InputsContainer>
    </Container>
  )
}

export default Discount

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`

const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

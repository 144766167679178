import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ColorPicker } from '../../../../components/forms/inputs/ColorPicker';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../shadcn/components/ui/form';
import { useToast } from '../../../../shadcn/components/ui/use-toast';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuizContext } from '../../context/QuizContext';
import { putQuizQuestionColors } from '../../../../services/api/quiz/quiz';
import { Button } from '../../../../shadcn/components/ui/button';

const formSchema = z.object({
  questionCorrectColor: z.string(),
  questionIncorrectColor: z.string(),
});

export const QuizQuestionColors = () => {
  const { quiz } = useQuizContext();

  const { toast } = useToast();
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      questionCorrectColor: quiz?.questionCorrectColor,
      questionIncorrectColor: quiz?.questionIncorrectColor,
    },
  });

  const updateQuizQuestionColorsMutation = useMutation({
    mutationFn: putQuizQuestionColors,
    onSuccess: async (data) => {
      if (data.success) {
        toast({
          title: 'Changements enregistré !',
          description:
            'Les couleurs des bonnes et mauvaises réponses ont bien été enregistrées.',
        });

        queryClient.invalidateQueries({ queryKey: ['quiz', quiz?.id] });

        form.reset({
          questionCorrectColor: data.quiz.questionCorrectColor,
          questionIncorrectColor: data.quiz.questionIncorrectColor,
        });
      } else {
        toast({
          variant: 'destructive',
          title: "Oops ! Une erreur s'est produite.",
          description:
            "L'enregistrement de vos changements n'ont pas été fait. Veuillez-réessayer.",
        });
      }
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (quiz?.id) {
      updateQuizQuestionColorsMutation.mutate({
        id: quiz?.id,
        ...values,
      });
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="space-y-5">
          <h4 className="text-xl font-light leading-none tracking-tight text-bluePrimary">
            Couleurs
          </h4>

          <div className="flex space-x-8">
            <FormField
              control={form.control}
              name="questionCorrectColor"
              render={({ field }) => (
                <FormItem className="flex flex-col items-center justify-center space-y-1.5">
                  <FormLabel>Bonne réponse</FormLabel>
                  <FormControl className="bg-bluePrimary">
                    <ColorPicker {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="questionIncorrectColor"
              render={({ field }) => (
                <FormItem className="flex flex-col items-center justify-center space-y-1.5">
                  <FormLabel>Mauvaise réponse</FormLabel>
                  <FormControl>
                    <ColorPicker {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex justify-end space-x-4">
            {form.formState.isDirty && (
              <>
                {!updateQuizQuestionColorsMutation.isPending && (
                  <Button
                    variant="outline"
                    type="button"
                    onClick={() => form.reset()}
                  >
                    Réinitialiser
                  </Button>
                )}
                <Button
                  type="submit"
                  isLoading={updateQuizQuestionColorsMutation.isPending}
                >
                  Enregistrer
                </Button>
              </>
            )}
          </div>
        </div>
      </form>
    </Form>
  );
};

export function isISODateString(value: any): value is string {
  if (typeof value !== 'string') {
    return false;
  }

  // Regular expression to validate ISO 8601 date strings
  const iso8601Regex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})$/;

  return iso8601Regex.test(value);
}

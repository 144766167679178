import React from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors.styles';

interface QuestionRowProps {
  question: Question;
  onClick?: () => void;
}

const QuestionRowDeprecated = ({ question, onClick }: QuestionRowProps) => {
  return (
    <Row onClick={onClick}>
      <Column>
        {question.date.toLocaleDateString('FR')} à{' '}
        {question.date.toLocaleTimeString('FR')}
      </Column>
      <Column style={{ flex: 1, justifyContent: 'flex-start' }}>
        {question.question}
      </Column>
      <Column style={{ borderRight: 'none' }}>
        {question.answers.length} réponses |{' '}
        {question.answers.filter((answer) => answer.isCorrect).length}{' '}
        correcte(s)
      </Column>
    </Row>
  );
};

const Row = styled.button`
  display: flex;
  flex-direction: row;
  border: 1px solid ${colors.blue.primary};
  border-radius: 4px;
  background-color: ${colors.beige.tertiary};
  margin-bottom: 5px;
  width: 100%;
  padding: 0;
  cursor: pointer;
  :hover {
    background-color: ${colors.blue.secondary};
    color: ${colors.mono.white};
  }
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-right: 1px solid ${colors.blue.primary};
  padding: 0.75em 1em;
`;

export default QuestionRowDeprecated;

import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import usePreview from '../../contexts/PreviewContext';
import colors from '../../styles/colors.styles';
import fonts from '../../styles/fonts.styles';
import ToggleGroup from '../forms/buttons/ToggleGroup.button';
import DateInput from '../forms/inputs/DateInput.form';
import DragableElement from './DragableElement';
import { parseISO } from 'date-fns';
import {
    ScreenName,
    DroppableItem,
    DroppablePreviewBlocs,
} from '../../interfaces/droppable';

interface PreviewDroppableProps {
    screenName: ScreenName;
    grid: number;
    handleModalOption?: (selectedItem: number) => void;
    isDragDisabled?: boolean;
    filters?: DroppableItem[];
}

const PreviewDroppable: React.FC<PreviewDroppableProps> = ({
    screenName,
    grid,
    handleModalOption = null,
    isDragDisabled = false,
    filters = [],
}) => {
    const [editingItemIndex, setEditingItemIndex] = useState<number | null>(
        null
    );
    const [selectedToggleValue, setSelectedToggleValue] =
        useState<string>('Actuel');
    const [selectedPreviewDateTime, setSelectedPreviewDateTime] =
        useState<Date>(new Date());

    const preview = usePreview();

    const handleClick = (item: DroppablePreviewBlocs, index: number) => {
        setEditingItemIndex(index);
        if (handleModalOption) {
            handleModalOption(index);
        }
    };

    const handlePreviewDateChange = (
        event: React.FormEvent<HTMLInputElement>
    ) => {
        // Parse the input string into a Date object
        const date = parseISO((event.target as HTMLInputElement).value);

        setSelectedPreviewDateTime(date);

        preview.loadHomeInProgressPreview(date);
    };

    const handleSelectedToggleGroupChange = (value: string) => {
        setSelectedToggleValue(value);

        if (value === 'Actuel') {
            if (screenName === 'onGoingSales') {
                preview.loadHomeInProgressPreview();
            } else {
                preview.loadHomeUpcomingPreview();
            }
        } else if (value === 'Aperçu') {
            if (screenName === 'onGoingSales') {
                preview.loadHomeInProgressPreview(selectedPreviewDateTime);
            }
        }
    };

    return (
        <Container>
            <Title>APERÇU DE LA PAGE</Title>

            {screenName === 'onGoingSales' && (
                <>
                    <ToggleGroup
                        values={['Actuel', 'Aperçu']}
                        selectedValue={selectedToggleValue}
                        style={{
                            marginBottom:
                                selectedToggleValue === 'Aperçu' ? 15 : 25,
                        }}
                        onSelectedValueChange={handleSelectedToggleGroupChange}
                    />

                    {selectedToggleValue === 'Aperçu' && (
                        <DateInput
                            name={'previewDate'}
                            date={selectedPreviewDateTime}
                            onChange={(event) => handlePreviewDateChange(event)}
                            style={{ marginBottom: 15 }}
                        />
                    )}
                </>
            )}

            <PreviewHeader>
                <TheBraderyLogo
                    src="/assets/images/theBraderyLogo.svg"
                    alt="The Bradery's logo"
                />
                <TopNavigationPreviewButtons>
                    <ButtonNavigationPreview
                        isFocused={screenName === 'onGoingSales'}
                    >
                        Ventes en cours
                    </ButtonNavigationPreview>
                    <ButtonNavigationPreview
                        isFocused={screenName === 'upComingSales'}
                    >
                        Ventes à venir
                    </ButtonNavigationPreview>
                </TopNavigationPreviewButtons>
                {filters.length > 0 && (
                    <FiltersContainer>
                        {filters.map((filter) => (
                            <FilterButton key={filter.id}>
                                {filter.content.title}
                            </FilterButton>
                        ))}
                    </FiltersContainer>
                )}
            </PreviewHeader>
            <Droppable
                droppableId="droppable2"
                isDropDisabled={selectedToggleValue === 'Aperçu'}
            >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver, grid)}
                    >
                        {preview
                            .getPreviewItems()
                            .filter((item: DroppablePreviewBlocs) => {
                                return item.content.items.length;
                            })
                            .map(
                                (
                                    item: DroppablePreviewBlocs,
                                    index: number
                                ) => (
                                    <DragableElement
                                        key={item.id}
                                        screenName={screenName}
                                        data={item}
                                        index={index}
                                        onClick={handleClick}
                                        editing={
                                            index === editingItemIndex
                                                ? true
                                                : false
                                        }
                                        isDragDisabled={isDragDisabled}
                                    />
                                )
                            )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </Container>
    );
};

export default PreviewDroppable;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 0px 0px 0px;
    width: 390px;
    margin: 0 50px 0 50px;
`;

const Title = styled.div`
    font-size: 15px;
    font-family: ${fonts.WorkSans.semiBold};
    color: ${colors.blue.primary};
    margin-bottom: 20px;
`;

const PreviewHeader = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    background-color: ${colors.beige.primary};
`;

const TheBraderyLogo = styled.img`
    height: 26px;
    width: auto;
`;

const TopNavigationPreviewButtons = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
`;

const ButtonNavigationPreview = styled.div<{ isFocused: boolean }>`
    font-family: ${fonts.Archivo.bold};
    font-size: 14px;
    color: ${colors.blue.primary};
    border-bottom: ${(p) =>
        p.isFocused ? `solid ${colors.blue.primary}` : ''};
    width: 50%;
    text-align: center;
    padding: 8px 0 11px 0;
`;

const FiltersContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 12px 0 12px 0;
    overflow: hidden;
`;

const FilterButton = styled.div`
    font-family: ${fonts.WorkSans.regular};
    font-size: 12px;
    color: ${colors.blue.primary};
    padding: 0 9px 0 9px;
    min-width: fit-content;
    max-width: fit-content;
    display: flex;
    flex: 1;
`;

const getListStyle = (isDraggingOver: boolean, grid: number) => ({
    background: isDraggingOver ? 'lightblue' : colors.beige.primary,
    padding: grid,
    width: '100%',
    flex: '1 1 100%',
});

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import QuizItemDeprecated from '../../components/quiz_deprecated/QuizItemDeprecated';
import { getQuizzes } from '../../services/quiz.service';
import colors from '../../styles/colors.styles';
import fonts from '../../styles/fonts.styles';

const QuizzesDeprecated = () => {
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);

  useEffect(() => {
    getQuizzes().then((quizzes) => {
      setQuizzes(quizzes);
    });
  }, []);

  return (
    <Container>
      <Header>
        <PageTitle>Jeux concours</PageTitle>
      </Header>

      {quizzes.map((quiz) => (
        <QuizItemDeprecated key={quiz.title} quiz={quiz} />
      ))}

      <NewQuizButton href={'/quiz/create'}>Créer nouveau</NewQuizButton>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 38px 0 38px;
  width: 100%;
`;

const Header = styled.div`
  margin: 2rem;
`;

const PageTitle = styled.h1`
  font-family: ${fonts.WorkSans.regular};
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  color: ${colors.blue.primary};
  margin-bottom: 10px;
`;

const NewQuizButton = styled.a`
  font-family: ${fonts.WorkSans.regular};
  font-size: 16px;
  color: ${colors.blue.primary};
  background-color: ${colors.beige.tertiary};
  border: 1px solid ${colors.beige.secondary};
  width: 100%;
  padding: 1em 2em;
  margin-top: 1em;
  border-radius: 4px;
  display: block;
  text-align: center;
  :hover {
    text-decoration: none;
  }
`;

export default QuizzesDeprecated;

import React, { PropsWithChildren } from 'react';
import { DropResult, DragDropContext, Droppable } from 'react-beautiful-dnd';
import { dragItemInSingleDroppable, grid } from '../../utils/droppable';
import { DroppableItem } from '../../interfaces/droppable';

interface SingleDroppableProps {
  items: any;
  setItems: any;
  listStyle: (
    isDraggingOver: boolean,
    grid: number
  ) => React.CSSProperties | undefined;
  onDragEnd: (categories: DroppableItem[]) => void;
}

const SingleDroppable = ({
  items,
  setItems,
  listStyle,
  onDragEnd,
  ...props
}: PropsWithChildren<SingleDroppableProps>) => {
  const handleOnDragEnd = (result: DropResult) => {
    const r = dragItemInSingleDroppable(result, items);
    if (!r) {
      return;
    }
    setItems(r);
    onDragEnd(r);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable1">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={listStyle(snapshot.isDraggingOver, grid)}
          >
            {props.children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SingleDroppable;

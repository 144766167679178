import { QuizQuestion } from '../../types';
import { orderQuestions } from './orderQuestions';

export const isQuestionListOrderDifferent = (
  original: QuizQuestion[],
  localCopy: QuizQuestion[]
): boolean => {
  // Sort the original array by index
  const sortedOriginal = orderQuestions([...original]);

  // Check if the lengths are different
  if (sortedOriginal.length !== localCopy.length) {
    return true;
  }

  // Compare the order of both arrays
  for (let i = 0; i < sortedOriginal.length; i++) {
    if (sortedOriginal[i].id !== localCopy[i].id) {
      return true;
    }
  }

  return false;
};

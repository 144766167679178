import { Button } from '../../../../shadcn/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../../../../shadcn/components/ui/dialog';
import { useEffect, useState } from 'react';
import { ScrollArea } from '../../../../shadcn/components/ui/scroll-area';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postQuizQuestionWinnerSelection } from '../../../../services/api/quiz/quiz';
import { useToast } from '../../../../shadcn/components/ui/use-toast';
import { QuizQuestion } from '../../types';
import { QuizResponseDTO } from '../../../../services/api/quiz/types';
import {
  Dices,
  SquareArrowOutUpRight,
  TriangleAlert,
  Trophy,
} from 'lucide-react';

type QuizQuestionWinnerDialogProps = {
  quizId: string;
  setIsQuestionWinnerDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isQuestionWinnerDialogOpen: boolean;
  question?: QuizQuestion;
  updateSelectedQuestion: React.Dispatch<
    React.SetStateAction<QuizQuestion | undefined>
  >;
};

export const QuizQuestionWinnerDialog = ({
  quizId,
  isQuestionWinnerDialogOpen,
  setIsQuestionWinnerDialogOpen,
  question,
  updateSelectedQuestion,
}: QuizQuestionWinnerDialogProps) => {
  const [generalErrorMessage, setGeneralErrorMessage] = useState('');

  const { toast } = useToast();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isQuestionWinnerDialogOpen) {
      setGeneralErrorMessage('');
    }
  }, [isQuestionWinnerDialogOpen]);

  const postQuizQuestionWinnerSelectionMutation = useMutation({
    mutationFn: postQuizQuestionWinnerSelection,
    onSuccess: async (data) => {
      if (data.success) {
        toast({
          title: 'Gagnant sélectionné !',
          description: 'Le gagnant a bien été sélectionné.',
        });

        if (data.question.winnerShopifyUserId && question) {
          updateSelectedQuestion({
            ...question,
            winnerShopifyUserId: data.question.winnerShopifyUserId,
          });
        }

        queryClient.invalidateQueries({ queryKey: ['quiz', quizId] });
      } else {
        toast({
          variant: 'destructive',
          title: "Oops ! Une erreur s'est produite.",
          description:
            "La sélection du gagnant n'a pas été faite. Veuillez-réessayer.",
        });
      }
    },
  });

  const handleSelectWinner = async () => {
    if (quizId && question?.id) {
      postQuizQuestionWinnerSelectionMutation.mutate({
        quizId,
        questionId: question?.id,
      });
    }
  };

  const handleOpenWinnerInShopifyAdmin = (
    winnerShopifyUserId?: string | null
  ) => {
    if (winnerShopifyUserId) {
      const shopifyAdminUrl = `https://admin.shopify.com/store/my-moon-store/customers/${winnerShopifyUserId}`;
      window.open(shopifyAdminUrl, '_blank', 'noreferrer');
    }
  };

  return (
    <Dialog
      onOpenChange={setIsQuestionWinnerDialogOpen}
      open={isQuestionWinnerDialogOpen}
    >
      <DialogContent className="max-h-[825px] p-0">
        <ScrollArea className="max-h-[800px] p-6">
          <DialogHeader>
            <DialogTitle>Gagnant de la question</DialogTitle>
            <DialogDescription>
              {generalErrorMessage && (
                <p className="text-error">{generalErrorMessage}</p>
              )}
            </DialogDescription>
          </DialogHeader>

          {question?.winnerShopifyUserId ? (
            <div className="mt-3 space-y-6">
              <div className="flex items-end">
                <Trophy className="mr-2 text-blueSecondary" />
                <p>Un gagnant a été sélectionné</p>
                <Trophy className="ml-2 text-blueSecondary" />
              </div>

              <Button
                onClick={() =>
                  handleOpenWinnerInShopifyAdmin(question?.winnerShopifyUserId)
                }
              >
                Profil Shopify du gagnant
                <SquareArrowOutUpRight size={16} className="ml-3" />
              </Button>
            </div>
          ) : (
            <div className="mt-3 space-y-6">
              <div className="flex items-end">
                <TriangleAlert className="mr-2 text-orangePrimary" />
                <p>Aucun gagnant n'a encore été sélectionné.</p>
                <TriangleAlert className="ml-2 text-orangePrimary" />
              </div>
              <Button onClick={() => handleSelectWinner()}>
                <Dices size={16} className="mr-3" />
                Sélectionner un gagnant
              </Button>
            </div>
          )}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

import { Plus } from 'lucide-react';
import { Button } from '../../../../shadcn/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../../shadcn/components/ui/dialog';
import { Form } from '../../../../shadcn/components/ui/form';
import { z } from 'zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { ScrollArea } from '../../../../shadcn/components/ui/scroll-area';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postQuizQuestion } from '../../../../services/api/quiz/quiz';
import { useToast } from '../../../../shadcn/components/ui/use-toast';
import { QuizQuestionForm } from './QuizQuestionForm';
import { generateQuestionZodSchema } from '../utils/generateQuestionZodSchema';
import { Quiz } from '../../types';

type QuizCreateQuestionProps = {
  quiz: Quiz;
};

export const QuizCreateQuestionDialog = ({ quiz }: QuizCreateQuestionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [generalErrorMessage, setGeneralErrorMessage] = useState('');

  const { toast } = useToast();
  const queryClient = useQueryClient();

  const formSchema = generateQuestionZodSchema(setGeneralErrorMessage);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      question: '',
      choices: [{ text: '', isCorrectAnswer: true, index: 0 }],
    },
  });

  const choicesFieldArray = useFieldArray({
    control: form.control,
    name: 'choices',
    keyName: 'key',
  });

  useEffect(() => {
    if (!isOpen) {
      form.reset();
      setGeneralErrorMessage('');
    }
  }, [form, isOpen]);

  const postQuizQuestionMutation = useMutation({
    mutationFn: postQuizQuestion,
    onSuccess: async (data) => {
      if (data.success) {
        toast({
          title: 'Question créée !',
          description: 'La question a bien été créée',
        });

        queryClient.invalidateQueries({ queryKey: ['quiz', quiz.id] });

        form.reset();
        setIsOpen(false);
      } else {
        toast({
          variant: 'destructive',
          title: "Oops ! Une erreur s'est produite.",
          description:
            "La création de la question n'a pas été faite. Veuillez-réessayer.",
        });
      }
    },
  });

  const handleSubmit = async (data: z.infer<typeof formSchema>) => {
    if (quiz.id) {
      postQuizQuestionMutation.mutate({
        quizId: quiz.id,
        index: quiz.questions.length,
        question: data.question,
        choices: data.choices,
        logoUrl: data.logoUrl,
        imageUrl: data.imageUrl,
      });
    }
  };

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <Form {...form}>
        <form>
          <DialogTrigger asChild>
            <Button size="icon-sm">
              <Plus className="h-4 w-4" />
            </Button>
          </DialogTrigger>
          <DialogContent className="max-h-[800px] max-w-[700px] p-0">
            <ScrollArea className="max-h-[775px] p-6">
              <DialogHeader>
                <DialogTitle>Créer une nouvelle question</DialogTitle>
                <DialogDescription>
                  Veuillez renseigner les informations relative à la question.
                  {generalErrorMessage && (
                    <p className="text-error">{generalErrorMessage}</p>
                  )}
                </DialogDescription>
              </DialogHeader>

              <QuizQuestionForm
                choicesFieldArray={choicesFieldArray}
                form={form}
              />

              <DialogFooter>
                <Button
                  type="submit"
                  onClick={form.handleSubmit(handleSubmit)}
                  isLoading={postQuizQuestionMutation.isPending}
                >
                  Créer
                </Button>
              </DialogFooter>
            </ScrollArea>
          </DialogContent>
        </form>
      </Form>
    </Dialog>
  );
};

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../contexts/AuthContext';

type ProtectRoutesProps = {
  roles: string[];
  fallback: string;
};

const ProtectRoutes: React.FC<ProtectRoutesProps> = ({ roles, fallback }) => {
  const { user, jwt } = useAuth();

  if (!user || !jwt || !roles.includes(user.role)) {
    return <Navigate to={fallback} />;
  }

  return <Outlet />;
};

export default ProtectRoutes;

import React from "react";
import styled from "styled-components";
import usePreview from "../../../../contexts/PreviewContext";
import { Story } from "../../../../interfaces/shop";
import AddButton from "../../buttons/AddButton";
import MediaUploader from "./MediaUploader";

interface CarouselStoryProps {
  selectedItem: number;
  selectedTab: number;
}

const CarouselStory: React.FC<CarouselStoryProps> = ({
  selectedItem,
  selectedTab,
}) => {
  const preview = usePreview();

  const handleAddButton = () => {
    preview.addMediaToStory(selectedItem, selectedTab);
  };

  const data = preview.getSelectedItemTabStories(selectedItem, selectedTab);

  if (!data) return <></>;

  return (
    <Carousel>
      {data.map((story: Story, index: number) => (
        <MediaUploader
          key={index}
          title={""}
          imageFieldName={"story" + index}
          imageUrl={
            preview.getSelectedItem(selectedItem).content.items[0]?.story[index]
              .url
          }
          onSetImage={path =>
            preview.setPreviewItemTabStory(
              selectedItem,
              selectedTab,
              index,
              path,
            )
          }
          onDeleteImage={() =>
            preview.deletePreviewStory(selectedItem, selectedTab, index)
          }
        />
      ))}

      <AddButton onClick={handleAddButton} />
    </Carousel>
  );
};

export default CarouselStory;

const Carousel = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  align-items: center;

  margin: 10px 0;
`;

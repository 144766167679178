import React from "react"
import styled from "styled-components"
import { HomeItem } from "../../interfaces/shop"

interface TextBannerProps {
  data: HomeItem
  isEditing: boolean
}

const TextBanner: React.FC<TextBannerProps> = ({ data, isEditing }) => {
  return (
    <Container setOpacity={isEditing}>
      <p>{"data.items.text"}</p>
    </Container>
  )
}

export default TextBanner

const Container = styled.div<{ setOpacity: boolean }>`
  display: flex;
  opacity: ${(p: any) => (p.setOpacity ? `0.5` : `1`)};
`

import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";
import usePreview from "../../../../contexts/PreviewContext";
import Input from "../../inputs/Input";
import Title from "../miscellaneous/Title";

interface ExpectedDeliveryProps extends InputHTMLAttributes<HTMLInputElement> {
  selectedItem: number;
  selectedTab: number;
}

const ExpectedDelivery: React.FC<ExpectedDeliveryProps> = ({
  selectedItem,
  selectedTab,
}) => {
  const preview = usePreview();

  const handleChange = (event: any) => {
    preview.setPreviewItemTabField(
      selectedItem,
      selectedTab,
      "expectedDelivery",
      event.target.value,
    );
  };

  return (
    <Container>
      <Title>Date de livraison estimée</Title>
      <Input
        type="text"
        name="expectedDelivery"
        onChange={handleChange}
        value={
          preview.getSelectedItemTab(selectedItem, selectedTab)
            .expectedDelivery || ""
        }
      />
    </Container>
  );
};

export default ExpectedDelivery;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`;

import { useState } from 'react';
import { Button } from '../../../shadcn/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../shadcn/components/ui/dialog';
import { Input } from '../../../shadcn/components/ui/input';
import { Label } from '../../../shadcn/components/ui/label';
import { Formik, FormikHelpers } from 'formik';
import { styled } from 'styled-components';
import colors from '../../../styles/colors.styles';
import { AxiosError } from 'axios';
import { createUser } from '../../../services/auth.service';
import {
  RadioGroup,
  RadioGroupItem,
} from '../../../shadcn/components/ui/radio-group';

export interface CreateUserFormValues {
  email: string;
  role: string;
}

interface CreateUserFormErrors {
  email?: string;
}

interface AddUserModalProps {
  onUserCreated: () => void;
}

export const AddUserDialog = ({ onUserCreated }: AddUserModalProps) => {
  const [error, setError] = useState<string>();
  const [open, setOpen] = useState(false);
  const [createdUser, setCreatedUser] = useState<CreateUserResponseDTO>();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Formik
        initialValues={{ email: '', role: 'editor' }}
        validate={(values: CreateUserFormValues) => {
          const errors: CreateUserFormErrors = {};

          if (!values.email) {
            errors.email = 'Requis';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Email adresse non-valide';
          }

          return errors;
        }}
        onSubmit={(
          values: CreateUserFormValues,
          { setSubmitting, setErrors }: FormikHelpers<CreateUserFormValues>
        ) => {
          setSubmitting(true);
          setErrors({});
          createUser(values)
            .then((res) => {
              setCreatedUser(res);
              onUserCreated();
            })
            .catch(
              (err: AxiosError<{ statusCode: number; message: string }>) => {
                if (
                  err.response &&
                  err?.response?.data?.statusCode === 409 &&
                  err.response.data.message === 'email'
                ) {
                  setErrors({
                    email:
                      'Un utilisateur est déjà associé à cet adresse mail.',
                  });
                } else {
                  setError(
                    "Une erreur s'est produite lors de la création de l'utilisateur."
                  );
                }
              }
            )
            .finally(() => setSubmitting(false));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          submitForm,
          resetForm,
        }) => (
          <>
            <DialogTrigger asChild>
              <Button>Ajouter un utilisateur</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>Ajouter un utilisateur</DialogTitle>
                <DialogDescription>
                  Veuillez entrer les informations du nouvel utilisateur
                </DialogDescription>
                {error && <ErrorContainer>{error}</ErrorContainer>}
              </DialogHeader>

              {!!createdUser ? (
                <>
                  <SuccessMessageTextContainer>
                    Le compte{' '}
                    <span style={{ color: colors.blue.primary }}>
                      {createdUser.email}
                    </span>{' '}
                    a bien été créé. Voici le mot de passe permettant d'accéder
                    au compte.
                  </SuccessMessageTextContainer>
                  <SuccessMessageWarningContainer>
                    Attention ! Enregistrez ce mot de passe car il ne sera plus
                    consultable.
                  </SuccessMessageWarningContainer>
                  <PasswordContainer>{createdUser.password}</PasswordContainer>
                </>
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="email" className="text-right">
                        Email
                      </Label>
                      <Input
                        id="email"
                        className="col-span-3"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && (
                        <ErrorContainer className="col-span-3 col-start-2">
                          {errors.email}
                        </ErrorContainer>
                      )}
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="role" className="text-right">
                        Rôle
                      </Label>
                      <RadioGroup
                        id="role"
                        defaultValue="editor"
                        name="role"
                        className="col-span-3"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem value="editor" id="editor" />
                          <Label htmlFor="editor">Editor</Label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem value="admin" id="admin" />
                          <Label htmlFor="admin">Admin</Label>
                        </div>
                      </RadioGroup>
                    </div>
                  </div>
                </form>
              )}

              <DialogFooter>
                {!createdUser ? (
                  <>
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      onClick={submitForm}
                    >
                      Ajouter
                    </Button>
                    <Button
                      onClick={() => {
                        resetForm();
                        setOpen(false);
                      }}
                      type="button"
                    >
                      Annuler
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      resetForm();
                      setOpen(false);
                    }}
                    type="button"
                  >
                    Fermer
                  </Button>
                )}
              </DialogFooter>
            </DialogContent>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

const ErrorContainer = styled.div`
  color: ${colors.orange.primary};
`;

const SuccessMessageTextContainer = styled.div``;

const SuccessMessageWarningContainer = styled.div`
  color: ${colors.orange.primary};
  font-weight: bold;
`;

const PasswordContainer = styled.div`
  background-color: ${colors.beige.tertiary};
  padding: 8px;
  font-family: monospace;
  margin-bottom: 16px;
`;

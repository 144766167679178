import { useState } from 'react';
import { Button } from '../../../shadcn/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../shadcn/components/ui/dialog';
import { Trash2 } from 'lucide-react';
import { deleteUser } from '../../../services/users.service';

type DeleteUserDialogProps = {
  onUserDeleted: () => void;
  user: User;
};

export const DeleteUserDialog = ({
  onUserDeleted,
  user,
}: DeleteUserDialogProps) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onDeleteClickedHandler = () => {
    setIsLoading(true);
    deleteUser(user)
      .then((res) => {
        onUserDeleted();
        setOpen(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" size="icon">
          <Trash2 className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Supprimer un utilisateur</DialogTitle>
          <DialogDescription>
            Êtes-vous sûr de vouloir supprimer le compte de l'utilisateur{' '}
            <span className="text-bluePrimary">{user.email}</span> ?
          </DialogDescription>
        </DialogHeader>

        <DialogFooter>
          {!isLoading && (
            <Button
              variant="outline"
              onClick={() => {
                setOpen(false);
              }}
              type="button"
            >
              Annuler
            </Button>
          )}
          <Button
            variant="destructive"
            isLoading={isLoading}
            onClick={onDeleteClickedHandler}
          >
            Supprimer
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export type QuizProgressStepFormValues = {
  value: string;
  titleHtml?: string;
  titleColor?: string;
  subTitleHtml?: string;
  subTitleColor?: string;
};

export enum CTAAction {
  CUSTOM_URL = 'CUSTOM_URL',
  NOTIFICATIONS = 'NOTIFICATIONS',
  DEEP_LINK = 'DEEP_LINK',
}

import { Plus } from 'lucide-react';
import { Button } from '../../../../shadcn/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../../shadcn/components/ui/dialog';
import { Form } from '../../../../shadcn/components/ui/form';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '../../../../shadcn/components/ui/use-toast';
import { Quiz, QuizRoadmapStep } from '../../types';
import { generateProgressStepZodSchema } from '../utils/generateProgressStepZodSchema';
import {
  deleteQuizProgressStep,
  postCreateQuizProgressStep,
  putEditQuizProgressStep,
} from '../../../../services/api/quiz/quiz';
import { QuizProgressStepForm } from './QuizProgressStepForm';

type QuizEditProgressStepDialogProps = {
  quiz: Quiz;
  setIsRoadmapStepEditDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isRoadmapStepEditDialogOpen: boolean;
  roadmapStep?: QuizRoadmapStep;
};

export const QuizEditProgressStepDialog = ({
  quiz,
  setIsRoadmapStepEditDialogOpen,
  isRoadmapStepEditDialogOpen,
  roadmapStep,
}: QuizEditProgressStepDialogProps) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const formSchema = generateProgressStepZodSchema();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      value: `${roadmapStep?.value ?? ''}`,
      titleHtml: roadmapStep?.titleHtml ?? '',
      titleColor: roadmapStep?.titleColor ?? '',
      subTitleColor: roadmapStep?.subTitleColor ?? '',
      subTitleHtml: roadmapStep?.subTitleHtml ?? '',
    },
  });

  useEffect(() => {
    if (!isRoadmapStepEditDialogOpen) {
      form.reset();
    }
  }, [form, isRoadmapStepEditDialogOpen]);

  const putQuizProgressStepEditMutation = useMutation({
    mutationFn: putEditQuizProgressStep,
    onSuccess: async (data) => {
      if (data.success) {
        toast({
          title: 'Étape Modifié !',
          description: "L'étape à bien été modifié",
        });

        queryClient.invalidateQueries({ queryKey: ['quiz', quiz.id] });

        form.reset();
        setIsRoadmapStepEditDialogOpen(false);
      } else {
        toast({
          variant: 'destructive',
          title: "Oops ! Une erreur s'est produite.",
          description:
            "La modification de l'étape n'a pas été faite. Veuillez-réessayer.",
        });
      }
    },
  });

  const deleteQuizProgressStepMutation = useMutation({
    mutationFn: deleteQuizProgressStep,
    onSuccess: async (data) => {
      if (data.success) {
        toast({
          title: 'Étape Supprimé !',
          description: "L'étape à bien été supprimé",
        });

        queryClient.invalidateQueries({ queryKey: ['quiz', quiz.id] });

        form.reset();
        setIsRoadmapStepEditDialogOpen(false);
      } else {
        toast({
          variant: 'destructive',
          title: "Oops ! Une erreur s'est produite.",
          description:
            "La suppression de l'étape n'a pas été faite. Veuillez-réessayer.",
        });
      }
    },
  });

  const handleSubmit = async (data: z.infer<typeof formSchema>) => {
    if (quiz.id && roadmapStep) {
      putQuizProgressStepEditMutation.mutate({
        quizRoadmapId: quiz.roadmap.id,
        stepId: roadmapStep.id,
        value: +data.value,
        titleHtml: data.titleHtml,
        titleColor: data.titleColor,
        subTitleHtml: data.subTitleHtml,
        subTitleColor: data.subTitleColor,
      });
    }
  };

  const handleDelete = async () => {
    if (quiz.id && roadmapStep) {
      deleteQuizProgressStepMutation.mutate({
        quizRoadmapId: quiz.roadmap.id,
        stepId: roadmapStep.id,
      });
    }
  };

  return (
    <Dialog
      onOpenChange={setIsRoadmapStepEditDialogOpen}
      open={isRoadmapStepEditDialogOpen}
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Modifier une nouvelle étape</DialogTitle>
              <DialogDescription>
                Veuillez renseigner les informations relative à l'étape.
              </DialogDescription>
            </DialogHeader>

            <QuizProgressStepForm form={form} />

            <DialogFooter>
              <div className="flex w-full flex-row justify-between">
                <Button
                  type="button"
                  variant="destructive"
                  onClick={handleDelete}
                >
                  Supprimer
                </Button>
                <div className="space-x-2">
                  <Button
                    type="button"
                    onClick={() => form.reset()}
                    variant="outline"
                  >
                    Réinitialiser
                  </Button>
                  <Button
                    type="submit"
                    onClick={form.handleSubmit(handleSubmit)}
                    isLoading={putQuizProgressStepEditMutation.isPending}
                  >
                    Enregistrer
                  </Button>
                </div>
              </div>
            </DialogFooter>
          </DialogContent>
        </form>
      </Form>
    </Dialog>
  );
};

import { z } from 'zod';
import { useQuizContext } from '../../context/QuizContext';
import { useToast } from '../../../../shadcn/components/ui/use-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../shadcn/components/ui/form';
import { ColorPicker } from '../../../../components/forms/inputs/ColorPicker';
import { Button } from '../../../../shadcn/components/ui/button';
import { Input } from '../../../../shadcn/components/ui/input';
import { putAnsweredPageMessages } from '../../../../services/api/quiz/quiz';

const formSchema = z.object({
  correctAnswerTitle: z.string(),
  correctAnswerTitleColor: z.string(),
  correctAnswerSubTitle: z.string(),
  correctAnswerSubTitleColor: z.string(),
  incorrectAnswerTitle: z.string(),
  incorrectAnswerTitleColor: z.string(),
  incorrectAnswerSubTitle: z.string(),
  incorrectAnswerSubTitleColor: z.string(),
});

export const QuizResultAnswerMessages = () => {
  const { quiz } = useQuizContext();

  const { toast } = useToast();
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      correctAnswerTitle: quiz?.answeredPage?.correctAnswerTitle ?? '',
      correctAnswerTitleColor:
        quiz?.answeredPage?.correctAnswerTitleColor ?? '',
      incorrectAnswerTitle: quiz?.answeredPage?.incorrectAnswerTitle ?? '',
      incorrectAnswerTitleColor:
        quiz?.answeredPage?.incorrectAnswerTitleColor ?? '',
      correctAnswerSubTitle: quiz?.answeredPage?.correctAnswerSubTitle ?? '',
      correctAnswerSubTitleColor:
        quiz?.answeredPage?.correctAnswerSubTitleColor ?? '',
      incorrectAnswerSubTitle:
        quiz?.answeredPage?.incorrectAnswerSubTitle ?? '',
      incorrectAnswerSubTitleColor:
        quiz?.answeredPage?.incorrectAnswerSubTitleColor ?? '',
    },
  });

  const putQuizAnsweredPageMessagesMutation = useMutation({
    mutationFn: putAnsweredPageMessages,
    onSuccess: async (data) => {
      if (data.success) {
        toast({
          title: 'Messages enregistrés !',
          description:
            'Les changements sur les messages ont bien été enregistré',
        });

        await queryClient.invalidateQueries({ queryKey: ['quiz', quiz?.id] });

        const formValues = form.getValues();

        form.reset(formValues);
      } else {
        toast({
          variant: 'destructive',
          title: "Oops ! Une erreur s'est produite.",
          description:
            "Les changements sur les messages n'ont pas été enregistré. Veuillez-réessayer.",
        });
      }
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (quiz && quiz.answeredPage) {
      putQuizAnsweredPageMessagesMutation.mutate({
        quizAnsweredPageId: quiz.answeredPage.id,
        quizId: quiz.id,
        ...values,
      });
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="space-y-5">
          <h4 className="text-xl font-light leading-none tracking-tight text-bluePrimary">
            Messages de réponse
          </h4>
          <div className="grid grid-cols-2 gap-x-5 divide-x">
            <div className="flex flex-col space-y-6">
              <h5 className="text-l font-light leading-none tracking-tight text-bluePrimary">
                Bonne réponse
              </h5>
              <div className="space-y-12">
                <div className="space-y-3">
                  <FormField
                    control={form.control}
                    name="correctAnswerTitle"
                    render={({ field }) => (
                      <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                        <FormLabel className="col-span-2 flex items-center justify-start">
                          Titre
                        </FormLabel>
                        <FormControl className="col-span-6 col-start-3">
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="correctAnswerTitleColor"
                    render={({ field }) => (
                      <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                        <FormLabel className="col-span-2 flex items-center justify-start">
                          Couleur de texte
                        </FormLabel>
                        <FormControl className="col-span-6 text-right">
                          <ColorPicker {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="space-y-3">
                  <FormField
                    control={form.control}
                    name="correctAnswerSubTitle"
                    render={({ field }) => (
                      <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                        <FormLabel className="col-span-2 flex items-center justify-start">
                          Sous-titre
                        </FormLabel>
                        <FormControl className="col-span-6 col-start-3">
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="correctAnswerSubTitleColor"
                    render={({ field }) => (
                      <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                        <FormLabel className="col-span-2 flex items-center justify-start">
                          Couleur de texte
                        </FormLabel>
                        <FormControl className="col-span-6 text-right">
                          <ColorPicker {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-5">
              <h5 className="text-l ml-5 font-light leading-none tracking-tight text-bluePrimary">
                Mauvaise réponse
              </h5>
              <div className="ml-5 space-y-12">
                <div className="space-y-3">
                  <FormField
                    control={form.control}
                    name="incorrectAnswerTitle"
                    render={({ field }) => (
                      <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                        <FormLabel className="col-span-2 flex items-center justify-start">
                          Titre
                        </FormLabel>
                        <FormControl className="col-span-6 col-start-3">
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="incorrectAnswerTitleColor"
                    render={({ field }) => (
                      <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                        <FormLabel className="col-span-2 flex items-center justify-start">
                          Couleur de texte
                        </FormLabel>
                        <FormControl className="col-span-6 text-right">
                          <ColorPicker {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="space-y-3">
                  <FormField
                    control={form.control}
                    name="incorrectAnswerSubTitle"
                    render={({ field }) => (
                      <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                        <FormLabel className="col-span-2 flex items-center justify-start">
                          Sous-titre
                        </FormLabel>
                        <FormControl className="col-span-6 col-start-3">
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="incorrectAnswerSubTitleColor"
                    render={({ field }) => (
                      <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                        <FormLabel className="col-span-2 flex items-center justify-start">
                          Couleur de texte
                        </FormLabel>
                        <FormControl className="col-span-6 text-right">
                          <ColorPicker {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end space-x-4">
            {form.formState.isDirty && (
              <>
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => form.reset()}
                >
                  Réinitialiser
                </Button>

                <Button
                  type="submit"
                  isLoading={putQuizAnsweredPageMessagesMutation.isPending}
                >
                  Enregistrer
                </Button>
              </>
            )}
          </div>
        </div>
      </form>
    </Form>
  );
};

import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../forms/buttons/Button';
import Input from '../forms/inputs/Input';
import fonts from '../../styles/fonts.styles';
import colors from '../../styles/colors.styles';
import AddButton from '../forms/buttons/AddButton';
import { Checkbox } from 'rsuite';
import SVGButton from '../forms/buttons/SVGButton';
import MinusIcon from '../../assets/icons/minus.svg';
import File from '../forms/inputs/File';
import DownloadBlocIcon from '../../assets/icons/download-icon.svg';
import { uploadFile } from '../../services/api/api';

interface QuestionFormProps {
  question?: Question;
  onSave?: (question: Question) => void;
  onDelete?: (question: Question) => void;
  onCancelEditing?: () => void;
}

const QuestionFormDeprecated = ({
  question,
  onSave,
  onDelete,
  onCancelEditing,
}: QuestionFormProps) => {
  const [date, setDate] = useState<string>('');
  const [questionText, setQuestionText] = useState<string>('');
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [image, setImage] = useState<string | null>(null);
  const [imageUploaded, setImageUploaded] = useState<boolean>(false);
  const [imageUploading, setImageUploading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (question) {
      setDate(getDatetimeInputString(question.date));
      setQuestionText(question.question);
      setAnswers(question.answers);
      setImage(question.image);
      setImageUploaded(Boolean(question.image));
    }
  }, [question]);

  const getDatetimeInputString = (date: Date): string => {
    return date.toLocaleString('sv').replace(' ', 'T').replace(/:\d+$/g, '');
  };

  const addAnswer = () => {
    const newAnswer = {
      id: Date.now().toString(),
      text: '',
      isCorrect: false,
    };
    setAnswers((state) => [...state, newAnswer]);
  };

  const updateAnswer = (id: string, text?: string, isCorrect?: boolean) => {
    setAnswers((state) =>
      state.map((answer) => {
        if (answer.id === id) {
          return {
            ...answer,
            text: text ?? answer.text,
            isCorrect: isCorrect ?? answer.isCorrect,
          };
        }

        return answer;
      })
    );
  };

  const removeAnswer = (id: string) => {
    setAnswers((state) => state.filter((answer) => answer.id !== id));
  };

  const uploadImage = async (event: ChangeEvent<HTMLInputElement>) => {
    setImageUploading(true);

    const file = event.target.files?.length ? event.target.files[0] : null;
    if (!file) return;

    const path = await uploadFile(file);
    setImage(path);

    event.target.value = '';
    setImageUploading(false);
    setImageUploaded(true);
  };

  const validateForm = (): boolean => {
    if (date === null) {
      setError('Veuillez renseigner une date');
    } else if (questionText.length === 0) {
      setError('Veuillez renseigner une question');
    } else if (answers.length < 2) {
      setError('Veuillez renseigner au moins 2 réponses');
    } else if (answers.filter((answer) => answer.isCorrect).length === 0) {
      setError('Veuillez indiquer au moins une réponse comme correcte');
    } else if (answers.some((answer) => answer.text.length === 0)) {
      setError('Veuillez renseigner toutes les réponses');
    } else if (image === null) {
      setError('Veuillez ajouter une image');
    } else {
      setError(null);
      return true;
    }

    return false;
  };

  const resetForm = () => {
    setDate(getDatetimeInputString(new Date()));
    setQuestionText('');
    setAnswers([]);
    setImage(null);
  };

  const deleteQuestion = () => {
    onDelete && onDelete(question!);
    resetForm();
  };

  const saveQuestion = () => {
    if (validateForm()) {
      onSave &&
        onSave({
          id: question?.id ?? Date.now().toString(),
          date: new Date(date),
          question: questionText,
          answers,
          image: image!,
        });
      resetForm();
    }
  };

  return (
    <Wrapper>
      <Title>Nouvelle question</Title>
      <InputWrapper>
        <Input
          type="datetime-local"
          label="Date"
          value={date}
          onChange={(evt) => setDate(evt.currentTarget.value)}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          type="text"
          label="Question"
          value={questionText}
          onChange={(evt) => setQuestionText(evt.currentTarget.value)}
        />
      </InputWrapper>

      <SectionTitle>Réponses</SectionTitle>
      {answers.map((answer, index) => (
        <AnswerWrapper key={index}>
          <Checkbox
            checked={answer.isCorrect}
            onChange={(_, checked) =>
              updateAnswer(answer.id, undefined, checked)
            }
          />
          <AnswerNumber>{index + 1}</AnswerNumber>
          <Input
            type="text"
            value={answer.text}
            onChange={(evt) => updateAnswer(answer.id, evt.currentTarget.value)}
          />
          <SVGButton
            icon={MinusIcon}
            width={30}
            height={15}
            onClick={() => removeAnswer(answer.id)}
            style={{ cursor: 'pointer' }}
          />
        </AnswerWrapper>
      ))}
      <AddButtonContainer>
        <AddButton onClick={addAnswer} />
      </AddButtonContainer>

      <SectionTitle>Image</SectionTitle>
      <FileInputWrapper>
        <File
          uploaded={imageUploaded}
          uploading={imageUploading}
          src={image ?? DownloadBlocIcon}
          onChange={uploadImage}
          name="question-image"
        />
      </FileInputWrapper>

      <ControlsContainer>
        {error !== null && <Error>{error}</Error>}
        {question && (
          <>
            <Button
              buttonType="secondary"
              text="Annuler"
              onClick={() => {
                resetForm();
                onCancelEditing && onCancelEditing();
              }}
            />
            <Button
              buttonType="danger"
              text="Supprimer"
              onClick={deleteQuestion}
            />
          </>
        )}
        <Button
          buttonType="primary"
          text={question ? 'Mettre à jour' : 'Ajouter'}
          onClick={saveQuestion}
        />
      </ControlsContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 1em;
  border: 1px solid ${colors.grey.primary};
  margin-top: 1em;
  border-radius: 4px;
`;

const Title = styled.h1`
  font-family: ${fonts.WorkSans.regular};
  font-size: 14px;
  color: ${colors.blue.primary};
  line-height: initial;
  margin-bottom: 1em;
`;

const SectionTitle = styled.h2`
  font-family: ${fonts.WorkSans.light};
  font-size: 14px;
  color: ${colors.blue.primary};
  line-height: initial;
  margin-top: 1em;
`;

const InputWrapper = styled.div`
  margin-top: 1em;
`;

const AddButtonContainer = styled.div`
  margin-top: 5px;
`;

const FileInputWrapper = styled.div`
  margin-top: 1em;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1em;
  * {
    margin-left: 1em;
  }
`;

const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  align-items: center;
`;

const AnswerNumber = styled.div`
  font-family: ${fonts.WorkSans.regular};
  font-size: 14px;
  color: ${colors.grey.secondary};
  width: 2em;
  text-align: center;
`;

const Error = styled.div`
  font-family: ${fonts.WorkSans.regular};
  font-size: 14px;
  color: ${colors.mono.danger};
`;

export default QuestionFormDeprecated;

import { z } from 'zod';
import { useQuizContext } from '../../context/QuizContext';
import { useToast } from '../../../../shadcn/components/ui/use-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../shadcn/components/ui/form';
import { ColorPicker } from '../../../../components/forms/inputs/ColorPicker';
import { Button } from '../../../../shadcn/components/ui/button';
import { putProgressStyles } from '../../../../services/api/quiz/quiz';

const formSchema = z.object({
  containerBorderColor: z.string(),
  stepColor: z.string(),
  progressBarBackgroundColor: z.string(),
  progressBarColor: z.string(),
  stepAchievedColor: z.string(),
  stepDescriptionColor: z.string(),
});

export const QuizResultProgressStyle = () => {
  const { quiz } = useQuizContext();

  const { toast } = useToast();
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      containerBorderColor: quiz?.roadmap.containerBorderColor ?? '',
      stepColor: quiz?.roadmap.stepColor ?? '',
      progressBarBackgroundColor:
        quiz?.roadmap.progressBarBackgroundColor ?? '',
      progressBarColor: quiz?.roadmap.progressBarColor ?? '',
      stepAchievedColor: quiz?.roadmap.stepAchievedColor ?? '',
      stepDescriptionColor: quiz?.roadmap.stepDescriptionColor ?? '',
    },
  });

  const putQuizProgressStyleMutation = useMutation({
    mutationFn: putProgressStyles,
    onSuccess: async (data) => {
      if (data.success) {
        toast({
          title: 'Styles enregistré !',
          description: 'Les changements de style ont bien été enregistré',
        });

        queryClient.invalidateQueries({ queryKey: ['quiz', quiz?.id] });

        const formValues = form.getValues();

        form.reset(formValues);
      } else {
        toast({
          variant: 'destructive',
          title: "Oops ! Une erreur s'est produite.",
          description:
            "Les changements de style n'ont pas été enregistré. Veuillez-réessayer.",
        });
      }
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (quiz) {
      putQuizProgressStyleMutation.mutate({
        quizRoadmapId: quiz.roadmap.id,
        ...values,
      });
    }
  };

  return (
    <div className="space-y-5">
      <h4 className="text-xl font-light leading-none tracking-tight text-bluePrimary">
        Styles roadmap
      </h4>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-y-5">
            <FormField
              control={form.control}
              name="containerBorderColor"
              render={({ field }) => (
                <FormItem className="grid grid-cols-2 gap-x-4 space-y-0">
                  <FormLabel className="flex items-center justify-start">
                    Couleur de la bordure de conteneur
                  </FormLabel>
                  <FormControl className="flex items-center justify-start">
                    <ColorPicker {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="stepColor"
              render={({ field }) => (
                <FormItem className="grid grid-cols-2 gap-x-4 space-y-0">
                  <FormLabel className="flex items-center justify-start">
                    Couleur des étapes
                  </FormLabel>
                  <FormControl className="flex items-center justify-start">
                    <ColorPicker {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="progressBarBackgroundColor"
              render={({ field }) => (
                <FormItem className="grid grid-cols-2 gap-x-4 space-y-0">
                  <FormLabel className="flex items-center justify-start">
                    Couleur de fond de la barre de progrès
                  </FormLabel>
                  <FormControl className="flex items-center justify-start">
                    <ColorPicker {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="progressBarColor"
              render={({ field }) => (
                <FormItem className="grid grid-cols-2 gap-x-4 space-y-0">
                  <FormLabel className="flex items-center justify-start">
                    Couleur de la barre de progrès
                  </FormLabel>
                  <FormControl className="flex items-center justify-start">
                    <ColorPicker {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="stepAchievedColor"
              render={({ field }) => (
                <FormItem className="grid grid-cols-2 gap-x-4 space-y-0">
                  <FormLabel className="flex items-center justify-start">
                    Couleur des étapes ayant été complété
                  </FormLabel>
                  <FormControl className="flex items-center justify-start">
                    <ColorPicker {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="stepDescriptionColor"
              render={({ field }) => (
                <FormItem className="grid grid-cols-2 gap-x-4 space-y-0">
                  <FormLabel className="flex items-center justify-start">
                    Couleur du text descriptif des étapes
                  </FormLabel>
                  <FormControl className="flex items-center justify-start">
                    <ColorPicker {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="mt-10 flex justify-end space-x-4">
            {form.formState.isDirty && (
              <>
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => form.reset()}
                >
                  Réinitialiser
                </Button>

                <Button
                  type="submit"
                  isLoading={putQuizProgressStyleMutation.isPending}
                >
                  Enregistrer
                </Button>
              </>
            )}
          </div>
        </form>
      </Form>
    </div>
  );
};

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  getOrderConfirmationMediaAdSettings,
  updateOrderConfirmationAdSettings,
} from '../../services/settings/settings.service';
import colors from '../../styles/colors.styles';
import fonts from '../../styles/fonts.styles';
import MediaUploader from '../forms/options/sections/MediaUploader';
import { SettingsSection } from './SettingsSection';
import { EditableCTA } from './components/EditableCTA';
import { OrderConfirmationPreview } from './components/OrderConfirmationPreview';

type MOrderConfirmationAdSectionProps = {
  triggerSubmit: boolean;
};

export const OrderConfirmationAdSection = ({
  triggerSubmit,
}: MOrderConfirmationAdSectionProps) => {
  const [mediaAdImage, setMediaAdImage] = useState('');
  const [ctaBackgroundColor, setCtaBackgroundColor] = useState('#ffffff');
  const [ctaTextColor, setCtaTextColor] = useState('#00000');
  const [ctaText, setCtaText] = useState('');
  const [adActive, setAdActive] = useState(false);
  const [adLink, setAdLink] = useState('');

  // Fetches the settings when the component mounts
  useEffect(() => {
    getOrderConfirmationMediaAdSettings().then((data) => {
      setMediaAdImage(data.adImageLink ?? '');
      setCtaBackgroundColor(data.ctaBackgroundColor ?? '#ffffff');
      setCtaTextColor(data.ctaTextColor ?? '#00000');
      setCtaText(data.ctaText ?? '');
      setAdActive(data.adActive ?? false);
      setAdLink(data.adLink ?? '');
    });
  }, []);

  // Updates the settings when the triggerSubmit prop is true
  useEffect(() => {
    const submitSettings = async () => {
      await updateOrderConfirmationAdSettings({
        ctaBackgroundColor,
        ctaTextColor,
        ctaText,
        adImageLink: mediaAdImage,
        adActive,
        adLink,
      });
    };

    if (triggerSubmit) {
      submitSettings();
    }
  }, [
    triggerSubmit,
    ctaBackgroundColor,
    ctaTextColor,
    ctaText,
    mediaAdImage,
    adActive,
    adLink,
  ]);

  const resetValues = () => {
    setMediaAdImage('');
    setCtaBackgroundColor('#ffffff');
    setCtaTextColor('#00000');
    setCtaText('');
    setAdActive(false);
    setAdLink('');
  };

  return (
    <SettingsSection
      title="Publicité confirmation de commande"
      description="Paramètres de la publicité affichée après la confirmation de
    commande (taille de l'image attendue 149x382px)."
    >
      <SectionContentContainer>
        <SectionSettingsContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <MediaUploader
              title="Image de la publicité"
              imageFieldName="orderConfirmationUrl"
              imageUrl={''}
              onSetImage={setMediaAdImage}
              onDeleteImage={() => setMediaAdImage('')}
            />
            <SectionSettingsCtaTextContainer>
              <SectionSubtitle>Texte du CTA</SectionSubtitle>
              <SectionSettingsCtaTextInput
                value={ctaText}
                onChange={(e) => setCtaText(e.target.value)}
              />
              <SectionSubtitle>Lien de redirection</SectionSubtitle>
              <SectionSettingsCtaTextInput
                value={adLink}
                onChange={(e) => setAdLink(e.target.value)}
              />
            </SectionSettingsCtaTextContainer>
          </div>
          <EditableCTA
            title="Selectionnez les couleurs du CTA"
            colors={[
              {
                color: ctaBackgroundColor,
                setColor: setCtaBackgroundColor,
                label: 'Couleur de fond du CTA',
              },
              {
                color: ctaTextColor,
                setColor: setCtaTextColor,
                label: 'Couleur du texte du CTA',
              },
            ]}
          />
        </SectionSettingsContainer>
        <OrderConfirmationPreview
          ctaBackgroundColor={ctaBackgroundColor}
          ctaText={ctaText}
          ctaTextColor={ctaTextColor}
          image={{
            link: mediaAdImage,
            width: 382,
            height: 149,
          }}
          isActive={adActive}
          onReset={resetValues}
          onToggleActive={() => setAdActive(!adActive)}
          title={<SectionSubtitle>Aperçu du rendu.</SectionSubtitle>}
        />
      </SectionContentContainer>
    </SettingsSection>
  );
};

const SectionSubtitle = styled.p`
  font-family: ${fonts.WorkSans.semiBold};
  font-size: 12px;
  color: ${colors.blue.primary};
  padding: 10px;
`;

const SectionContentContainer = styled.div`
  display: flex;
`;

const SectionSettingsContainer = styled.div`
  border-right: 1px solid #002961;
  flex: 1;
`;

const SectionSettingsCtaTextContainer = styled.div`
  border-left-width: 1px;
  border-left-color: black;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SectionSettingsCtaTextInput = styled.input`
  border-width: 1px;
  border-radius: 7px;
  width: 80%;
  background-color: ${colors.beige.primary};
  font-family: ${fonts.WorkSans.regular};
  color: ${colors.blue.primary};
  font-size: 13px;
  padding: 5px;
  margin-bottom: 10px;
`;

import React, { forwardRef } from 'react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../../shadcn/components/ui/popover';
import { Button } from '../../../shadcn/components/ui/button';
import { CalendarIcon } from 'lucide-react';
import { format } from 'date-fns';
import { cn } from '../../../shadcn/utils';
import { Calendar } from '../../../shadcn/components/ui/calendar';
import { Label } from '../../../shadcn/components/ui/label';
import { Input } from '../../../shadcn/components/ui/input';

type DatePickerProps = {
  value: Date | undefined;
  onChange: (...event: any[]) => void;
  onBlur: () => void;
  disabled?: boolean;
  name: string;
  ref: (instance: HTMLButtonElement | null) => void;
  buttonClassName?: string;
};

export const DatePicker = forwardRef<HTMLButtonElement, DatePickerProps>(
  ({ value, onChange, onBlur, disabled, name, buttonClassName }, ref) => {
    const [date, setDate] = React.useState<Date | undefined>(value);
    const [time, setTime] = React.useState<string>(
      value ? format(value, 'HH:mm') : ''
    ); // state for time in HH:MM format

    // Function to combine date and time
    const combineDateTime = (date: Date | undefined, time: string) => {
      if (!date || !time) return date;

      const [hours, minutes] = time.split(':').map(Number);
      const combinedDate = new Date(date);
      combinedDate.setHours(hours);
      combinedDate.setMinutes(minutes);

      return combinedDate;
    };

    // Handle time change
    const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newTime = event.target.value;
      setTime(newTime);
      const combinedDate = combineDateTime(date, newTime);
      setDate(combinedDate);
      onChange(combinedDate);
    };

    // Handle date change
    const handleDateChange = (selectedDate: Date | undefined) => {
      const combinedDate = combineDateTime(selectedDate, time);
      setDate(combinedDate);
      onChange(combinedDate);
    };

    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={'outline'}
            type="button"
            className={cn(
              'justify-start text-left text-sm font-normal',
              !date && 'text-muted-foreground',
              buttonClassName
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date ? format(date, 'PPp') : <span>Choisissez une date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <div className="grid gap-4 px-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="time" className="text-right">
                Heure
              </Label>
              <Input
                className="col-span-3"
                type="time"
                value={time}
                onChange={handleTimeChange}
                onBlur={onBlur}
                disabled={disabled}
              />
            </div>
          </div>

          <Calendar mode="single" selected={date} onSelect={handleDateChange} />
        </PopoverContent>
      </Popover>
    );
  }
);

DatePicker.displayName = 'DatePicker';

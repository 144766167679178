import axios, { AxiosResponse } from 'axios';
import {
  QUIZ_GENERAL_INFORMATIONS_ROUTE,
  QUIZ_LIST_ROUTE,
  QUIZ_PROGRESS_ROUTE,
  QUIZ_PROGRESS_STYLES_ROUTES,
  QUIZ_PROGRESS_STEP_ROUTE,
  QUIZ_QUESTION_COLORS_ROUTE,
  QUIZ_QUESTION_ORDER_ROUTE,
  QUIZ_QUESTION_ROLLOVER_TIME_ROUTE,
  QUIZ_QUESTION_ROUTE,
  QUIZ_ROUTE,
  QUIZ_ANSWERED_PAGE_ROUTE,
} from './constants';
import {
  QuizResponseDTO,
  CreateQuizDTO,
  QuizInListDTO,
  PutQuizGeneralInformationDTO,
  QuizUpdateResponseDTO,
  PutQuizQuestionColorsDTO,
  PutQuizQuestionRolloverTimeDTO,
  PostQuizQuestionCreationDTO,
  QuizSuccessDTO,
  DeleteQuizQuestionDTO,
  UpdateQuizQuestionDTO,
  PutQuizQuestionOrderDTO,
  PostCreateProgressStepDTO,
  PutEditProgressStepDTO,
  DeleteProgressStepDTO,
  PutProgressStylesDTO,
  PutProgressTextsDTO,
  PutAnsweredPageCtaDTO,
  PutAnsweredPageCorrectIncorrectMessagesDTO,
  PutAnsweredPageGeneralStyleDTO,
  QuizQuestionResponseDTO,
  PostQuizQuestionWinnerSelectionDTO,
  PostQuizQuestionWinnerSelectionResponseDTO,
} from './types';

export const getQuizList = async () => {
  const { data } =
    await axios.get<AxiosResponse<QuizInListDTO[]>>(QUIZ_LIST_ROUTE);

  return data.data;
};

export const postNewQuiz = async (quizData: CreateQuizDTO) => {
  const { data } = await axios.post<AxiosResponse<string>>(
    QUIZ_ROUTE,
    quizData
  );

  return data.data;
};

export const deleteQuiz = async (id: number) => {
  const { data } = await axios.delete<AxiosResponse<number>>(
    `${QUIZ_ROUTE}/${id}`
  );

  return data.data;
};

export const getQuiz = async (id: string) => {
  const { data } = await axios.get<AxiosResponse<QuizResponseDTO>>(
    `${QUIZ_ROUTE}/${id}`
  );

  return data.data;
};

export const putQuizGeneralInformation = async (
  updateQuizDto: PutQuizGeneralInformationDTO
) => {
  const { data } = await axios.put<AxiosResponse<QuizUpdateResponseDTO>>(
    `${QUIZ_GENERAL_INFORMATIONS_ROUTE}`,
    updateQuizDto
  );

  return data.data;
};

export const putQuizQuestionColors = async (
  quizQuestionColorsDto: PutQuizQuestionColorsDTO
) => {
  const { data } = await axios.put<AxiosResponse<QuizUpdateResponseDTO>>(
    `${QUIZ_QUESTION_COLORS_ROUTE}`,
    quizQuestionColorsDto
  );

  return data.data;
};

export const putQuizQuestionRolloverTime = async (
  quizQuestionRolloverTimeDto: PutQuizQuestionRolloverTimeDTO
) => {
  const { data } = await axios.put<AxiosResponse<QuizUpdateResponseDTO>>(
    `${QUIZ_QUESTION_ROLLOVER_TIME_ROUTE}`,
    quizQuestionRolloverTimeDto
  );

  return data.data;
};

export const postQuizQuestion = async (
  quizQuestionDto: PostQuizQuestionCreationDTO
) => {
  const { data } = await axios.post<AxiosResponse<QuizSuccessDTO>>(
    `${QUIZ_QUESTION_ROUTE}`,
    quizQuestionDto
  );

  return data.data;
};

export const deleteQuizQuestion = async (
  deleteQuizQuestionDto: DeleteQuizQuestionDTO
) => {
  const { data } = await axios.delete<AxiosResponse<QuizSuccessDTO>>(
    `${QUIZ_ROUTE}/${deleteQuizQuestionDto.quizId}/${deleteQuizQuestionDto.questionId}`
  );

  return data.data;
};

export const putQuizQuestion = async (
  putQuizQuestionDto: UpdateQuizQuestionDTO
) => {
  const { data } = await axios.put<AxiosResponse<QuizSuccessDTO>>(
    `${QUIZ_QUESTION_ROUTE}`,
    putQuizQuestionDto
  );

  return data.data;
};

export const putQuizQuestionOrder = async (
  putQuizQuestionOrderDto: PutQuizQuestionOrderDTO
) => {
  const { data } = await axios.put<AxiosResponse<QuizSuccessDTO>>(
    `${QUIZ_QUESTION_ORDER_ROUTE}`,
    putQuizQuestionOrderDto
  );

  return data.data;
};

export const postCreateQuizProgressStep = async (
  postCreateProgressStepDTO: PostCreateProgressStepDTO
) => {
  const { data } = await axios.post<AxiosResponse<QuizSuccessDTO>>(
    QUIZ_PROGRESS_STEP_ROUTE,
    postCreateProgressStepDTO
  );

  return data.data;
};

export const putEditQuizProgressStep = async (
  putEditProgressStepDTO: PutEditProgressStepDTO
) => {
  const { data } = await axios.put<AxiosResponse<QuizSuccessDTO>>(
    QUIZ_PROGRESS_STEP_ROUTE,
    putEditProgressStepDTO
  );

  return data.data;
};

export const deleteQuizProgressStep = async (
  deleteProgressStepDTO: DeleteProgressStepDTO
) => {
  const { data } = await axios.delete<AxiosResponse<QuizSuccessDTO>>(
    `${QUIZ_PROGRESS_ROUTE}/${deleteProgressStepDTO.quizRoadmapId}/${deleteProgressStepDTO.stepId}`
  );

  return data.data;
};

export const putProgressStyles = async (
  putProgressStylesDto: PutProgressStylesDTO
) => {
  const { data } = await axios.put<AxiosResponse<QuizSuccessDTO>>(
    `${QUIZ_PROGRESS_STYLES_ROUTES}`,
    putProgressStylesDto
  );

  return data.data;
};

export const putProgressTexts = async (
  putProgressTextsDto: PutProgressTextsDTO
) => {
  const { data } = await axios.put<AxiosResponse<QuizSuccessDTO>>(
    `${QUIZ_PROGRESS_ROUTE}`,
    putProgressTextsDto
  );

  return data.data;
};

export const putAnsweredPageCTA = async (
  putAnsweredPageCtaDto: PutAnsweredPageCtaDTO
) => {
  const { data } = await axios.put<AxiosResponse<QuizSuccessDTO>>(
    `${QUIZ_ANSWERED_PAGE_ROUTE}/cta`,
    putAnsweredPageCtaDto
  );

  return data.data;
};

export const putAnsweredPageMessages = async (
  putAnsweredPageCorrectIncorrectMessagesDto: PutAnsweredPageCorrectIncorrectMessagesDTO
) => {
  const { data } = await axios.put<AxiosResponse<QuizSuccessDTO>>(
    `${QUIZ_ANSWERED_PAGE_ROUTE}/messages`,
    putAnsweredPageCorrectIncorrectMessagesDto
  );

  return data.data;
};

export const putAnsweredPageGeneralStyle = async (
  putAnsweredPageGeneralStyleDto: PutAnsweredPageGeneralStyleDTO
) => {
  const { data } = await axios.put<AxiosResponse<QuizSuccessDTO>>(
    `${QUIZ_ANSWERED_PAGE_ROUTE}/style`,
    putAnsweredPageGeneralStyleDto
  );

  return data.data;
};

export const postQuizQuestionWinnerSelection = async (
  postQuizQuestionWinnerSelectionDto: PostQuizQuestionWinnerSelectionDTO
) => {
  const { data } = await axios.post<
    AxiosResponse<PostQuizQuestionWinnerSelectionResponseDTO>
  >(`${QUIZ_QUESTION_ROUTE}/select-winner`, postQuizQuestionWinnerSelectionDto);

  return data.data;
};

import React from "react"
import styled from "styled-components"
import colors from "../../../../styles/colors.styles"
import fonts from "../../../../styles/fonts.styles"

interface TitleProps {
  children: string
}

const Title: React.FC<TitleProps> = ({ children }) => {
  return <Container>{children}</Container>
}

export default Title

const Container = styled.div`
  margin: 15px 0 15px 0;
  font-family: ${fonts.WorkSans.semiBold};
  font-size: 12px;
  color: ${colors.blue.primary};
`

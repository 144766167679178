import axios from "axios";

const ROUTE_ROOT = "travels";

export async function getTravelsConfig(): Promise<any> {
  const response = await axios.get(`${ROUTE_ROOT}`);
  return response.data.data;
}

export async function updateTravelsConfig(
  jsonData: any
): Promise<any> {
  const response = await axios.post(`${ROUTE_ROOT}`, {
    jsonData
  });
  return response.data.data;
}

import React from 'react';
import styled from 'styled-components';
import DataType from './blocs/DataType';
import Category from './sections/Category';
import Collection from './sections/Collection';
import Discount from './sections/Discount';
import ExpectedDelivery from './sections/ExpectedDelivery';
import Product from './sections/Product';
import DisplayDate from './sections/DisplayDate';
import Webpage from './sections/Webpage';
import UpComingSaleDetails from './sections/UpComingSaleDetails';
import usePreview from '../../../contexts/PreviewContext';
import OptionsAccordion from './OptionsAccordion';
import { Filler } from './sections/OptionsFiller';
import ProductVendor from './sections/ProductVendor';
import MediaUploader from './sections/MediaUploader';
import CarouselStory from './sections/CarouselStory';
import ExpectedDeliveryDate from './sections/ExpectedDeliveryData';
import { DeveloperSection } from './sections/DeveloperSection';

interface SubOptionsSectionProps {
  blockType: string | undefined;
  selectedItem: number;
  selectedTab: number;
}

const SubOptionsSection: React.FC<SubOptionsSectionProps> = ({
  blockType,
  selectedTab,
  selectedItem,
}) => {
  const preview = usePreview();

  const previewObject = preview.getSelectedItem(selectedItem);
  const image = previewObject.content.items[0]?.image;

  return (
    <Container>
      <OptionsAccordion
        title={
          blockType === 'upComingSale'
            ? 'PAGE VENTE A VENIR'
            : 'PAGE VENTE EN COURS'
        }
        defaultState={true}
      >
        {(blockType === 'normal' || blockType === 'upComingSale') && (
          <>
            <MediaHorizontalVerticalContainer>
              <MediaUploader
                title={'Horizontal'}
                imageFieldName="defaultUrl"
                imageUrl={image?.defaultUrl ?? ''}
                onSetImage={(path) =>
                  preview.setPreviewItemTabImageField(
                    selectedItem,
                    selectedTab,
                    'image',
                    'defaultUrl',
                    path
                  )
                }
                onDeleteImage={() =>
                  preview.deleteImage(
                    selectedItem,
                    selectedTab,
                    'image',
                    'defaultUrl'
                  )
                }
              />
              <MediaUploader
                title={'Vertical'}
                imageFieldName="verticalUrl"
                imageUrl={image?.verticalUrl ?? ''}
                onSetImage={(path) =>
                  preview.setPreviewItemTabImageField(
                    selectedItem,
                    selectedTab,
                    'image',
                    'verticalUrl',
                    path
                  )
                }
                onDeleteImage={() =>
                  preview.deleteImage(
                    selectedItem,
                    selectedTab,
                    'image',
                    'verticalUrl'
                  )
                }
              />
            </MediaHorizontalVerticalContainer>
            <Filler />
          </>
        )}
        <DataType
          categories={
            blockType === 'normal'
              ? ['product', 'collection', 'static', 'webpage']
              : blockType === 'carousel'
                ? ['product', 'static', 'webpage']
                : null
          }
          selectedTab={selectedTab}
          selectedItem={selectedItem}
        />
        <Filler />
        {preview.getDataType(selectedItem, selectedTab) === 'product' && (
          <>
            <Product selectedTab={selectedTab} selectedItem={selectedItem} />
            <Filler />
            <DisplayDate
              selectedTab={selectedTab}
              selectedItem={selectedItem}
              title="Dates de l’affichage"
            />
          </>
        )}
        {preview.getDataType(selectedItem, selectedTab) === 'collection' && (
          <>
            <Collection selectedTab={selectedTab} selectedItem={selectedItem} />
            <Filler />
            <Category selectedTab={selectedTab} selectedItem={selectedItem} />
            <Filler />
            <DisplayDate
              selectedTab={selectedTab}
              selectedItem={selectedItem}
              title="Dates de l’affichage"
            />
            <Filler />
            <Discount selectedTab={selectedTab} selectedItem={selectedItem} />
          </>
        )}
        {preview.getDataType(selectedItem, selectedTab) === 'webpage' && (
          <>
            <Webpage selectedTab={selectedTab} selectedItem={selectedItem} />
            <Filler />
            <Category selectedTab={selectedTab} selectedItem={selectedItem} />
            <Filler />
            <DisplayDate
              selectedTab={selectedTab}
              selectedItem={selectedItem}
              title="Dates de l’affichage"
            />
            <Filler />
            <Discount selectedTab={selectedTab} selectedItem={selectedItem} />
          </>
        )}
        {preview.getDataType(selectedItem, selectedTab) === 'static' && (
          <DisplayDate
            selectedTab={selectedTab}
            selectedItem={selectedItem}
            title="Dates de l’affichage"
          />
        )}
        {blockType === 'upComingSale' && (
          <>
            <ProductVendor
              selectedTab={selectedTab}
              selectedItem={selectedItem}
            />
            <Filler />
            <DisplayDate
              selectedTab={selectedTab}
              selectedItem={selectedItem}
              title="Dates de la vente"
            />
            <Filler />
            <ExpectedDeliveryDate
              selectedTab={selectedTab}
              selectedItem={selectedItem}
            />
            <Filler />
            <UpComingSaleDetails
              selectedTab={selectedTab}
              selectedItem={selectedItem}
            />
          </>
        )}
        <Filler />
        <DeveloperSection
          selectedTab={selectedTab}
          selectedItem={selectedItem}
        />
        <Filler />
      </OptionsAccordion>
      {preview.getDataType(selectedItem, selectedTab) === 'collection' && (
        <OptionsAccordion title={'PAGE COLLECTION'}>
          <ExpectedDelivery
            selectedTab={selectedTab}
            selectedItem={selectedItem}
          />
          <Filler />
          <MediaUploader
            title={'Image page collection'}
            imageFieldName="collectionImage"
            imageUrl={
              preview.getSelectedItem(selectedItem)?.content?.items[0]
                ?.collectionImage?.url ?? ''
            }
            onSetImage={(path) =>
              preview.setPreviewItemTabImageField(
                selectedItem,
                selectedTab,
                'collectionImage',
                'url',
                path
              )
            }
            onDeleteImage={() =>
              preview.deleteImage(
                selectedItem,
                selectedTab,
                'collectionImage',
                'url'
              )
            }
          />
          <Filler />
          <CarouselStory
            selectedItem={selectedItem}
            selectedTab={selectedTab}
          />
          <Filler />
        </OptionsAccordion>
      )}
    </Container>
  );
};

export default SubOptionsSection;

const Container = styled.div`
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  box-sizing: border-box;
`;

const MediaHorizontalVerticalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

import { useQuery } from '@apollo/client';
import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { getProducts, ProductsData } from '../../../../graphql/queries';
import Title from '../miscellaneous/Title';
import makeAnimated from 'react-select/animated';
import { reactSelectStyles } from '../../../../styles/reactSelect.styles';
import AsyncSelect from 'react-select/async';
import usePreview from '../../../../contexts/PreviewContext';
import { GroupBase, OptionsOrGroups } from 'react-select';

interface SelectProductProps extends InputHTMLAttributes<HTMLInputElement> {
  selectedItem: number;
  selectedTab: number;
}

const SelectProduct: React.FC<SelectProductProps> = ({
  selectedItem,
  selectedTab,
}) => {
  const preview = usePreview();

  const animatedComponents = makeAnimated();

  const { data, fetchMore } = useQuery<ProductsData>(getProducts, {
    fetchPolicy: 'cache-and-network',
    variables: {
      offset: 0,
      limit: 50,
      query:
        preview.getSelectedItemTab(selectedItem, selectedTab).product || '',
    },
  });
  const handleChange = (event: any) => {
    preview.setPreviewItemTabField(
      selectedItem,
      selectedTab,
      'product',
      event.label
    );
    preview.setPreviewItemTabField(
      selectedItem,
      selectedTab,
      'id_shopify',
      event.value
    );
  };

  const loadOptions = async (
    inputValue: string,
    callback: (
      options: OptionsOrGroups<
        { label: string; value: string },
        GroupBase<{ label: string; value: string }>
      >
    ) => void
  ): Promise<
    OptionsOrGroups<
      { label: string; value: string },
      GroupBase<{ label: string; value: string }>
    >
  > => {
    const result = await fetchMore({
      variables: {
        offset: 0,
        limit: 50,
        query: inputValue || '',
      },
    });

    const options = result.data.products.edges.map((product) => {
      return {
        value: product.node.id,
        label: product.node.title,
      };
    });

    callback(options);
    return options;
  };

  return (
    <Container>
      <Title>Écrire & sélectionner le nom du produit</Title>
      <AsyncSelect
        components={animatedComponents}
        defaultOptions={(data?.products.edges || []).map((product) => {
          return {
            value: product.node.id,
            label: product.node.title,
          };
        })}
        options={(data?.products.edges || []).map((product) => {
          return {
            value: product.node.id,
            label: product.node.title,
          };
        })}
        value={{
          label: preview.getSelectedItemTab(selectedItem, selectedTab).product,
          value: preview.getSelectedItemTab(selectedItem, selectedTab)
            .id_shopify,
        }}
        defaultValue={{
          label: preview.getSelectedItemTab(selectedItem, selectedTab).product,
          value: preview.getSelectedItemTab(selectedItem, selectedTab)
            .id_shopify,
        }}
        loadOptions={loadOptions}
        styles={reactSelectStyles}
        onChange={handleChange}
      />
    </Container>
  );
};

export default SelectProduct;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`;

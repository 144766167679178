import React from "react";
import styled from "styled-components";
import colors from "../styles/colors.styles";

const Header: React.FC = () => {
	return (
		<Container>
			<TheBraderyLogo
				src="/assets/images/theBraderyLogo.svg"
				alt="The Bradery's logo"
			/>
		</Container>
	);
};

export default Header;

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${colors.beige.primary};
`;

const TheBraderyLogo = styled.img`
	height: 40px;
	width: auto;
`;

import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import ToggleGroupItem from "./ToggleGroupItem.button";

interface ToggleGroupProps extends HTMLAttributes<HTMLDivElement> {
	values: string[];
	selectedValue: string;
	onSelectedValueChange: (newSelectedValue: string) => void;
}

const ToggleGroup: React.FC<ToggleGroupProps> = ({
	values,
	selectedValue,
	style,
	onSelectedValueChange,
}) => {
	return (
		<Container style={style}>
			{values.map((x) => {
				return (
					<ToggleGroupItem
						key={x}
						value={x}
						isSelected={x === selectedValue}
						onSelectedValueChange={() => onSelectedValueChange(x)}
					/>
				);
			})}
		</Container>
	);
};

export default ToggleGroup;

const Container = styled.div`
	display: flex;
`;

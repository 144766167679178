import styled from 'styled-components';
import colors from '../../../styles/colors.styles';

type NewPopupConfirmationModalProps = {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
};

export const NewPopupConfirmationModal = ({
  setIsOpen,
  isOpen,
  onConfirm,
  onCancel,
}: NewPopupConfirmationModalProps) => {
  if (!isOpen) return null;

  const handleClick = (value: boolean) => {
    setIsOpen(false);
    if (value) {
      onConfirm();
    } else {
      onCancel?.();
    }
  };

  return (
    <ModalBackground>
      <ModalContent>
        <ModalText>
          Définir une nouvelle popup en affichera une nouvelle pour TOUS les
          utilisateurs, êtes vous sur ? Les actions seront prises en compte
          seulement qaund vous aurez cliqué sur "Sauvegarder" au bas de la page.
        </ModalText>
        <ButtonContainer>
          <YesButton onClick={() => handleClick(true)}>OUI</YesButton>
          <NoButton onClick={() => handleClick(false)}>NON</NoButton>
        </ButtonContainer>
      </ModalContent>
    </ModalBackground>
  );
};

const ModalBackground = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  row-gap: 20px;
  background-color: ${colors.beige.primary};
  width: 30%;
  padding: 20px;
  border-radius: 5px;
`;

const ModalText = styled.h4`
  line-height: 20px;
  color: ${colors.blue.primary};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const YesButton = styled.button`
  padding: 10px 40px;
  background-color: green;
  color: white;
  border-radius: 5px;
`;

const NoButton = styled.button`
  padding: 10px 40px;
  background-color: red;
  color: white;
  border-radius: 5px;
`;

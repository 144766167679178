import colors from "./colors.styles"

export const reactSelectStyles: any = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: colors.beige.primary,
    width: 262,
  }),
  multiValue: (styles: any, { data }: any) => {
    return {
      ...styles,
      backgroundColor: colors.blue.tertiary,
    }
  },
  multiValueLabel: (styles: any, { data }: any) => ({
    ...styles,
    color: colors.blue.primary,
  }),
  multiValueRemove: (styles: any, { data }: any) => ({
    ...styles,
    ":hover": {
      color: colors.orange.primary,
    },
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: colors.beige.primary,
    color: colors.blue.primary,
  }),
}

import React from 'react';
import colors from '../../styles/colors.styles';
import styled from 'styled-components';
import fonts from '../../styles/fonts.styles';

type SettingsSectionProps = {
  title: string;
  description: string;
  headerExtra?: React.ReactNode;
};

export const SettingsSection = (
  props: React.PropsWithChildren<SettingsSectionProps>
) => {
  return (
    <Section>
      <SectionHeader>
        <SectionTitles>
          <SectionTitle>{props.title}</SectionTitle>
          <SectionInfo>{props.description}</SectionInfo>
        </SectionTitles>
        {props.headerExtra}
      </SectionHeader>
      {props.children}
    </Section>
  );
};

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SectionTitles = styled.div``;

const Section = styled.section`
  margin-top: 20px;
  background-color: ${colors.beige.primary};
  border: 2px solid ${colors.beige.tertiary};
  border-radius: 8px;
  padding: 15px;
`;

const SectionTitle = styled.h1`
  font-family: ${fonts.WorkSans.regular};
  color: ${colors.blue.secondary};
  font-size: 15px;
  line-height: 15px;
  margin: 5px 0 15px;
`;

const SectionInfo = styled.p`
  font-family: ${fonts.WorkSans.regular};
  color: ${colors.blue.primary};
  font-size: 13px;
  margin: 5px 0;
`;

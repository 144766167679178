import { QuizResponseDTO } from '../../../services/api/quiz/types';
import { isISODateString } from '../../../utils/typguards/isISODateString';
import { Quiz } from '../types';
import { minutesToTimeString } from './minutesToTimeString';

export function parseQuizDTO(fullQuizDTO: QuizResponseDTO): Quiz {
  const quiz: Quiz = {
    id: fullQuizDTO.id || '',
    startDate: isISODateString(fullQuizDTO.startDate)
      ? new Date(fullQuizDTO.startDate)
      : new Date(),
    endDate: isISODateString(fullQuizDTO.endDate)
      ? new Date(fullQuizDTO.endDate)
      : new Date(),
    title: fullQuizDTO.title,
    image: fullQuizDTO.quizImage || {},
    answeredPage: fullQuizDTO.quizAnsweredPage,
    minimizeQuizButtonColor: fullQuizDTO.minimizeButtonColor,
    maximizeQuizButtonColor: fullQuizDTO.maximizeButtonColor,
    maximizeQuizButtonLogoUrl: fullQuizDTO.maximizeButtonLogoUrl,
    roadmap: {
      id: fullQuizDTO.quizAnsweredPage?.quizRoadmap?.id ?? '',
      descriptionHtmlText:
        fullQuizDTO.quizAnsweredPage?.quizRoadmap?.descriptionHtmlText,
      descriptionHtmlTextColor:
        fullQuizDTO.quizAnsweredPage?.quizRoadmap?.descriptionHtmlTextColor,
      steps: fullQuizDTO.quizAnsweredPage?.quizRoadmap?.steps.map((step) => ({
        id: step.id,
        value: step.value,
        titleHtml: step.titleHtml,
        titleColor: step.titleColor,
        subTitleHtml: step.subTitleHtml,
        subTitleColor: step.subTitleColor,
      })),
      containerBorderColor:
        fullQuizDTO.quizAnsweredPage?.quizRoadmap?.containerBorderColor,
      stepColor: fullQuizDTO.quizAnsweredPage?.quizRoadmap?.stepColor,
      progressBarBackgroundColor:
        fullQuizDTO.quizAnsweredPage?.quizRoadmap?.progressBarBackgroundColor,
      progressBarColor:
        fullQuizDTO.quizAnsweredPage?.quizRoadmap?.progressBarColor,
      stepAchievedColor:
        fullQuizDTO.quizAnsweredPage?.quizRoadmap?.stepAchievedColor,
      stepDescriptionColor:
        fullQuizDTO.quizAnsweredPage?.quizRoadmap?.stepDescriptionColor,
    },
    questions: fullQuizDTO.quizQuestions.map((question) => ({
      id: question.id,
      htmlQuestionText: question.htmlQuestionText,
      index: question.index,
      imageUrl: question.imageUrl,
      logoUrl: question.logoUrl,
      leftCornerLogoUrl: question.leftCornerLogoUrl,
      winnerShopifyUserId: question.winnerShopifyUserId,
      _count: question._count,
      choices: question.quizQuestionChoices.map((choice) => ({
        id: choice.id,
        text: choice.htmlChoiceText,
        index: choice.index,
        isCorrectAnswer: choice.isCorrectAnswer,
      })),
    })),
    isActive: fullQuizDTO.isActive,
    questionCorrectColor: fullQuizDTO.questionCorrectColor,
    questionIncorrectColor: fullQuizDTO.questionIncorrectColor,
    questionRolloverTime: fullQuizDTO.questionRolloverTime
      ? minutesToTimeString(fullQuizDTO.questionRolloverTime)
      : '',
  };
  return quiz;
}

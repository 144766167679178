import { GripVertical, Pencil, Trophy } from 'lucide-react';
import { Button } from '../../../../shadcn/components/ui/button';
import { TableCell, TableRow } from '../../../../shadcn/components/ui/table';
import { QuizQuestion } from '../../types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type QuizQuestionListRowProps = {
  question: QuizQuestion;
  setSelectedQuestion: React.Dispatch<
    React.SetStateAction<QuizQuestion | undefined>
  >;
  setIsQuestionEditDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsQuestionWinnerDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
};

export const QuizQuestionListRow = ({
  question,
  setIsQuestionEditDialogOpen,
  setIsQuestionWinnerDialogOpen,
  setSelectedQuestion,
  index,
}: QuizQuestionListRowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: question.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <TableRow ref={setNodeRef} style={style}>
      <TableCell
        className="text-left"
        ref={setActivatorNodeRef}
        {...listeners}
        {...attributes}
      >
        <div>
          <GripVertical size={18} className="text-greySecondary" />
        </div>
      </TableCell>
      <TableCell className="font-medium">{index + 1}</TableCell>
      <TableCell>{question.htmlQuestionText}</TableCell>
      <TableCell className="text-right">{question.choices.length}</TableCell>
      <TableCell className="space-x-2 text-right">
        {question._count?.quizUserResponse &&
        question._count?.quizUserResponse > 0 ? (
          <Button
            size="icon-sm"
            variant="outline"
            onClick={() => {
              setIsQuestionWinnerDialogOpen(true);
              setSelectedQuestion(question);
            }}
          >
            <Trophy size={14} />
          </Button>
        ) : null}

        <Button
          size="icon-sm"
          variant="outline"
          onClick={() => {
            setIsQuestionEditDialogOpen(true);
            setSelectedQuestion(question);
          }}
        >
          <Pencil size={14} />
        </Button>
      </TableCell>
    </TableRow>
  );
};

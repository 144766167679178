export const getQuizStartDate = (questions: Question[]): Date => {
  const questionTimes = questions.map(question => question.date.getTime())
  const startTime = Math.min(...questionTimes)
  return new Date(startTime)
}

export const getQuizEndDate = (questions: Question[]): Date => {
  const questionTimes = questions.map(question => question.date.getTime())
  const endTime = Math.max(...questionTimes)
  return new Date(endTime)
}

export const doQuizzesOverlap = (quiz1Questions: Question[], quiz2Questions: Question[]): boolean => {
  const quiz1Start = getQuizStartDate(quiz1Questions)
  const quiz1End = getQuizEndDate(quiz1Questions)
  const quiz2Start = getQuizStartDate(quiz2Questions)
  const quiz2End = getQuizEndDate(quiz2Questions)

  return quiz1Start <= quiz2End && quiz2Start <= quiz1End
}
import axios from "axios";
import { LoginDataType } from "../contexts/AuthContext";

const ROUTE_ROOT = "auth";

export async function loginUser(
	data: LoginDataType
): Promise<SignInUserResponseDTO> {
	const response = await axios.post(`${ROUTE_ROOT}/signin`, data);

	return response.data.data;
}

export async function createUser(
	data: CreateUserDTO
): Promise<CreateUserResponseDTO> {
	const response = await axios.post(`${ROUTE_ROOT}/create`, data);

	return response.data.data;
}

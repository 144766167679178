import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TextArea from "../../inputs/TextArea";
import File from "../../inputs/File";
import { uploadFile } from "../../../../services/api/api";
import DownloadBlocIcon from "../../../../assets/icons/download-icon.svg";
import usePreview from "../../../../contexts/PreviewContext";

interface UpComingSaleDetailsProps {
    selectedItem: number;
    selectedTab: number;
}

const UpComingSaleDetails: React.FC<UpComingSaleDetailsProps> = ({
    selectedItem,
    selectedTab,
}) => {
    const preview = usePreview();

    const [leftUploading, setLeftUploading] = useState(false);
    const [leftUploaded, setLeftUploaded] = useState(false);

    const [rightUploading, setRightUploading] = useState(false);
    const [rightUploaded, setRightUploaded] = useState(false);

    useEffect(() => {
        setLeftUploaded(
            !!preview.getSelectedItemTab(selectedItem, selectedTab)
                ?.additionalImages?.leftUrl
        );
        setRightUploaded(
            !!preview.getSelectedItemTab(selectedItem, selectedTab)
                ?.additionalImages?.rightUrl
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem, selectedTab]);

    const handleUpload = async (event: any, index: number) => {
        const name = event.target.name;
        if (index === 0) {
            setLeftUploading(true);
        } else if (index === 1) {
            setRightUploading(true);
        }

        const file = event.target.files[0];
        if (!file) return;
        const path = await uploadFile(file);

        if (file) {
            preview.setPreviewItemTabAdditionalImageField(
                selectedItem,
                selectedTab,
                name,
                path
            );
        }

        event.target.value = "";
        if (index === 0) {
            setLeftUploading(false);
            setLeftUploaded(true);
        } else if (index === 1) {
            setRightUploading(false);
            setRightUploaded(true);
        }
    };

    const handleChangeTextArea = (event: any) => {
        const name = event.target.name;
        preview.setPreviewItemTabField(
            selectedItem,
            selectedTab,
            name,
            event.target.value
        );
    };

    return (
        <Container>
            <TextArea
                value={
                    preview.getSelectedItemTab(selectedItem, selectedTab)
                        ?.descriptionText || ""
                }
                onChange={handleChangeTextArea}
            />
            <FileUploaderContainer>
                <File
                    uploaded={leftUploaded}
                    uploading={leftUploading}
                    src={
                        leftUploaded
                            ? preview.getSelectedItemTab(
                                  selectedItem,
                                  selectedTab
                              )?.additionalImages?.leftUrl
                            : DownloadBlocIcon
                    }
                    onChange={(e) => handleUpload(e, 0)}
                    name="leftUrl"
                />
                <File
                    uploaded={rightUploaded}
                    uploading={rightUploading}
                    src={
                        rightUploaded
                            ? preview.getSelectedItemTab(
                                  selectedItem,
                                  selectedTab
                              )?.additionalImages?.rightUrl
                            : DownloadBlocIcon
                    }
                    onChange={(e) => handleUpload(e, 1)}
                    name="rightUrl"
                />
            </FileUploaderContainer>
        </Container>
    );
};

export default UpComingSaleDetails;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-bottom: 15px;
`;
const FileUploaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`;

import axios from "axios";

const ROUTE_ROOT = "christmas";

export async function getChristmasConfig(): Promise<any> {
	const response = await axios.get(`${ROUTE_ROOT}`);
	return response.data.data;
}

export async function updateChristmasConfig(
	jsonData: any
): Promise<any> {
	const response = await axios.post(`${ROUTE_ROOT}`, {
		jsonData
	});
	return response.data.data;
}

import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import colors from '../../../styles/colors.styles';
import Loader from '../../loaders/Loader';
import { ImageUp } from 'lucide-react';

interface ImageUploaderProps extends InputHTMLAttributes<HTMLInputElement> {
  uploaded: boolean;
  uploading: boolean;
  src: string | undefined;
}

const File: React.FC<ImageUploaderProps> = ({
  uploaded,
  uploading,
  src,
  ...props
}) => {
  return (
    <UploadContainer>
      {src ? (
        <Image
          src={src}
          alt=""
          style={{
            width: uploaded ? '100%' : 36,
            height: uploaded ? '100%' : 36,
          }}
        />
      ) : (
        <ImageUp className="text-bluePrimary" />
      )}
      <Input type="file" {...props} />
      <Loader isVisible={uploading} />
    </UploadContainer>
  );
};

export default File;

const UploadContainer = styled.label`
  position: relative;
  display: flex;
  border: 2px solid ${colors.blue.secondary};
  border-radius: 8px;
  width: 100px;
  height: 93px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Image = styled.img`
  border-radius: 6px;
  object-fit: cover;
`;

const Input = styled.input`
  display: none;
`;

import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getCategories } from '../../../../services/api/category';
import Title from '../miscellaneous/Title';
import Select, { MultiValue } from 'react-select';
import makeAnimated from 'react-select/animated';
import { reactSelectStyles } from '../../../../styles/reactSelect.styles';
import usePreview from '../../../../contexts/PreviewContext';
import { Checkbox } from '../../../../shadcn/components/ui/checkbox';
import { CollectionCategorySelect } from '../../../../interfaces/shop';

interface CategoryProps extends InputHTMLAttributes<HTMLInputElement> {
    selectedItem: number;
    selectedTab: number;
}

const Category: React.FC<CategoryProps> = ({ selectedItem, selectedTab }) => {
    const preview = usePreview();

    const [categories, setCategories] = useState<CollectionCategorySelect[]>(
        []
    );

    const animatedComponents = makeAnimated();

    const allSalesChecked = preview.getAllSales(selectedItem);
    const selectedItemTabCategories = preview.getSelectedItemTab(
        selectedItem,
        selectedTab
    ).Category;

    useEffect(() => {
        (async () => {
            const _categories = await getCategories();

            setCategories(
                _categories.map((el) => {
                    return {
                        value: el.id ?? '',
                        label: el.title ?? '',
                    };
                })
            );
        })();
    }, []);

    const handleChange = (event: MultiValue<CollectionCategorySelect>) => {
        preview.setCategories(selectedItem, selectedTab, [...event]);
    };

    return (
        <Container>
            <Title>Sélectionner la catégorie</Title>
            <Select<CollectionCategorySelect, true>
                components={animatedComponents}
                options={categories}
                isMulti
                closeMenuOnSelect={false}
                defaultValue={
                    selectedItemTabCategories
                        ? selectedItemTabCategories.map((el) => {
                              return {
                                  value: el.id ?? '',
                                  label: el.title ?? '',
                              };
                          })
                        : []
                }
                value={(selectedItemTabCategories || []).map((el) => {
                    return {
                        value: el.id ?? '',
                        label: el.title ?? '',
                    };
                })}
                styles={reactSelectStyles}
                onChange={(newValues) => handleChange(newValues)}
            />
            <CheckboxContainer>
                <Checkbox
                    checked={allSalesChecked}
                    onClick={() => {
                        preview.setAllSales(
                            selectedItem,
                            selectedTab,
                            !allSalesChecked
                        );
                    }}
                />
                <label className="ml-2 text-xs text-primary-blue">
                    Afficher dans toutes les ventes
                </label>
            </CheckboxContainer>
        </Container>
    );
};

export default Category;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 12px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
`;

import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getUsers } from '../../services/users.service';
import 'reactjs-popup/dist/index.css';
import colors from '../../styles/colors.styles';
import fonts from '../../styles/fonts.styles';
import useAuth from '../../contexts/AuthContext';
import { AddUserDialog } from './components/AddUserDialog';
import { DeleteUserDialog } from './components/DeleteUserDialog';

export default function Users() {
  const { user } = useAuth();
  const [users, setUsers] = useState<User[]>([]);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    updateUsersList();
  }, []);

  const updateUsersList = () => {
    setLoading(true);
    getUsers()
      .then((users) => {
        setUsers(users);
      })
      .catch((err: AxiosError) => {
        if (err.response && err.response.status === 401) {
          setError("Vous n'avez pas les droits nécessaires");
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container>
      <ActionsRowContainer>
        <AddUserDialog onUserCreated={updateUsersList} />
      </ActionsRowContainer>
      <TableContainer>
        <TableHeaderContainer>
          <span>Email</span>
          <span>Role</span>
          <span></span>
        </TableHeaderContainer>

        {users.map((_user) => {
          return (
            <TableRowContainer key={_user.id}>
              <span>{_user.email}</span>
              <span>{_user.role}</span>
              {_user.id === user?.id ? (
                <span></span>
              ) : (
                <DeleteUserDialog
                  user={_user}
                  onUserDeleted={updateUsersList}
                />
              )}
            </TableRowContainer>
          );
        })}
      </TableContainer>
    </Container>
  );
}

const ActionsRowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 24px;
`;

const TableHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 5fr 2fr 1fr;
  font-family: ${fonts.WorkSans.semiBold};
  color: ${colors.blue.primary};

  padding: 0 8px 4px 8px;
  margin-bottom: 8px;

  border-bottom: 2px solid ${colors.blue.primary};
`;

const TableRowContainer = styled.div`
  display: grid;
  grid-template-columns: 5fr 2fr 1fr;

  align-items: center;

  font-family: ${fonts.WorkSans.regular};
  color: ${colors.blue.primary};

  padding: 8px;

  &:hover {
    background: ${colors.orange.overlay};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  padding: 16px;
`;
